class DataService {

   /* Load attributes from Firebase */
	async loadSubCollectionFromFirebase(language, ref){
		let items = [];

		return new Promise(function (resolve, reject) {

			ref.collection(language).get().then(snap => {
				snap.forEach(function(doc) {
					let item = doc.data();
					item['idFirebase'] = doc.id
					items.push(item);
				});
			
				if(items) resolve(items);
				else reject('Erreur');
			})
		})
	}
}

export default DataService;