/* Natives */
import React, { Component } from "react";
/* Style */
import "./LineItem.scss";
import colors from '../../assets/Colors.scss';
/* Plugins */
import { Row, Col } from 'react-flexbox-grid';
/* Services */
import StringService from '../../services/StringService';
//Class
const stringService  = new StringService();

class LineItem extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showMenuUser : false,
			showMenuRole : false,
			roleList     : [],
			usersList    : [],
		}
	}

	/* Remove user from Firebase */
	async _removeFromFirebase(type, prodType){
		let that = this;
		that.setState({display : false});

		/* Produit */
		if(type === 'produit') {
			if(prodType === 0) this.props.onOpenAlert(true, "Êtes-vous sûr de vouloir supprimer le produit, ainsi que les déclinaisons associées ?",prodType,this.props.data);
			else this.props.onOpenAlert(true, "Êtes-vous sûr de vouloir supprimer la déclinaison ?",prodType,this.props.data);
		}  
		/* Users */
		else if(type === 'user')  this.props.onOpenAlert(true, "Êtes-vous sûr de vouloir supprimer l'utilisateur ?",'user',this.props.data);
		/* Users */
		else if(type === 'carac') this.props.onOpenAlert(true, "Êtes-vous sûr de vouloir supprimer la caractéristique ?",'carac',this.props.data);
		/* Attributs */
		else if(type === 'attr')  this.props.onOpenAlert(true, "Êtes-vous sûr de vouloir supprimer l'attribut ?",'attr',this.props.data);

		setTimeout(function(){ that.setState({display : true}) }, 500);
	}

	/* Change icon depending the complete value */
	_displayComplete(produit){
		if(produit.complete) return <i className="icons-font green icon-check-circle"></i>
		else return <i className="icons-font red icon-times-circle"></i>
	}

	_displayOptions(index,data){
		if(index===0) return data;
		else return '- '+data;
	}

	_getColorPrj(){
		if(this.props.projet === this.props.data){
			return {"background": colors.darkblue, "color": colors.white}
		}
	}

	/* Displaying the component */
	_displayLine(){
		let that = this;
		if(this.props.type === "produits"){
			let produit   = this.props.data;
			let prodType  = this.props.prodType;
			return(
				<div className="lineItem-container">
					{prodType === 0 &&(
						<Row onClick={() => this.props.onEdit(this.props.data, prodType)} className="lineItem-item editableProduit">
							<Col data-label="Nom du produit" className="lineItem-item-cell" xs={3}>{stringService.uppercaseFirstLetter(produit.nom)}</Col>
							<Col data-label="Référence"      className="lineItem-item-cell" xs={3}>{stringService.uppercaseFirstLetter(produit.reference)}</Col>
							<Col data-label="Complété"       className="lineItem-item-cell" xs={2}>{this._displayComplete(produit)}</Col>
							<Col data-label="Crée le"        className="lineItem-item-cell" xs={2}>{produit.creation_date}</Col>
							<Col data-label="Màj le"         className="lineItem-item-cell" xs={2}>{produit.maj_date}</Col>
						</Row>
					)}
					{prodType === 1 &&(
						<Row onClick={() => this.props.onEdit(this.props.data, prodType)} className="lineItem-item editableProduit">
							<Col data-label="Référence" className="lineItem-item-cell" xs={4}>{stringService.uppercaseFirstLetter(produit.reference)}</Col>
							<Col data-label="Complété"  className="lineItem-item-cell" xs={2}>{this._displayComplete(produit)}</Col>
							<Col data-label="Crée le"   className="lineItem-item-cell" xs={3}>{produit.creation_date}</Col>
							<Col data-label="Màj le"    className="lineItem-item-cell" xs={3}>{produit.maj_date}</Col>
						</Row>
					)}
					<div className="delete-button" onClick={() => this._removeFromFirebase('produit',prodType)}><i className="icons-font delete icon-close"></i></div>
				</div>
			);
		}else if (this.props.type === "user"){
			let user = this.props.data;
			return(
				<div className="lineItem-container">
					<Row onClick={() => this.props.onEdit(this.props.data)} className="lineItem-item editable">
					<Col data-label="Nom"    className="lineItem-item-cell" xs={3}>{stringService.uppercaseFirstLetter(user.nom)}</Col>
					<Col data-label="Prénom" className="lineItem-item-cell" xs={3}>{stringService.uppercaseFirstLetter(user.prenom)}</Col>
					<Col data-label="Email"  className="lineItem-item-cell" xs={4}>{user.email}</Col>
					<Col data-label="Role"   className="lineItem-item-cell" xs={2}>{stringService.uppercaseFirstLetter(user.role)}</Col>
					</Row>
					<div className="delete-button" onClick={() => this._removeFromFirebase('user')}><i className="icons-font delete icon-close"></i></div>
				</div>
			);
		}else if (this.props.type === "projets"){
			return(
				<div className="lineItem-container">
					<Row className="lineItem-item editablePrj" onClick={() => this.props.onSelectPrj(this.props.data)} style={this._getColorPrj()}>
						<div className="lineItem-item-cell projets">{this.props.data}</div>
						{this.props.projet === this.props.data &&(
							<div className="lineItem-item-cell projets"><i className="icons-font icon-check-circle"></i></div>
						)}
					</Row>
				</div>
			);
		}else if (this.props.type === "carac"){
			let caracteristique = this.props.data;
			return(
				<div className="lineItem-container">
					<Row className="lineItem-item editable" onClick={() => this.props.onEdit(this.props.data)}>
						<Col data-label="Label"   className="lineItem-item-cell" xs={6}>{stringService.uppercaseFirstLetter(caracteristique.nom)}</Col>
						<Col data-label="Options" className="lineItem-item-cell option" xs={6}>
						{caracteristique.options.map(function(data, i){
							return <div key={i}>&nbsp;{that._displayOptions(i,stringService.uppercaseFirstLetter(data))}</div>
						})}
						</Col>
					</Row>
					<div className="delete-button" onClick={() => this._removeFromFirebase('carac')}><i className="icons-font delete icon-close"></i></div>
				</div>
			);
		}else if (this.props.type === "attr"){
			let attribut = this.props.data;
			return(
				<div className="lineItem-container">
					<Row className="lineItem-item editable" onClick={() => this.props.onEdit(this.props.data)}>
						<Col data-label="Label"   className="lineItem-item-cell" xs={4}>{stringService.uppercaseFirstLetter(attribut.nom)}</Col>
						<Col data-label="Type"    className="lineItem-item-cell" xs={4}>{stringService.uppercaseFirstLetter(attribut.type)}</Col>
						<Col data-label="Options" className="lineItem-item-cell" xs={4}>
						{attribut.options.map(function(data, i){
							return <div key={i}>&nbsp;{that._displayOptions(i,stringService.uppercaseFirstLetter(data))}</div>
						})}
						</Col>
					</Row>
					<div className="delete-button" onClick={() => this._removeFromFirebase('attr')}><i className="icons-font delete icon-close"></i></div>
				</div>
			);
		}
	}

	render(){
		return (
			this._displayLine()
		);
	}
}

export default LineItem;
