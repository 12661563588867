/** Natives **/
import React, { Component } from "react";
/** Style **/
import "./Progression.scss";
/** Services **/
import StringService from '../../services/StringService';
import DataService 	from '../../services/DataService';

//Class
const stringService = new StringService();
const dataService   = new DataService();

class Progression extends Component {
	constructor(props) {
		super(props);
		this.state = {
			descriptionMedium : '',
			percentCompleted  : {},

			allProducts   : [],
			allDecli   	  : [],
			langProducts  : [],
			langDecli  	  : [],
			allAttributes : [],
			allCategories : [],
			allCarac		  : [],

			isLoading : true,
		}
	}
	componentDidMount(){
		this._loadData();
	}
	componentDidUpdate(prevProps, prevState){
		let {allProducts, allAttributes, allCategories} = this.state;
		if(prevProps.langues !== this.props.langues || prevState.allProducts !== allProducts || prevState.allAttributes !== allAttributes || prevState.allCategories !== allCategories){
			this._loadData();
		}
	}
	
	/* Load all data from Firestore */
	_loadData(){
		let that = this;
		let {allProducts, allDecli, langProducts, langDecli, allAttributes, allCategories, allCarac} = this.state;

		this.props.langues.forEach((lang) => {
			dataService.loadSubCollectionFromFirebase(lang.nom, that.props.produitsRef).then((prodLang) => {
				if(prodLang.length){
					langProducts[lang.nom] = [];
					langProducts[lang.nom] = prodLang;
					prodLang.forEach((prod) => {
						allProducts.push(prod);
					})
				}
				dataService.loadSubCollectionFromFirebase(lang.nom, that.props.declinRef).then((decliLang) => {
					if(decliLang.length){
						langDecli[lang.nom] = [];
						langDecli[lang.nom] = decliLang;
						decliLang.forEach((attr) => {
							allDecli.push(attr);
						});
					}
					dataService.loadSubCollectionFromFirebase(lang.nom, that.props.attributsRef).then((attrLang) => {
						if(attrLang.length){
							attrLang.forEach((attr) => {
								allAttributes.push(attr);
							});
						}
						dataService.loadSubCollectionFromFirebase(lang.nom, that.props.categoriesRef).then((catLang) => {
							if(catLang.length){
								catLang.forEach((attr) => {
									allCategories.push(attr);
								});
							}
							dataService.loadSubCollectionFromFirebase(lang.nom, that.props.caracRef).then((caracLang) => {
								if(caracLang.length){
									caracLang.forEach((attr) => {
										allCarac.push(attr);
									});
								}
								that.setState({isLoading:false})
							});
						});
					})
				});
			});
		})
		this.setState({allProducts, allDecli, langProducts, langDecli, allAttributes, allCategories });
	}

	/*-------------------------------------------------*/
	/*------------------ Percentage -------------------*/
	/*-------------------------------------------------*/

	/* Calculation of the percatage of each language */
	_calcPercentage(data){
		let {langProducts, langDecli} = this.state;
		let langTotalProd = [];

		if(langProducts[data.nom] && langDecli[data.nom]){
			let completed = {};

			langTotalProd = langProducts[data.nom].concat(langDecli[data.nom]);

			if(langTotalProd){
				Object.values(langTotalProd).forEach((prod) => {
					completed[data.nom] = {}
					completed[data.nom]['empty']   = 0;
					completed[data.nom]['total']   = 0;
					completed[data.nom]['percent'] = 0;
		
					for(var property in prod){
						if(prod.hasOwnProperty(property)) if(typeof prod[property] === 'string' && prod[property] === "") completed[data.nom].empty = completed[data.nom].empty+1;
					} 
					completed[data.nom].total = Object.keys(prod).length;
				});
				if(langTotalProd.length > 0) completed[data.nom].empty = completed[data.nom].empty/langTotalProd.length;
				let tempPercent = (100*completed[data.nom].empty) / completed[data.nom].total;
				completed[data.nom].percent = (100 - tempPercent).toFixed(1);
		
				if(completed[data.nom]){
					if(isNaN(completed[data.nom].percent)) completed[data.nom].percent = 0;
					return completed[data.nom].percent+'%';
				}else return '0%';
			}else return '0%';
		}else return '0%';
	}

	/*-------------------------------------------------*/
	/*------------------- Catalogue -------------------*/
	/*-------------------------------------------------*/

	/* Get the value of the description */
	_getDescriptionMedium(){
		let {allProducts} = this.state;
		let total = 0;

		allProducts.forEach((produitProd) => {
			if(produitProd.desc_longue) {
				if(produitProd.desc_longue.length !== 0) total = total + produitProd.desc_longue.length;
			}
		});
		
		total = total / allProducts.length;
		if (isNaN(total)) total = 0;
		return Math.round(total);
	}

	/*-------------------------------------------------*/
	/*-------------------- Display --------------------*/
	/*-------------------------------------------------*/

	_displayBody(){
		let title 	= this.props.projet;
		let langues = this.props.langues;

		let {allProducts, allDecli, allAttributes, allCategories, allCarac} = this.state;

		if(this.state.isLoading) return null;
		else return(
			<div className="progression-body">
				{/* Title */}
				<div className="progression-body-row">
					{title}
				</div>

				{/* Totality peurcentage */}
				<div className="progression-body-row">
					<div className="progression-body-row-title">Pourcentage de la totalité</div>
					<div className="line"></div>
					{langues.map((data, index) => {
						return (
							<div key={index} className="progression-body-row-text">
								<div>{stringService.uppercaseFirstLetter(data.nom)}</div>
								<div>{this._calcPercentage(data)}</div>
							</div>
						);
					})}
				</div>

				{/* Data registered */}
				<div className="progression-body-row">
					<div className="progression-body-row-title">Volume catalogue</div>
					<div className="line"></div>

					<div className="progression-body-row-text">
						<div>Nombre de produits principaux</div>
						<div>{allProducts.length}</div>
					</div>
					<div className="progression-body-row-text">
						<div>Nombre de déclinaisons de produits</div>
						<div>{allDecli.length}</div>
					</div>
					<div className="progression-body-row-text">
						<div>Nombre d'attributs</div>
						<div>{allAttributes.length}</div>
					</div>
					<div className="progression-body-row-text">
						<div>Nombre de caractéristiques</div>
						<div>{allCarac.length}</div>
					</div>
					<div className="progression-body-row-text">
						<div>Nombre de catégories et de sous-catégories</div>
						<div>{allCategories.length}</div>
					</div>
					<div className="progression-body-row-text">
						<div>Moyenne de caractères par description</div>
						<div>{this._getDescriptionMedium()}</div>
					</div>
				</div>
			</div>
		);
	}

	render() {
		return (
			<div className="progression-container">
				{this._displayBody()}
			</div>
		);
	}
}

export default Progression;

