/** Natives **/
import React, { Component } from "react";
/** Style **/
import "./Parametres-attributs.scss";
/* Plugins */
import { Row, Col } from 'react-flexbox-grid';
/* Components */
import LineItem     from '../LineItem/LineItem';
import SlidableMenu from '../SlidableMenu/SlidableMenu';
import AlertMessage from '../../components/AlertMessage/AlertMessage';
import Pagination   from '../Pagination/Pagination';

class ParametresAttributs extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			showMenu       : false,
			edit           : false,
			langueSelected : '',
			data           : {},
			title          : '',

			filteredAttributs : [],
			query             : '',
			
			// Alert message
			openAlert    : false,
			isChoice     : false,
			titleAlert   : '',
			dataToDelete : '',
			typeToDelete : '',

			// pagination
			pageOfItems : [],
			page : 1,
		}
	}
	componentDidMount() {
		this._loadAttributes()
	}
	
	componentDidUpdate(prevProps, prevState){
		let {query} = this.state;
		if(this.props.attributsArray !== prevProps.attributsArray || prevState.query !== query){
			this._loadAttributes()
		}
	}

	/* Load the attributes from the parent component (Parametres) */
	_loadAttributes(){
		const query = this.state.query;
		let filteredAttributs = [];
		if(this.props.attributsArray.length){
			filteredAttributs = this.props.attributsArray.filter(p => p.nom.toLowerCase().includes(query.toLowerCase()));
			filteredAttributs.sort((a, b) => (a.id > b.id) ? 1 : -1);
		}
		this.setState({filteredAttributs});
	}
	
	/*-------------------------------------------------*/
	/*---------------------- Menu ---------------------*/
	/*-------------------------------------------------*/

	/* Open or close the menu in the right */
	_openMenu = () => {
		this.setState({
			showMenu : !this.state.showMenu,
			edit     : false,
			title    : 'Ajouter un attribut'
		});
	}

	/* Edit an attribute */
	_editMenu = (data) => {
		this.setState({
			data     : data,
			edit     : true,
			showMenu : !this.state.showMenu,
			title    : 'Editer un attribut'
		});
	}

	/*-------------------------------------------------*/
	/*---------------------- Data ---------------------*/
	/*-------------------------------------------------*/

	/* Search functionality */
	_handleInputChange = event => {
		const query = event.target.value;
		this.setState({query})
	};


	/*-------------------------------------------------*/
	/*---------------------- Alert --------------------*/
	/*-------------------------------------------------*/

	/* Open the alert message */
	_openAlert = (isChoice, title, type, dataToDelete) => {
		this.setState({isChoice: isChoice, openAlert: !this.state.openAlert, titleAlert: title, typeToDelete:type, dataToDelete: dataToDelete});
	}

	/* Remove attribute */ 
	_closeAlert = async (choice) => {
		let {dataToDelete} = this.state;
		let that = this;

		if(choice === true){
			that.props.attributsRef.collection(that.props.langueSelected).doc(dataToDelete.idFirebase).delete().then(function(){    
				that.setState({openAlert: !that.state.openAlert});
				that.props.onReload();
			}).catch(function(error) {
				console.error("Error removing document: ", error);
			});
		}
	}

	/*-------------------------------------------------*/
	/*------------------ Pagination -------------------*/
	/*-------------------------------------------------*/

	// Update state with new page of items
	_onChangePage = (pageOfItems,page) => this.setState({ pageOfItems:pageOfItems, page:page}); 

	/*-------------------------------------------------*/
	/*--------------------- Render --------------------*/
	/*-------------------------------------------------*/
	
	render() {
		let that              = this;
		let langueSelected    = this.props.langueSelected;
		let filteredAttributs = this.state.filteredAttributs;

		return (
			<div className="param-attr-content">
				<div className="param-attr-container">
					<div className="param-attr-top-container">

						{/* Title of the page */}
						<div className="param-attr-title">
							<div className="param-attr-title-text">Attributs</div>
						</div>

						{/* Input buttons */}
						<div className="param-attr-buttons">
							<div className="searchbar">
								<input className="searchbar-text" type="text" placeholder="Rechercher..." value={this.state.query} onChange={this._handleInputChange}></input>
								<div className="searchbar-icon"><i className="icons-font search icon-search"></i></div>
							</div>
							<button className="button-blue" onClick={() => this._openMenu()}><i className="icons-font icon-list-ul"></i> Ajouter un attribut</button>
						</div>
					</div>

					{/* Header table */}
					<Row className="table-header removable">
						<Col className="subtitle" xs={4}>Label</Col> 
						<Col className="subtitle" xs={4}>Type</Col>
						<Col className="subtitle" xs={4}>Options</Col>
					</Row> 
					<div className="line"></div>

					{/* Items */}
					<div className="table-items attributs">
						{this.state.pageOfItems.map((attr, indexAttr) => {
							return(
								<div key={indexAttr}>
									<LineItem key={indexAttr} type="attr" data={attr} onEdit={that._editMenu} langueSelected={langueSelected} onOpenAlert={that._openAlert} />
								</div>
							);
						})}

						{/* Pagination */}
						<Pagination items={filteredAttributs} onChangePage={this._onChangePage} />
					</div>
				</div>

				{this.state.showMenu  && (<SlidableMenu title={this.state.title} type="attribut" edit={that.state.edit} data={this.state.data} onClose={this._openMenu} onReload={this.props.onReload} projet={this.props.projet} langueSelected={langueSelected} attributsRef={this.props.attributsRef} />)}
				{this.state.openAlert && (<AlertMessage title={this.state.titleAlert} isChoice={this.state.isChoice} onAlertChoice={this._closeAlert}></AlertMessage>)}
			</div>
		);
	}
}

export default ParametresAttributs;

