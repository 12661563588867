/** Natives **/
import React, { Component } from "react";
/** Style **/
import "./Configuration.scss";
/* Plugins */
import { Row, Col } from 'react-flexbox-grid';
/* Components */
import LineItem     from '../LineItem/LineItem';
import SlidableMenu from '../SlidableMenu/SlidableMenu';
import AlertMessage from '../../components/AlertMessage/AlertMessage';
import Pagination   from '../Pagination/Pagination';
/* Services */
import { db } from '../../services/FirebaseCloudService';
/** Services **/
import StringService  from '../../services/StringService';
//Class
const stringService = new StringService();

class Configuration extends Component {
	_isMounted = false;
	
	constructor(props) {
		super(props);
		
		this.state = {
			showMenuUser : false,
			showMenuRole : false,
			roleList     : [],
			usersList    : [],
			data         : {},
			edit         : false,
			titleRole    : '',
			titleUser    : '',

			filteredUsers : [],
			query : '',

			// Alert message
			openAlert    : false,
			isChoice     : false,
			titleAlert   : '',
			dataToDelete : '',
			typeToDelete : '',

			// pagination
			pageOfItems : [],
			page : 1,
		}
	}
	componentDidMount(){
		this._getRoles();
		this._getUsers();
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	/* Open the menu in the right */
	_openMenuRole = () => { this.setState({ edit: false, showMenuRole: !this.state.showMenuRole, titleRole: 'Ajouter un rôle' }); }
	_openMenuUser = () => { this.setState({ edit: false, showMenuUser: !this.state.showMenuUser, titleUser: 'Ajouter un utilisateur' }); }

	/* Reload the data when leaving slidable menu */
	_reloadData = () => {
		this._getRoles();
		this._getUsers();
	}

	/* Search functionality */
	_handleInputChange = event => {
		this._isMounted = true;
		let {pageOfItems} = this.state;

		const query = event.target.value;
		this.setState(prevState => {
			const filteredUsers = prevState.usersList.filter(element => {
				return element.nom.toLowerCase().includes(query.toLowerCase());
			});
			return { query, filteredUsers, pageOfItems };
		});
	};

	/* Get the projects from Firebase */
	_getRoles = () => {
		let roleList = [];
		db.collection('roles').get().then((snapshot) => {
			snapshot.docs.forEach((snap) =>{
				roleList.push(snap.data());
			})
			this.setState({roleList : roleList})
		})
	}

	/* Get the users from Firebase */
	_getUsers = () => {
		let usersList = [];
		db.collection('utilisateurs').get().then((snapshot) => {
			snapshot.docs.forEach(async (snap) =>{
				let user = {}
				let userData  = snap.data();
				user.email    = userData.email;
				user.nom      = userData.nom;
				user.prenom   = userData.prenom;
				user.username = userData.username;
				user.role     = userData.roleRef.id;
				usersList.push(user);
			});
			const { query } = this.state;
			const filteredUsers = usersList.filter(element => {
				return element.nom.toLowerCase().includes(query.toLowerCase());
			});
			this.setState({usersList : usersList, filteredUsers})
		})
	}

	/* Edit a role */
	_editMenuRole = (data) => {
		this.setState({ data: data, edit: true, showMenuRole: !this.state.showMenuRole, titleRole:'Editer un rôle'});
	}

	/* Edit a user */
	_editMenuUser = (data) => {
		this.setState({data: data, edit: true, showMenuUser: !this.state.showMenuUser, titleUser:'Editer un utilisateur'});
	}

	/* Open the alert message */
	_openAlert = (isChoice, title, type, dataToDelete) => {
		this.setState({isChoice: isChoice, openAlert: true, titleAlert: title, typeToDelete:type, dataToDelete: dataToDelete});
	}
	
	/* When closing the alert */
	_closeAlert = async (choice) => {
		let that = this;
		if(choice === true){
			if(this.state.typeToDelete === "role"){
				let role    = this.state.dataToDelete;
				let isExist = false;

				await db.collection('utilisateurs').get().then((snapshot) => {
					snapshot.docs.forEach(async (snap) => {
						if(snap.data().roleRef.id === role){
							isExist = true;
						}
					});
				});
				if(role.toLowerCase() === "admin")  	  that._openAlert(false, "Le rôle d'administrateur ne peut pas être supprimé.");
				else if(role.toLowerCase() === "client") that._openAlert(false, "Le rôle de client ne peut pas être supprimé.");

				else{
					if(!isExist){
						await db.collection("roles").doc(role).delete().then(() => { 
							this._getRoles(); 
							this.setState({openAlert: !this.state.openAlert});
						});
					}else that._openAlert(false, "Le rôle est associé à un utilisateur et ne peut être supprimé.");
				}
			}if(this.state.typeToDelete === "user"){
				if(this.state.dataToDelete.nom.toLowerCase() !== "admin"){
					// TODO : await auth.removeUser(this.props.data.email).then(()=>{
					await db.collection("utilisateurs").doc(this.state.dataToDelete.email).delete();
					await db.collection("projetsUsers").doc(this.state.dataToDelete.email).delete().then(() => {
						this._getUsers();
						that._openAlert(false, "L'utilisateur a été supprimé avec succès."); 
					});
					// TODO : then -> redirection sur login page
				}else{
					that._openAlert(false, "Impossible de supprimer l'administrateur.");
				} 
			}
		}else{
			this.setState({openAlert: !this.state.openAlert});
		}
	}

	/*-------------------------------------------------*/
	/*------------------ Pagination -------------------*/
	/*-------------------------------------------------*/

	// update state with new page of items
	_onChangePage = (pageOfItems,page) => this.setState({ pageOfItems: pageOfItems, page:page }); 
		
	/*-------------------------------------------------*/
	/*--------------------- Render --------------------*/
	/*-------------------------------------------------*/
	
	render() {
		let utilisateurs  = this.props.role.onglets && this.props.role.onglets.utilisateurs === true;
		let roles         = this.props.role.onglets && this.props.role.onglets.roles        === true;
		let rolesList     = this.state.roleList;

		return (
			<div className="config-content">
				<div className="config-container">
					<div className="config-top-container">

						{/* Title of the page */}
						<div className="config-title">
							<div className="config-title-text">Rôles</div>
						</div>

						{/* Input buttons */}
						<div className="config-buttons">
							<div className="searchbar">
								<input className="searchbar-text" type="text" placeholder="Rechercher" value={this.state.query} onChange={this._handleInputChange}></input>
								<div className="searchbar-icon"><i className="icons-font search icon-search"></i></div>
							</div>

							{roles        && (<button className="button-blue" onClick={() => this._openMenuRole()}><i className="icons-font icon-handshake-o"></i> Ajouter un rôle</button> )}
							{utilisateurs && (<button className="button-blue" onClick={() => this._openMenuUser()}><i className="icons-font icon-user-plus"></i> Ajouter un utilisateur</button> )}
						</div>
					</div>

					{/* List of rôles */}
					<Row className="config-roles">
						{rolesList.map((role, index) => {
							return (
								<Col key={index} className="config-roles-item">
									<div className="config-roles-item-title"  onClick={() => this._editMenuRole(role)}>{stringService.uppercaseFirstLetter(role.nom)}</div>
									<div className="config-roles-item-delete" onClick={() => this._openAlert(true, "Etes-vous sûr de vouloir supprimer le rôle ?",'role',role.nom)}><i className="icons-font delete icon-close"></i></div>
								</Col>
							);
						})}
					</Row>

					{/* Title 'Utilisateurs' */}
					<div className="config-title">
						<div className="config-title-text">Utilisateurs</div>
					</div>

					{/* Header table */}
					<Row className="table-header removable">
						<Col className="subtitle" xs={3}>Nom</Col>
						<Col className="subtitle" xs={3}>Prénom</Col>
						<Col className="subtitle" xs={4}>Email</Col>
						<Col className="subtitle" xs={2}>Rôle</Col>
					</Row> 
					<div className="line"></div>

					<div className="table-items config">
						{this.state.pageOfItems.map((user, index) => {
							return(
								user && (<LineItem key={index} type="user" data={user} role={user.role} onEdit={this._editMenuUser} onOpenAlert={this._openAlert} />)
							);
						})}

						{/* Pagination */}
						<Pagination items={this.state.filteredUsers} onChangePage={this._onChangePage} />
					</div>
				</div>

				{this.state.showMenuRole && (<SlidableMenu title={this.state.titleRole}  type="config-role" edit={this.state.edit} data={this.state.data} onClose={this._openMenuRole} onReload={this._reloadData} />)}
				{this.state.showMenuUser && (<SlidableMenu title={this.state.titleUser}  type="config-user" edit={this.state.edit} data={this.state.data} onClose={this._openMenuUser} onReload={this._reloadData} />)}
				{this.state.openAlert    && (<AlertMessage title={this.state.titleAlert} isChoice={this.state.isChoice} onAlertChoice={this._closeAlert}></AlertMessage>)}
			</div>
		);
	}
}

export default Configuration;

