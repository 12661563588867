/** Natives **/
import React, { Component } from "react";
/** Style **/
import "./Parametres.scss";
import colors from '../../assets/Colors.scss';
/* Components */
import ParametresAttributs        from '../Parametres-attributs/Parametres-attributs';
import ParametresCaracteristiques from '../Parametres-caracteristiques/Parametres-caracteristiques';
import ParametresCategories       from '../Parametres-categories/Parametres-categories';
/** Services **/
import DataService from '../../services/DataService';
// Class
const dataService = new DataService();

class Parametres extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			style_selected     : { "background": colors.darkblue, "color": colors.white, "boxShadow" : "0 5px 15px rgba(0, 0, 0, 0.16)" },
			style_unselected   : { "background": colors.grey,     "color": colors.darkblue },
			attributsSelected  : true,
			caracSelected      : false,
			categoriesSelected : false,

			attributsArray      : [],
			caracteristicsArray : [],
			categoriesArray	  : [],

			isLoading : true
		}
	}
	componentDidMount(){
		this._loadData(this.props.languageSelected);
	}
	componentDidUpdate(prevProps, prevState) {
		if(this.props.languageSelected !== prevProps.languageSelected){
			this._loadData(this.props.languageSelected)
	   }
	}
	
	/* Load all data from Firestore */
	_loadData(language){
		let that = this;
		dataService.loadSubCollectionFromFirebase(language, that.props.categoriesRef).then((cat) => {
			dataService.loadSubCollectionFromFirebase(language, that.props.caracRef).then((caracs) => {
				dataService.loadSubCollectionFromFirebase(language, that.props.attributsRef).then((attr) => {
					cat.sort((a, b) => (a.id > b.id) ? 1 : -1);
					that.setState({attributsArray:attr, caracteristicsArray:caracs, categoriesArray:cat, isLoading:false});
				});
			});
		});
	}

	/* Select the state of the draw */
	_selectDraw = (title) => {
		if      (title === "attr")  this.setState({ attributsSelected : true,  caracSelected : false, categoriesSelected : false }); 
		else if (title === "carac") this.setState({ attributsSelected : false, caracSelected : true,  categoriesSelected : false }); 
		else if (title === "cat")   this.setState({ attributsSelected : false, caracSelected : false, categoriesSelected : true  });
		this._loadData(this.props.languageSelected);
	}

	/* Select the color of the draw */
	_getColors(category){
		if(category === true) return this.state.style_selected;
	}

	/* Display 'Attributs','Caracteristiques' or 'Categories' body */
	_displayBody(){
		let {attributsArray, caracteristicsArray, categoriesArray} = this.state;
		let that = this;

		if(this.state.isLoading) return null;
			  if(that.state.attributsSelected)  return <ParametresAttributs 		  langueSelected={that.props.languageSelected} onReload={() => this._loadData(this.props.languageSelected)} attributsArray={attributsArray} attributsRef={this.props.attributsRef}/>
		else if(that.state.caracSelected)      return <ParametresCaracteristiques langueSelected={that.props.languageSelected} onReload={() => this._loadData(this.props.languageSelected)} caracteristicsArray={caracteristicsArray} caracRef={this.props.caracRef} />
		else if(that.state.categoriesSelected) return <ParametresCategories 		  langueSelected={that.props.languageSelected} onReload={() => this._loadData(this.props.languageSelected)} categoriesArray={categoriesArray} categoriesRef={this.props.categoriesRef}/>
	}


	render() {
		let roleAttributs  = this.props.role.onglets && this.props.role.onglets.attributs         === true;
		let roleCarac      = this.props.role.onglets && this.props.role.onglets.caracteristiques  === true;
		let roleCategories = this.props.role.onglets && this.props.role.onglets.categories        === true;

		return(
			<div className="param-content">
			{/* Panel */}
			<div className="param-panel">
				<div className="param-panel-title">Paramètres</div>
				<div className="param-panel-buttons">
					{roleAttributs  && (<input className="param-panel-buttons-button" type="button" value="Attributs"        onClick={() => this._selectDraw('attr')}  style={this._getColors(this.state.attributsSelected)}></input>)}
					{roleCarac      && (<input className="param-panel-buttons-button" type="button" value="Caractéristiques" onClick={() => this._selectDraw('carac')} style={this._getColors(this.state.caracSelected)}></input>)}
					{roleCategories && (<input className="param-panel-buttons-button" type="button" value="Catégories"       onClick={() => this._selectDraw('cat')}   style={this._getColors(this.state.categoriesSelected)}></input>)}
				</div>
			</div>

			{/* Body */}
			{this._displayBody()}
			</div>
		);
	}
}

export default Parametres;

