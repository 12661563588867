import React from 'react';
import {Route, IndexRoute} from 'react-router';

import App   from '../App';
import Login from '../pages/LoginPage/LoginPage';
import Main  from '../pages/MainPage/MainPage';

const routes = (
    <Route path="/" component={App}>
        <IndexRoute component={Login}/>
        <Route path="/main" component={Main}/>
    </Route>
);

export default routes;