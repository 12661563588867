/** Natives **/
import React, { Component } from "react";
/** Style **/
import "./SlidableMenu_ConfigRole.scss";
/* Services */
import { db } from '../../services/FirebaseCloudService';
/* Images */
import check from '../../assets/check.png'; 

class SlidableMenu_ConfigRole extends Component {
    constructor(props) {
     super(props);
        this.state = {
            role               : '',
            checkProjets       : false,
            checkProgression   : false,
            checkProduits      : false,
            checkParametres    : false,
            checkParamAttr     : false,
            checkParamCarac    : false,
            checkParamCat      : false,
            checkConfiguration : false,
            checkConfigRole    : false,
            checkConfigUtil    : false,
            checkExports       : false,

            roleAdded    : false,
            showMessage  : false,
            errorMessage : '',
        }
    }
    componentDidMount(){
        if(this.props.edit === true){
            this.setState({
                role               : this.props.data.nom,
                checkProjets       : this.props.data.onglets.projets,
                checkProgression   : this.props.data.onglets.progression,
                checkProduits      : this.props.data.onglets.produits,
                checkParametres    : this.props.data.onglets.parametres,
                checkParamAttr     : this.props.data.onglets.attributs,
                checkParamCarac    : this.props.data.onglets.caracteristiques,
                checkParamCat      : this.props.data.onglets.categories,
                checkConfiguration : this.props.data.onglets.configuration,
                checkConfigRole    : this.props.data.onglets.roles,
                checkConfigUtil    : this.props.data.onglets.utilisateurs,
                checkExports       : this.props.data.onglets.export,
            });
        }
    }

    /* Role */
    _selectRoleName = (role) => {
        this.setState({role : role.target.value});
    }

    _selectCheckbox(type){
             if(type === 'projets')      {this.setState({checkProjets     : !this.state.checkProjets})}
        else if(type === 'progression')  {this.setState({checkProgression : !this.state.checkProgression})}
        else if(type === 'produits')     {this.setState({checkProduits    : !this.state.checkProduits})}
        else if(type === 'parametres')    
            if(this.state.checkParametres === false) this.setState({checkParametres: !this.state.checkParametres, checkParamAttr: true, checkParamCarac:true,  checkParamCat: true})
            else                                     this.setState({checkParametres: !this.state.checkParametres, checkParamAttr: false, checkParamCarac:false,  checkParamCat: false})
        else if(type === 'attributs') 
            if(this.state.checkParamAttr === false)  this.setState({checkParamAttr : !this.state.checkParamAttr, checkParametres: true})
            else                                     this.setState({checkParamAttr : !this.state.checkParamAttr});
        else if(type === 'caracteristiques') 
            if(this.state.checkParamCarac === false)  this.setState({checkParamCarac : !this.state.checkParamCarac, checkParametres: true})
            else                                     this.setState({checkParamCarac  : !this.state.checkParamCarac});
        else if(type === 'categories') 
            if(this.state.checkParamCat === false)   this.setState({checkParamCat : !this.state.checkParamCat, checkParametres: true})
            else                                     this.setState({checkParamCat : !this.state.checkParamCat});
        else if(type === 'configuration') 
            if(this.state.checkConfiguration === false) this.setState({checkConfiguration: !this.state.checkConfiguration, checkConfigRole: true,  checkConfigUtil: true})
            else                                        this.setState({checkConfiguration: !this.state.checkConfiguration, checkConfigRole: false, checkConfigUtil: false})
        else if(type === 'roles')        
            if(this.state.checkConfigRole === false)    this.setState({checkConfigRole : !this.state.checkConfigRole, checkConfiguration: true})
            else                                        this.setState({checkConfigRole : !this.state.checkConfigRole});
        else if(type === 'utilisateurs')
            if(this.state.checkConfigUtil === false)    this.setState({checkConfigUtil : !this.state.checkConfigUtil, checkConfiguration: true})
            else                                        this.setState({checkConfigUtil : !this.state.checkConfigUtil});
        else if(type === 'export')       {this.setState({checkExports : !this.state.checkExports})}
        else if(type === 'aide')         {this.setState({checkAide    : !this.state.checkAide})}
    }

    /* Close the slidable menu */
    _closePanel(){
        this.props.onClose();
        this.props.onReload();
    }

  /*-------------------------------------------------*/
  /*-------------------- Firebase -------------------*/
  /*-------------------------------------------------*/

    /* Save data to Firebase */
    async _sendToFirebase(){
        let that = this;
        let role = this.state.role;
        let errorMessage = '';

        if(role !== ""){
            if(that.props.data.nom !== "admin"){
                let onglets = {
                    progression      : this.state.checkProgression,
                    projets          : this.state.checkProjets,
                    produits         : this.state.checkProduits,
                    parametres       : this.state.checkParametres,
                    attributs        : this.state.checkParamAttr,
                    caracteristiques : this.state.checkParamCarac,
                    categories       : this.state.checkParamCat,
                    configuration    : this.state.checkConfiguration,
                    roles            : this.state.checkConfigRole,
                    utilisateurs     : this.state.checkConfigUtil,
                    export           : this.state.checkExports,
                }
                if(that.props.data.nom){
                    await db.collection("roles").doc(that.props.data.nom).get().then((snapshot) => {
                        // If the role exist
                        if(snapshot.data()){
                            db.collection("roles").doc(that.props.data.nom).delete().then(function() {                         // Delete the role
                                db.collection("roles").doc(that.state.role).set({ nom: that.state.role, onglets: onglets });   // Re-create it
                            }).catch(function(error) {console.error("Erreur de suppression du rôle ", error)});
                        }else db.collection("roles").doc(this.state.role).set({ nom: this.state.role, onglets: onglets });
                    });
                }else db.collection("roles").doc(this.state.role).set({ nom: this.state.role, onglets: onglets });
                this.setState({roleAdded : true});
            }else errorMessage = "Impossible d'éditer le rôle de l'administrateur." 
        }else errorMessage = "Veuillez entrer un nom de rôle." 
        this.setState({errorMessage : errorMessage});
    }

    render() {
        return (
            <div>
                {!this.state.roleAdded && (
                    <div className="slidableMenu-content-block">
                        {/* Username */}
                        <div className="slidableMenu-content-block-label">Nom du rôle</div>
                        <input className="slidableMenu-content-block-input-short" type="text" value={this.state.role} onChange={this._selectRoleName}></input>

                        <div  className="slidableMenu-content-block-checkboxBlock">
                            {/* Projets */}
                            <div className="slidableMenu-content-block-checkboxRow">
                                <div className="checkbox" onClick={() => this._selectCheckbox('projets')} >
                                    {this.state.checkProjets && (<img src={check} className="checkbox-icon" alt="check" />)}
                                </div>
                                <div className="slidableMenu-content-block-checkboxRow-text">Projets</div>
                            </div>
                            {/* Progression */}
                            <div className="slidableMenu-content-block-checkboxRow">
                                <div className="checkbox" onClick={() => this._selectCheckbox('progression')} >
                                    {this.state.checkProgression && (<img src={check} className="checkbox-icon" alt="check" />)}
                                </div>
                                <div className="slidableMenu-content-block-checkboxRow-text">Progression</div>
                            </div>
                            {/* Produits */}
                            <div className="slidableMenu-content-block-checkboxRow">
                                <div className="checkbox" onClick={() => this._selectCheckbox('produits')} >
                                    {this.state.checkProduits && (<img src={check} className="checkbox-icon" alt="check" />)}
                                </div>
                                <div className="slidableMenu-content-block-checkboxRow-text">Produits</div>
                            </div>
                            {/* Paramètres */}
                            <div className="slidableMenu-content-block-checkboxRow">
                                <div className="checkbox" onClick={() => this._selectCheckbox('parametres')} >
                                    {this.state.checkParametres && (<img src={check} className="checkbox-icon" alt="check" />)}
                                </div>
                                <div className="slidableMenu-content-block-checkboxRow-text">Paramètres</div>
                            </div>
                            {/* Attributs */}
                            <div className="slidableMenu-content-block-checkboxRow second">
                                <div className="checkbox" onClick={() => this._selectCheckbox('attributs')} >
                                    {this.state.checkParamAttr && (<img src={check} className="checkbox-icon" alt="check" />)}
                                </div>
                                <div className="slidableMenu-content-block-checkboxRow-text">Attributs</div>
                            </div>
                            {/* Caractéristiques */}
                            <div className="slidableMenu-content-block-checkboxRow second">
                                <div className="checkbox" onClick={() => this._selectCheckbox('caracteristiques')} >
                                    {this.state.checkParamCarac && (<img src={check} className="checkbox-icon" alt="check" />)}
                                </div>
                                <div className="slidableMenu-content-block-checkboxRow-text">Caractéristiques</div>
                            </div>
                            {/* Catégories */}
                            <div className="slidableMenu-content-block-checkboxRow second">
                                <div className="checkbox" onClick={() => this._selectCheckbox('categories')} >
                                    {this.state.checkParamCat && (<img src={check} className="checkbox-icon" alt="check" />)}
                                </div>
                                <div className="slidableMenu-content-block-checkboxRow-text">Catégories</div>
                            </div>
                            {/* Configuration */}
                            <div className="slidableMenu-content-block-checkboxRow">
                                <div className="checkbox" onClick={() => this._selectCheckbox('configuration')} >
                                    {this.state.checkConfiguration && (<img src={check} className="checkbox-icon" alt="check" />)}
                                </div>
                                <div className="slidableMenu-content-block-checkboxRow-text">Configuration</div>
                            </div>
                            {/* Rôles */}
                            <div className="slidableMenu-content-block-checkboxRow second">
                                <div className="checkbox" onClick={() => this._selectCheckbox('roles')} >
                                    {this.state.checkConfigRole && (<img src={check} className="checkbox-icon" alt="check" />)}
                                </div>
                                <div className="slidableMenu-content-block-checkboxRow-text">Rôles</div>
                            </div>
                            {/* Rôles */}
                            <div className="slidableMenu-content-block-checkboxRow second">
                                <div className="checkbox" onClick={() => this._selectCheckbox('utilisateurs')} >
                                    {this.state.checkConfigUtil && (<img src={check} className="checkbox-icon" alt="check" />)}
                                </div>
                                <div className="slidableMenu-content-block-checkboxRow-text">Utilisateurs</div>
                            </div>
                            {/* Exports */}
                            <div className="slidableMenu-content-block-checkboxRow">
                                <div className="checkbox" onClick={() => this._selectCheckbox('export')} >
                                    {this.state.checkExports && (<img src={check} className="checkbox-icon" alt="check" />)}
                                </div>
                                <div className="slidableMenu-content-block-checkboxRow-text">Exports</div>
                            </div>
                        </div>
                        {this.state.errorMessage !== '' && (<div className="slidableMenu-content-block-label center">{this.state.errorMessage}</div>)}
                        
                        {/* Buttons */}
                        <div className="slidableMenu-content-block-buttons">
                            <input className="slidableMenu-content-block-buttons-button white" type="button" value="Ajouter" onClick={() => this._sendToFirebase()}></input>
                            <input className="slidableMenu-content-block-buttons-button dark"  type="button" value="Annuler" onClick={this.props.onClose}></input>
                        </div>
                    </div>
                )}
                {this.state.roleAdded && ( 
                    <div className="slidableMenu-content-block">
                        <div className="slidableMenu-content-block-label center">Le rôle a été ajouté avec succès !</div>
                        <div className="slidableMenu-content-block-buttons">
                            <input className="slidableMenu-content-block-buttons-button white" type="button" value="Fermer" onClick={() => this._closePanel()}></input>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default SlidableMenu_ConfigRole;

