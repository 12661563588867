class DropdownService {

    /* Open the dropdown */
    openDropDown(selectedDrop, list){
        if(list){
            let index = list.findIndex(drop => drop === selectedDrop);
            if(index !== -1) list.splice(index, 1);
            list.forEach(drop => {
                this.closeDropDown(drop);
            });
        }

        let node = document.getElementById(selectedDrop);
        if(node.style.visibility !== "visible"){
            node.style.visibility = "visible";
            node.style.marginTop  = "-5px"
        }else node.style.visibility = "hidden";
    }

    /* Close the dropdown */
    closeDropDown(id){
        let node = document.getElementById(id);
        if(node) node.style.visibility = "hidden";
    }
}

export default DropdownService;