/** Natives **/
import React, { Component } from "react";
/** Style **/
import "./Exports.scss";
/** Components */
import AlertMessage from '../../components/AlertMessage/AlertMessage';
/** Services **/
import StringService   from '../../services/StringService';
import DropdownService from '../../services/DropdownService';
import DataService 	  from '../../services/DataService';
import JsonService 	  from '../../services/JsonService';
/* Plugins */
import Papa from 'papaparse';
//Class
const stringService   = new StringService();
const dropdownService = new DropdownService();
const dataService   	 = new DataService();
const jsonService   	 = new JsonService();

class Exports extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			typeSelected : 0,

			// Alert message
			openAlert    : false,
			isChoice     : false,
			titleAlert   : '',
			dataToDelete : '',
			typeToDelete : '',

			dropDownList : ["dropLang","dropProd"],

			langProducts   : [],
			langDecli  		: [],
			langCategories : [],
			langAttr			: [],
			langCarac		: [],

			selectedImportExport : 0,
			titleSelected : 'exporter',
			titleType 	  : 'export',

			csvImported : null,
			isCSVLoaded : false
		}
		this._updateData = this._updateData.bind(this);
	}
	componentDidMount(){
		this._loadData();
	}

	/*-------------------------------------------------*/
	/*---------------------- Load ---------------------*/
	/*-------------------------------------------------*/

	/* Load all data from Firestore */
	_loadData(){
		let that = this;
		let {langProducts, langCategories, langDecli, langAttr, langCarac} = this.state;

		this.props.langues.forEach((lang) => {
			dataService.loadSubCollectionFromFirebase(lang.nom, that.props.produitsRef).then((prodLang) => {
				if(prodLang.length){
					langProducts[lang.nom] = [];
					langProducts[lang.nom] = prodLang;
				}

				dataService.loadSubCollectionFromFirebase(lang.nom, that.props.declinRef).then((decliLang) => {
					if(prodLang.length){
						langDecli[lang.nom] = [];
						langDecli[lang.nom] = decliLang;
					}

					dataService.loadSubCollectionFromFirebase(lang.nom, that.props.categoriesRef).then((catLang) => {
						if(catLang.length){
							langCategories[lang.nom] = [];
							langCategories[lang.nom] = catLang;
						}

						dataService.loadSubCollectionFromFirebase(lang.nom, that.props.attributsRef).then((attrLang) => {
							if(attrLang.length){
								langAttr[lang.nom] = [];
								langAttr[lang.nom] = attrLang;
							}

							dataService.loadSubCollectionFromFirebase(lang.nom, that.props.caracRef).then((caracLang) => {
								if(caracLang.length){
									langCarac[lang.nom] = [];
									langCarac[lang.nom] = caracLang;
								}
							});
						});
					});
				});
			});
		})
		this.setState({ langProducts, langDecli, langCategories, langAttr, langCarac});
	}

	/*-------------------------------------------------*/
	/*--------------------- Select --------------------*/
	/*-------------------------------------------------*/

	/* Select if the user want to export or import data */
	_selectImportExport = (typeImpExp) => {
		let {selectedImportExport, titleSelected, titleType} = this.state;

		selectedImportExport = typeImpExp;
		if(typeImpExp === 0){
			titleSelected = 'exporter';
			titleType	  = 'export';
		}else{
			titleSelected = 'importer';
			titleType	  = 'import'
		}

		this.setState({selectedImportExport, titleSelected, titleType});
		dropdownService.closeDropDown("dropImpExp");
	}

	/* Select the products type on click */
	_selectTypeExport = (typeExp) => {
		this.setState({typeSelected : typeExp});
		dropdownService.closeDropDown("dropProd");
	}

	/*-------------------------------------------------*/
	/*--------------------- Display -------------------*/
	/*-------------------------------------------------*/

	_displayImportExportSelected(typeImpExp){
		if(typeImpExp === 0) return "Exports";
		else if(typeImpExp === 1) return "Imports";
	}
	 
	/* Display the type of export selected */
	_displayExportTypeSelected(typeSelected){
		if(typeSelected === 0) return "Prod. principaux";
		else if(typeSelected === 1) return "Déclinaisons";
		else if(typeSelected === 2) return "Attributs";
		else if(typeSelected === 3) return "Caractéristiques";
		else if(typeSelected === 4) return "Catégories";
	}

	/*-------------------------------------------------*/
	/*-------------------- Export ---------------------*/
	/*-------------------------------------------------*/

	/* Format the categories array */
	_formatExportCategories(categories){
		let categoriesToExport = [];
		categories.forEach((cat) => {
			let newCat = {}
			newCat['id']  = 15+ categoriesToExport.length;
			newCat['nom'] = stringService.uppercaseFirstLetter(cat.nom);

			if(cat.parentID === 0) newCat['parent'] = 'Accueil';

			else{
				let parent = categories.find(catParent => catParent.id === cat.parentID)
				newCat['parent'] = stringService.uppercaseFirstLetter(parent.nom);
			}
			categoriesToExport.push(newCat)
		})
		return categoriesToExport;
	}


	/* Format the datat to be exported */
	_formatDataToExport(){
		let {langProducts, langDecli, langCategories, langAttr, langCarac} = this.state;
		let language = this.props.languageSelected;

		let jsonToExport = [];
		if (this.state.typeSelected === 0)      jsonToExport = JSON.parse(JSON.stringify(langProducts[language]));
		else if (this.state.typeSelected === 1) jsonToExport = JSON.parse(JSON.stringify(langDecli[language]));
		else if (this.state.typeSelected === 2) jsonToExport = JSON.parse(JSON.stringify(langAttr[language]));
		else if (this.state.typeSelected === 3) jsonToExport = JSON.parse(JSON.stringify(langCarac[language]));
		else if (this.state.typeSelected === 4) jsonToExport = JSON.parse(JSON.stringify(this._formatExportCategories(langCategories[language])));

		for(let i=0; i<jsonToExport.length; i++){
			/* Main products */
			if(this.state.typeSelected === 0){
				jsonToExport[i] = jsonService.generateJsonExportProdPrincipaux(jsonToExport[i], i);

			/* Declinaisons */
			}else if(this.state.typeSelected === 1){
				jsonToExport[i] = jsonService.generateJsonExportDeclinaisons(jsonToExport[i]);
				
			/* Attributs */
			}else if(this.state.typeSelected === 2){
				jsonToExport[i] = jsonService.generateJsonExportAttributes(jsonToExport[i]);

			/* Caractéristiques */
			}else if(this.state.typeSelected === 3){
				jsonToExport[i] = jsonService.generateJsonExportCaracteristiques(jsonToExport[i]);
			
			/* Catégories */
			}else if(this.state.typeSelected === 4){
				jsonToExport[i] = jsonService.generateJsonExportCategories(jsonToExport[i]);
			}
		}

		/* Generate CSV from Json object */
		if(jsonToExport.length !== 0){
			let fileName = '';
			if(this.state.typeSelected === 0)      fileName = 'produits-principaux-générés-'+language+'.csv'
			else if(this.state.typeSelected === 1) fileName = 'produits-déclinés-générés-'+language+'.csv'
			else if(this.state.typeSelected === 2) fileName = 'attributs-générées-'+language+'.csv'
			else if(this.state.typeSelected === 3) fileName = 'caracteritiques-générées-'+language+'.csv'
			else if(this.state.typeSelected === 4) fileName = 'catégories-générées-'+language+'.csv'

			let csv = Papa.unparse(jsonToExport,{delimiter: ";"})
			var csvData = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
			var csvURL  =  null;
			if (navigator.msSaveBlob) csvURL = navigator.msSaveBlob(csvData, fileName); 
			else csvURL   = window.URL.createObjectURL(csvData);
			var tempLink  = document.createElement('a');
			tempLink.href = csvURL;
			tempLink.setAttribute('download', fileName);
			tempLink.click(); 
			this._openAlert(false, "Les produits ont été exportés avec succès.",'',undefined);
			if(this.state.typeSelected === 0){
				this._openAlert(false, "Les produits principaux ont été exportés avec succès.",'',undefined);
			}else if(this.state.typeSelected === 1){
				this._openAlert(false, "Les produits déclinés ont été exportés avec succès.",'',undefined);
			}else{
				this._openAlert(false, "Les catégories ont été exportés avec succès.",'',undefined);
			}
		}else{
			this._openAlert(false, "Il n'y a aucun produit à exporter dans cette langue.",'',undefined);
		}
	}

	/*-------------------------------------------------*/
	/*---------------------- Alert --------------------*/
	/*-------------------------------------------------*/

	/* Open the alert message */
	_openAlert = (isChoice, titleAlert, typeToDelete, dataToDelete) => {
		this.setState({isChoice, openAlert: !this.state.openAlert, titleAlert, typeToDelete, dataToDelete});
	}

	/* Close the alert dialog */ 
	_closeAlert = async (choice) => {
		this.setState({openAlert: !this.state.openAlert});
	}

	/*-------------------------------------------------*/
	/*-------------------- Import ---------------------*/
	/*-------------------------------------------------*/

	/* Changing file */
	_importChange = event => {
		var fileType = 'csv';
		var extension = event.target.files[0].name.split('.').pop().toLowerCase()
		if(extension !== fileType){
			this.setState({ isCSVLoaded : false });
			console.log('Veuillez importer un fichier .csv.');
			return this.setState({ info_text : 'Veuillez importer un fichier .csv.' });
		}else{
			return this.setState({
				csvImported : event.target.files[0],
				info_text   : 'Fichier CSV importé.',
				isCSVLoaded : true
			});
		}
	};
	
	/* Import the CSV and parse it */
	_importCSV = () => {
		const { csvImported } = this.state;
		Papa.parse(csvImported, {
			complete       : this._updateData,
			skipEmptyLines : true,
			header         : true
		});
   };

  	/* Update the data */
	_updateData(result) {
		var data = result.data;
		this._formatCSVData(data);
	}

	/* Format the categories imported */
	_formatImportCategories(categories){
		let categoriesToImport = [];

		categories.forEach((cat) =>{
			let newCat = {}
			newCat['id']  = parseInt(cat.id) - 14;
			newCat['nom'] = cat.nom;

			if(cat['parentID'] === 'Accueil' || 'Home') newCat['parentID'] = 0;

			else{
				let parent = categories.find(catParent => catParent.nom === cat.parentID)
				newCat['parentID'] = parseInt(parent.id) - 14;
			}
			categoriesToImport.push(newCat);
		})
		return categoriesToImport;
	}


	/* Format the imported CSV */
	_formatCSVData(listData){
		let {typeSelected} = this.state;
		let that = this;
		let jsonToImport = JSON.parse(JSON.stringify(listData));

		try{
			for(let i=0; i<listData.length; i++){

				/* Prod. Principaux */
				if(typeSelected === 0){
					jsonToImport[i] = jsonService.generateJsonImportProdPrincipaux(jsonToImport[i]);
					
				/* Declinaisons */
				}else if(typeSelected === 1){
					jsonToImport[i] = jsonService.generateJsonImportDeclinaisons(jsonToImport[i]);
					
				/* Attributs */
				}else if(typeSelected === 2){
					jsonToImport[i] = jsonService.generateJsonImportAttributes(jsonToImport[i]);

				/* Attributs */
				}else if(typeSelected === 3){
					jsonToImport[i] = jsonService.generateJsonImportCaracteristiques(jsonToImport[i]);
					
				/* Categories */
				}else if(typeSelected === 4){
					jsonToImport[i] = jsonService.generateJsonImportCategories(jsonToImport[i]);
				}
			}

			/* Prod. Principaux */
			if(typeSelected === 0){
				that.props.produitsRef.collection(that.props.languageSelected).get().then(function(querySnapshot){
					querySnapshot.forEach(function(doc) {
						that.props.produitsRef.collection(that.props.languageSelected).doc(doc.id).delete();
					});
				}).then(function(){
					jsonToImport.forEach(data => {
						that.props.produitsRef.collection(that.props.languageSelected).add(data);
					})
					that._openAlert(false, "Les produits principaux ont été importés avec succès.",'',undefined);
				});

			/* Declinaisons */
			}else if(typeSelected === 1){
				that.props.declinRef.collection(that.props.languageSelected).get().then(function(querySnapshot){
					querySnapshot.forEach(function(doc){
						that.props.declinRef.collection(that.props.languageSelected).doc(doc.id).delete();
					});
				}).then(function(){
					jsonToImport.forEach(data => {
						that.props.declinRef.collection(that.props.languageSelected).add(data);
					})
					that._openAlert(false, "Les produits déclinés ont été importés avec succès.",'',undefined);
				});

			/* Attributs */
			}else if(typeSelected === 2){
				that.props.attributsRef.collection(that.props.languageSelected).get().then(function(querySnapshot){
					querySnapshot.forEach(function(doc) {
						that.props.attributsRef.collection(that.props.languageSelected).doc(doc.id).delete();
					});
				}).then(function(){
					jsonToImport.forEach(data => {
						that.props.attributsRef.collection(that.props.languageSelected).add(data);
					})
					that._openAlert(false, "Les attributs ont été importés avec succès.",'',undefined);
				});
			
			/* Caractéristiques */
			}else if(typeSelected === 3){
				that.props.caracRef.collection(that.props.languageSelected).get().then(function(querySnapshot){
					querySnapshot.forEach(function(doc) {
						that.props.caracRef.collection(that.props.languageSelected).doc(doc.id).delete();
					});
				}).then(function(){
					jsonToImport.forEach(data => {
						that.props.caracRef.collection(that.props.languageSelected).add(data);
					})
					that._openAlert(false, "Les caractéritiques ont été importées avec succès.",'',undefined);
				});

			/* Categories */
			}else if(typeSelected === 4){
				jsonToImport = JSON.parse(JSON.stringify(this._formatImportCategories(jsonToImport)));

				that.props.categoriesRef.collection(that.props.languageSelected).get().then(function(querySnapshot){
					querySnapshot.forEach(function(doc) {
						that.props.categoriesRef.collection(that.props.languageSelected).doc(doc.id).delete();
					});
				}).then(function(){
					jsonToImport.forEach(data => {
						that.props.declinRef.collection(that.props.languageSelected).add(data);
					})
					that._openAlert(false, "Les catégories ont été importées avec succès.",'',undefined);
				});
			}

		}catch(e){
			this.setState({ info_text : "Erreur d'importation des données." });
			console.log(e);
		}
  	}

	/*-------------------------------------------------*/
	/*--------------------- Render --------------------*/
	/*-------------------------------------------------*/

	render(){
		let {dropDownList, selectedImportExport, titleSelected, titleType, typeSelected} = this.state;

		return(
			<div className="exports-container">
				<div className="exports-import-selector">
					<div className="dropdown">
						<button className="dropbtn" onClick={() => dropdownService.openDropDown('dropImpExp',JSON.parse(JSON.stringify(dropDownList)))}><div className="dropdown-name">{stringService.uppercaseFirstLetter(this._displayImportExportSelected(selectedImportExport))}</div><i className="icons-font arrow icon-chevron-down"></i></button>
						<div className="dropdown-content" id="dropImpExp">
							<div onClick={() => this._selectImportExport(0)}>Exports</div>
							<div onClick={() => this._selectImportExport(1)}>Imports</div>
						</div>  
					</div>
				</div>
				<div className="exports-box">
					<div className="exports-box-title">Voulez-vous {titleSelected} vos données ?</div>
					{selectedImportExport === 1 &&(<div className="exports-box-subtitle">Attention, cela écrasera les données actuelles associées.</div>)}
					<div className="exports-box-param">

						{/* Product type */}
						<div className="exports-box-param-dropdown">
							<div className="exports-box-param-dropdown-label">Type d'{titleType}</div>
							<div className="dropdown">
								<button className="dropbtn" onClick={() => dropdownService.openDropDown('dropProd',JSON.parse(JSON.stringify(dropDownList)))}><div className="dropdown-name">{stringService.uppercaseFirstLetter(this._displayExportTypeSelected(typeSelected))}</div><i className="icons-font arrow icon-chevron-down"></i></button>
								<div className="dropdown-content" id="dropProd">
									<div onClick={() => this._selectTypeExport(0)}>Prod. principaux</div>
									<div onClick={() => this._selectTypeExport(1)}>Déclinaisons</div>
									<div onClick={() => this._selectTypeExport(2)}>Attributs</div>
									<div onClick={() => this._selectTypeExport(3)}>Caractéristiques</div>
									<div onClick={() => this._selectTypeExport(4)}>Catégories</div>
								</div>  
							</div>
						</div>

						{selectedImportExport === 1 && (
							<div>
								<input
									className 	= "button-file"
									type 		 	= "file"
									ref  			= {input => {this.filesInput = input;}}
									name 			= "file"
									placeholder = {null}
									onChange 	= {this._importChange}
								/>
							</div>
						)}

						{selectedImportExport === 0 &&(<button className="button-blue" onClick={() => this._formatDataToExport()} ><i className="icons-font icon-download"></i> {stringService.uppercaseFirstLetter(titleSelected)}</button>)}
						{selectedImportExport === 1 &&(<button className="button-blue" onClick={() => this._importCSV()} ><i className="icons-font icon-download"></i> {stringService.uppercaseFirstLetter(titleSelected)}</button>)}
					</div>
					{this.state.openAlert && (<AlertMessage title={this.state.titleAlert} isChoice={this.state.isChoice} onAlertChoice={this._closeAlert}></AlertMessage>)}
				</div>
			</div>
		);
	}
}

export default Exports;

