/** Natives **/
import React, { Component } from "react";
/** Style **/
import "./SlidableMenu.scss";
/* Components */
import SlidableMenuProjets          from '../SlidableMenu_Projets/SlidableMenu_Projets';
import SlidableMenuProduits         from '../SlidableMenu_Produits/SlidableMenu_Produits';
import SlidableMenuAttributs        from '../SlidableMenu_Attributs/SlidableMenu_Attributs';
import SlidableMenuCaracteristiques from '../SlidableMenu_Caracteristiques/SlidableMenu_Caracteristiques';
import SlidableMenuConfigUser       from '../SlidableMenu_ConfigUser/SlidableMenu_ConfigUser';
import SlidableMenuConfigRole       from '../SlidableMenu_ConfigRole/SlidableMenu_ConfigRole';

class SlidableMenu extends Component {
  constructor(props) {
    super(props);
		let vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  render() {
    return (
      <div className="slidableMenu-content" ref={this.setWrapperRef}>
        <div className="slidableMenu-content-title">{this.props.title}</div>
        <div className="linewhite"></div>
          {this.props.type === "projet"           && (<SlidableMenuProjets          onClose={this.props.onClose} onReload={this.props.onReload} onSelectPrj={this.props.onSelectPrj} langues={this.props.langues} email={this.props.email}></SlidableMenuProjets>)}
          {this.props.type === "produit"          && (<SlidableMenuProduits         onClose={this.props.onClose} onReload={this.props.onReload} edit={this.props.edit} data={this.props.data} typeProd={this.props.typeProd} role={this.props.role} languageSelected={this.props.languageSelected} attributs={this.props.attributs} caracteristiques={this.props.caracteristiques} categories={this.props.categories} produitsRef={this.props.produitsRef} declinRef={this.props.declinRef}></SlidableMenuProduits>)}
          {this.props.type === "attribut"         && (<SlidableMenuAttributs        onClose={this.props.onClose} onReload={this.props.onReload} edit={this.props.edit} data={this.props.data} projet={this.props.projet} langueSelected={this.props.langueSelected} attributsRef={this.props.attributsRef}></SlidableMenuAttributs>)}
          {this.props.type === "caracteristiques" && (<SlidableMenuCaracteristiques onClose={this.props.onClose} onReload={this.props.onReload} edit={this.props.edit} data={this.props.data} projet={this.props.projet} langueSelected={this.props.langueSelected} caracRef={this.props.caracRef}></SlidableMenuCaracteristiques>)}
          {this.props.type === "config-user"      && (<SlidableMenuConfigUser       onClose={this.props.onClose} onReload={this.props.onReload} edit={this.props.edit} data={this.props.data}></SlidableMenuConfigUser>)}
          {this.props.type === "config-role"      && (<SlidableMenuConfigRole       onClose={this.props.onClose} onReload={this.props.onReload} edit={this.props.edit} data={this.props.data}></SlidableMenuConfigRole>)}
      </div>
    );
  } 
}
export default SlidableMenu;


