/** Natives **/
import React, { Component } from "react";
/** Style **/
import "./Projets.scss";
/* Components */
import LineItem 	from '../LineItem/LineItem';
import SlidableMenu from '../SlidableMenu/SlidableMenu';
import Pagination   from '../Pagination/Pagination';

/* Services */
import { db } from '../../services/FirebaseCloudService';

class Projets extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);
		this.state = {
			isLoading	 : true,
			showMenu	 : false,
			projectsList : [],
			filteredData : [],
			query		 : '',

			// pagination
			pageOfItems : [],
			page : 1,
		}
	}
	componentDidMount() {
		let that = this;
		that._getProjects();
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	/* Open the menu in the right */
	_openMenu = () => {
		this.setState({
			showMenu: !this.state.showMenu
		})
	}

	/* Get the projects */
	_getProjects = () => {
		this._isMounted = true;
		let projectsList = [];

		db.collection('projetsUsers').doc(this.props.user.email).get().then((snapshot) => {
			if(this._isMounted) {
				snapshot.data().projets.forEach((prj) => {
					projectsList.push(prj.nom)
				});

				const { query } = this.state;
				const filteredData = projectsList.filter(element => {
					return element.toLowerCase().includes(query.toLowerCase());
				});
				filteredData.sort((a, b) => (a.toLowerCase() > b.toLowerCase()) ? 1 : -1);
				this.setState({ projectsList: projectsList, filteredData });
			}
		});
	}

	/* Change the project */
	_selectProject = (data) => {
		let that = this;
		let filteredData = this.state.filteredData;
		let index = filteredData.findIndex(prj => prj === data);
		setTimeout(function(){ that.props.onChangeProject(index); }, 200);
	}

	/* Search functionality */
	_handleInputChange = event => {
		this._isMounted = true;
		const query = event.target.value;

		this.setState(prevState => {
			const filteredData = prevState.projectsList.filter(element => {return element.toLowerCase().includes(query.toLowerCase());});
			filteredData.sort((a, b) => (a.toLowerCase() > b.toLowerCase()) ? 1 : -1);

			return { query, filteredData };
		});
	}

	/*-------------------------------------------------*/
	/*------------------ Pagination -------------------*/
	/*-------------------------------------------------*/

	// update state with new page of items
	_onChangePage = (pageOfItems,page) => this.setState({ pageOfItems: pageOfItems, page:page }); 
	
	/*-------------------------------------------------*/
	/*--------------------- Render --------------------*/
	/*-------------------------------------------------*/

	render() {
		let that = this;
		let role = this.props.role;

		return(
			<div className="projets-container">
				<div className="projets-top-container">
					{/* Title of the page */}
					<div className="projets-title">
						<div className="projets-title-text">Liste des projets</div>
					</div>

					{/* Input buttons */}
					<div className="projets-buttons">
						<div className="searchbar">
							<input className="searchbar-text" type="text" placeholder="Rechercher..." value={this.state.query} onChange={this._handleInputChange}></input>
							<div className="searchbar-icon"><i className="icons-font search icon-search"></i></div>
						</div>
						{role && role.onglets && role.onglets.addProjet && (<button className="button-blue" onClick={() => this._openMenu()}><i className="icons-font icon-plus-circle"></i> Nouveau projet</button>)}
					</div>
				</div>

				{/* Items */}
				<div className="table-items projects">
					{this.state.pageOfItems.map((data, index) => {
						return (
							<div key={index}>
								<LineItem type="projets" id={index} data={data} projet={this.props.projet} onSelectPrj={that._selectProject} />
							</div>
						);
					})}

					{/* Pagination */}
					<Pagination items={this.state.filteredData} onChangePage={this._onChangePage} />
				</div>

				{this.state.showMenu && (<SlidableMenu title="Nouveau projet" type="projet" email={this.props.user.email} onClose={this._openMenu} onReload={this._getProjects} onSelectPrj={this._selectProject} />)}
			</div>
		);
	}
}

export default Projets;

