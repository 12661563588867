import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
/* Services */
import routes from './services/Routes';
import "./assets/fontIcoMoon/style.css";
/* Plugins */
import {Router, browserHistory} from 'react-router';

import * as serviceWorker from './serviceWorker';

ReactDOM.render(<Router history={browserHistory} routes={routes}/>, document.getElementById('root'));

serviceWorker.unregister();
