/** Natives **/
import React, { Component } from "react";
/** Style **/
import "./Parametres-caracteristiques.scss";
/* Plugins */
import { Row, Col } from 'react-flexbox-grid';
/* Components */
import LineItem     from '../LineItem/LineItem';
import SlidableMenu from '../SlidableMenu/SlidableMenu';
import AlertMessage from '../../components/AlertMessage/AlertMessage';
import Pagination   from '../Pagination/Pagination';

class ParametresCaracteristiques extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            showMenu         : false,
            edit             : false,
            langueSelected   : '',
            caracteristiques : {},
            data             : {},
            title            : '',
    
            filteredCaracteristiques : [],
            query : '',
            
            // Alert message
            openAlert    : false,
            isChoice     : false,
            titleAlert   : '',
            dataToDelete : '',
            typeToDelete : '',
    
            // pagination
            pageOfItems : [],
            page : 1,
        }
    }
    componentDidMount() {
        this._loadCaracteritics();
    }

    /* Load the caracteristics from the parent component (Parametres) */
    componentDidUpdate(prevProps, prevState) {
		let {query} = this.state;
        if(this.props.caracteristicsArray !== prevProps.caracteristicsArray || prevState.query !== query){
            this._loadCaracteritics();
        }
    }

    _loadCaracteritics(){
        const query = this.state.query;
        let filteredCaracteristiques = [];
        if(this.props.caracteristicsArray.length){
            filteredCaracteristiques = this.props.caracteristicsArray.filter(p => p.nom.toLowerCase().includes(query.toLowerCase()));
        }
        this.setState({filteredCaracteristiques});
    }

    /*-------------------------------------------------*/
	/*---------------------- Menu ---------------------*/
    /*-------------------------------------------------*/

    /* Open or close the menu in the right */
    _openMenu = () => {
        this.setState({
            showMenu : !this.state.showMenu,
            edit     : false,
            title    : 'Ajouter une caractéristique'
        });
    }

    /* Edit an attribute */
    _editMenu = (data) => {
        this.setState({
            data     : data,
            edit     : true,
            showMenu : !this.state.showMenu,
            title    : 'Editer une caractéristique'
        });
    }

    /*-------------------------------------------------*/
	/*---------------------- Data ---------------------*/
    /*-------------------------------------------------*/

    _handleInputChange = event => {
        const query = event.target.value;
        this.setState({query})
    };

    /*-------------------------------------------------*/
	/*---------------------- Alert --------------------*/
    /*-------------------------------------------------*/

    /* Open the alert message */
    _openAlert = (isChoice, title, type, dataToDelete) => {
        this.setState({isChoice: isChoice, openAlert: !this.state.openAlert, titleAlert: title, typeToDelete:type, dataToDelete: dataToDelete});
    }

    _closeAlert = async (choice) => {
        let {dataToDelete, filteredCaracteristiques} = this.state;
        let that = this;

        if(choice === true){
            that.props.caracRef.collection(that.props.langueSelected).doc(dataToDelete.idFirebase).delete().then(function(){    
                that.setState({ filteredCaracteristiques, openAlert:!that.state.openAlert });
                that.props.onReload();
            }).catch(function(error) {
                console.error("Error removing document: ", error);
            });
        }
    }

    /*-------------------------------------------------*/
	/*------------------ Pagination -------------------*/
	/*-------------------------------------------------*/

	// update state with new page of items
	_onChangePage = (pageOfItems,page) => this.setState({ pageOfItems:pageOfItems, page:page }); 

    /*-------------------------------------------------*/
	/*-------------------- Render ---------------------*/
    /*-------------------------------------------------*/

    render() {
        let that = this;
        let langueSelected = this.props.langueSelected;
        let filteredCaracteristiques = this.state.filteredCaracteristiques;

        return(
            <div className="param-carac-content">
                <div className="param-carac-container">
                    <div className="param-carac-top-container">

                        {/* Title of the page */}
                        <div className="param-carac-title">
                            <div className="param-carac-title-text">Caractéristiques</div>
                        </div>

                        {/* Input buttons */}
                        <div className="param-carac-buttons">
                            <div className="searchbar">
                                <input className="searchbar-text" type="text" placeholder="Rechercher..." value={this.state.query} onChange={this._handleInputChange}></input>
                                <div className="searchbar-icon"><i className="icons-font search icon-search"></i></div>
                            </div>
                            <button className="button-blue" onClick={() => this._openMenu()}><i className="icons-font icon-briefcase"></i> Ajouter une caractéristique</button>
                        </div>
                    </div>

                    {/* Header table */}
                    <Row className="table-header removable">
                        <Col className="subtitle" xs={6}>Label</Col> 
                        <Col className="subtitle" xs={6}>Options</Col>
                    </Row> 
                    <div className="line"></div>

                    {/* Items */}
                    <div className="table-items attributs">
                        {this.state.pageOfItems.map((carac, indexCarac) => {
                            return(
                                <div key={indexCarac}>
                                    <LineItem key={indexCarac} type="carac" data={carac} onEdit={that._editMenu} langueSelected={langueSelected} onOpenAlert={that._openAlert} />
                                </div>
                            );
                        })}

                        {/* Pagination */}
                        <Pagination items={filteredCaracteristiques} onChangePage={this._onChangePage} />
                    </div>
                </div>
                {this.state.showMenu  && (<SlidableMenu title={this.state.title} type="caracteristiques" edit={that.state.edit} data={this.state.data} onClose={this._openMenu} onReload={this.props.onReload} projet={this.props.projet} langueSelected={langueSelected} caracRef={this.props.caracRef} />)}
                {this.state.openAlert && (<AlertMessage title={this.state.titleAlert} isChoice={this.state.isChoice} onAlertChoice={this._closeAlert}></AlertMessage>)}
            </div>
        )
    }
}
export default ParametresCaracteristiques;