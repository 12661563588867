	/** Natives **/
	import React, { Component } from "react";
	/** Style **/
	import "./SlidableMenu_Projets.scss";
	/* Plugins */
	import { Row } from 'react-flexbox-grid';
	/* Services */
	import { db   } from '../../services/FirebaseCloudService';
	import StringService   from '../../services/StringService';
	import DropdownService  from '../../services/DropdownService';
	//Class
	const stringService   = new StringService();
	const dropdownService = new DropdownService();

	class SlidableMenu_Projets extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			languesArray : [],
			codeProjet   : '',
			alreadyExist : false,
			prjAdded     : false,
			errorMessage : '',

			languageSelected : '',
			languageListAPI  : [],
			languageArray    : []
		}
	}
	componentDidMount(){
		this._getLanguages();
	}

	/* Select the code project */
	_selectCodeProjet = (code) => {
		this.setState({codeProjet: code.target.value});
	}

	/* Get the list of projects */
	_getLanguages(){
		let languageListAPI = this.state.languageListAPI;
		db.collection('langues').doc('all').get().then((snapshot) => {
			languageListAPI = Object.values(snapshot.data())
			this.setState({languageListAPI : languageListAPI})
		})
	}

	/* Select a language */
	_selectLanguageArray(lang){
		let {languageArray,languageSelected} = this.state;

		if(!languageArray) languageArray = [];
		if(!languageArray.includes(lang)) languageArray.push(lang);

		languageSelected = lang;
		this.setState({languageArray,languageSelected});
		dropdownService.closeDropDown("drop");
	}

	_selectProject(codePrj){
		let projectsList = [];
		db.collection('projetsUsers').doc(this.props.email).get().then((snapshot) => {
			snapshot.data().projets.forEach((prj) => {
				projectsList.push(prj.nom)
			});
			this._closePanel();
			this.props.onSelectPrj(codePrj);
		});
	}

	/* Remove a language */
	_removeItem(item){
		let langArrayTemp = this.state.languageArray;
		let index = langArrayTemp.indexOf(item);
		if (index > -1) langArrayTemp.splice(index,1);

		this.setState({languageArray : langArrayTemp});
	}

	/*-------------------------------------------------*/
	/*-------------------- Firebase -------------------*/
	/*-------------------------------------------------*/

	/* Save data to Firebase */
	async _sendToFirebase(){
		let errorMessage = this.state.errorMessage;
		let alreadyExist = this.state.alreadyExist;
		let prjAdded     = this.state.prjAdded;

		if(this.state.codeProjet !== ''){
			if(this.state.languageArray.length !== 0){
				await db.collection('projets').get().then((snapshot) => {
					snapshot.docs.forEach((snap) =>{
						if(snap.data().nom === this.state.codeProjet) alreadyExist = true; 
					})
				});
				
				if(alreadyExist === false){
					if(this.state.languageArray.length !== 0){
						let objProjet = {};
						await this.state.languageArray.forEach((langue) => {
							objProjet[langue]   = {nom : langue}
						});
						await db.collection("langues").doc(this.state.codeProjet).set(objProjet);
						await db.collection("projets").doc(this.state.codeProjet).set({
							"nom"           : this.state.codeProjet,
							"languesRef"    : db.collection("langues").doc(this.state.codeProjet),
							"produitsRef"   : db.collection("produits").doc(this.state.codeProjet),
							"declinRef"     : db.collection("declinaisons").doc(this.state.codeProjet),
							"attributsRef"  : db.collection("attributs").doc(this.state.codeProjet),
							"caracRef"      : db.collection("caracteristiques").doc(this.state.codeProjet),
							"categoriesRef" : db.collection("categories").doc(this.state.codeProjet),
						})
						await db.collection('projetsUsers').doc(this.props.email).get().then(async(snapshot) => {
							let projectsUsersList = snapshot.data()
							projectsUsersList.projets.push({
								"nom" : this.state.codeProjet,
								"projetRef" : db.collection("projets").doc(this.state.codeProjet),
							})
							await db.collection("projetsUsers").doc(this.props.email).set(projectsUsersList)
						}).then((_) => { prjAdded = true; });
					}else this.setState({showMessage : true}); 
				}else errorMessage = "Le projet existe déja.";
			}else errorMessage = "Veuillez sélectionner au moins une langue";
		}else errorMessage = "Veuillez entrer un code projet";
		this.setState({ alreadyExist:alreadyExist, prjAdded:prjAdded, errorMessage:errorMessage });
	}

	_closePanel(){
		this.props.onClose();
		this.props.onReload();
	}

	render() {
		return (
			<div>
			{!this.state.alreadyExist && !this.state.prjAdded && (
				<div className="slidableMenu-content-block">
					{/* Code projet */}
					<div className="slidableMenu-content-block-label">Code projet</div>
					<input className="slidableMenu-content-block-input-short" type="text" value={this.state.codeProjet} onChange={this._selectCodeProjet}></input>
					
					{/* Langues */}
					<div className="slidableMenu-content-block-label">Langues</div>
					<div className="dropdown">
						<button className="dropbtn long" onClick={() => dropdownService.openDropDown('drop')}><div className="dropdown-name">Sélectionnez une langue</div><i className="icons-font arrow icon-chevron-down"></i></button>
						<div className="dropdown-content long" id="drop">
							{this.state.languageListAPI.map((data, index) => {
								if(!this.state.languageArray.find(lang => lang === data.nom)){
									return <div key={index} onClick={() => this._selectLanguageArray(data.nom)}>{stringService.uppercaseFirstLetter(data.nom)}</div>
								}else return null;
							})}
						</div>
					</div>
					<Row className="selected-items-block">
						{this.state.languageArray && (
							this.state.languageArray.map((data, index) => {
								return (
									<div className="selected-items-item" key={index}>
										<div>{stringService.uppercaseFirstLetter(data)}</div>
										<i className="icons-font selectable icon-close" onClick={() => this._removeItem(data)}></i>
									</div>
								);
							})
						)}
					</Row>
					{this.state.errorMessage &&(<div className="slidableMenu-content-block-label center">{this.state.errorMessage}</div>)}
					<div className="slidableMenu-content-block-buttons">
						<input className="slidableMenu-content-block-buttons-button white" type="button" value="Ajouter" onClick={() => this._sendToFirebase()}></input>
						<input className="slidableMenu-content-block-buttons-button dark"  type="button" value="Annuler" onClick={this.props.onClose}></input>
					</div> 
				</div> 
			)}
			{this.state.alreadyExist && !this.state.prjAdded && (
				<div className="slidableMenu-content-block">
					<div className="slidableMenu-content-block-label center">Votre projet a déja été créé :</div>
					<div className="slidableMenu_projets_codeProjet" onClick={() => this._selectProject(this.state.codeProjet)}>
						{this.state.codeProjet}
					</div>
					<div className="slidableMenu-content-block-buttons">
						<input className="slidableMenu-content-block-buttons-button white" type="button" value="Fermer" onClick={this.props.onClose}></input>
					</div>
				</div>
			)}
			{this.state.prjAdded && !this.state.alreadyExist && (
				<div className="slidableMenu-content-block">
					<div className="slidableMenu-content-block-label center">Votre projet a été ajouté avec succès !</div>
					<div className="slidableMenu-content-block-buttons">
						<input className="slidableMenu-content-block-buttons-button white" type="button" value="Fermer" onClick={() => this._closePanel()}></input>
					</div>
				</div>
			)}
			</div>
		);
	}
}

export default SlidableMenu_Projets;

