/* Natives */
import React, { Component } from "react";
/* Style */
import "./SlidableMenu_Produits.scss";
import colors from '../../assets/Colors.scss';
/* Plugins */
import { Row } from 'react-flexbox-grid';
/* Services */
import StringService   from '../../services/StringService';
import DropdownService from '../../services/DropdownService';
//Class
const stringService   = new StringService();
const dropdownService = new DropdownService();

class SlidableMenu_Produits extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nom                  : '',
            reference            : '',
            categorieSelected    : [],
            categoriesToSend     : [],
            prixHT               : '',
            taxe                 : 0,
            langueSelected       : '',
            actif                : 1,
            ean13                : '',
            upc                  : '',
            eco                  : 0,
            poids                : '',
            quantity             : '',
            quantityMin          : '',
            visibility           : 'both',
            descCourte           : '',
            descLongue           : '',
            dispoCommande        : 0,
            image                : '',
            suppImages           : 1,
            dispoLigne           : 1,

            //images
            imagesInputArray     : [],
            imageSelected        : [],

            // Caracteristiques
            caracInputArray      : [],
            caracSelected        : [],
            optCaracSelected     : [],
            optCaracArray        : [],

            // Attributs
            attributsInputArray  : [],
            attributSelected     : [], 
            optAttrSelected      : [],
            optAttrArray         : [],

            errorMessage : '',
            produitAdded : false,

            dropDownList : [],
            isDisabled : false,

            highiestIdDecliFound : 0
        }
    }
    componentDidMount() {
        let that = this;

        this._initData();
        this._addImageInputArray();

        if(this.props.edit === true){ 
            let caracInputArray     = [];
            let caracSelected       = [];
            let optCaracSelected    = [];
            let optCaracArray       = [];
            let imagesInputArray    = [];
            let attributsInputArray = [];
            let attributSelected    = [];
            let optAttrSelected     = [];
            let optAttrArray        = [];

            if(this.props.data){
                // Caracteristiques
                if(this.props.caracteristiques && this.props.data.caracteristiques){
                    if(this.props.caracteristiques.length !== 0 ){
                        for(let i=0; i<this.props.data.caracteristiques.length; i++){
                            caracSelected[i] = this.props.data.caracteristiques[i];
                            caracInputArray.push('');
            
                            optCaracSelected[i] = []; 
                            optCaracArray[i]    = []; 
                            
                            if(this.props.data.caracteristiques[i].options){
                                this.props.data.caracteristiques[i].options.forEach((opt) => {
                                    optCaracSelected[i] = opt
                                    optCaracArray[i].push(opt)
                                })
                            }
                        }
                    }
                }
                // Images
                if(this.props.data.images){
                    for(let i=0; i<this.props.data.images.length; i++){
                        imagesInputArray.push('');
                        imagesInputArray[i] = this.props.data.images[i];
                    }
                }
                // attributs
                if(this.props.attributs && this.props.data.attributs){
                    if(this.props.attributs.length !== 0 && this.props.data.attributs){
                        for(let i=0; i<this.props.data.attributs.length; i++){
                            attributSelected[i] = this.props.data.attributs[i];
                            attributsInputArray.push('');
            
                            optAttrSelected[i] = []; 
                            optAttrArray[i]    = []; 
                            if(this.props.typeProd !== 1){
                                if(this.props.data.attributs[i].options){
                                    this.props.data.attributs[i].options.forEach((opt) => {
                                        optAttrSelected[i] = opt; 
                                        optAttrArray[i].push(opt)
                                    });
                                }
                            }else{
                                optAttrSelected[i] = this.props.data.attributs[i].options; 
                            }
                        }
                    }
                }
            }

            this.setState({
                id                  : this.props.data.id,
                nom                 : this.props.data.nom, 
                reference           : this.props.data.reference,
                prixHT              : this.props.data.prix_ht, 
                taxe                : this.props.data.taxe, 
                langueSelected      : this.props.languageSelected, 
                actif               : this.props.data.actif,
                ean13               : this.props.data.ean13,
                upc                 : this.props.data.upc,
                eco                 : this.props.data.eco,
                poids               : this.props.data.poids,
                quantity            : this.props.data.quantity,
                quantityMin         : this.props.data.quantityMin,
                visibility          : this.props.data.visibility,
                descCourte          : this.props.data.desc_courte, 
                descLongue          : this.props.data.desc_longue, 
                dispoCommande       : this.props.data.dispoCommande,
                suppImages          : this.props.data.suppImages,
                dispoLigne          : this.props.data.dispoLigne,
                caracInputArray     : caracInputArray,
                caracSelected       : caracSelected,
                optCaracSelected    : optCaracSelected,
                optCaracArray       : optCaracArray,
                imagesInputArray    : imagesInputArray, 
                attributsInputArray : attributsInputArray,
                attributSelected    : attributSelected,
                optAttrSelected     : optAttrSelected,
                optAttrArray        : optAttrArray,
            })
            setTimeout(function(){ that._getCompletedValue(that.props.typeProd) }, 100);
        }
    }

    _initData = () => {
        let that = this;
        let {dropDownList} = this.state;
        dropDownList.push('dropTaxe',"dropActif","dropEco","dropVisibility","dropCommande","dropSuppImages","dropDispoLigne");
        this.setState({langueSelected:this.props.languageSelected, dropDownList});

        let { 
            caracInputArray, caracSelected, optCaracSelected, optCaracArray,
            attributsInputArray, attributSelected, optAttrSelected, optAttrArray 
        } = this.state;
        
        caracInputArray     = [];
        caracSelected       = [];
        optCaracSelected    = [];
        optCaracArray       = [];
        attributsInputArray = [];
        attributSelected    = [];
        optAttrSelected     = [];
        optAttrArray        = [];
        
        // Caractéristiques
        for(let i=0; i<caracInputArray.length; i++){
            caracInputArray[i]  = [];
            caracSelected[i]    = [];
            optCaracSelected[i] = [];
            optCaracArray[i]    = [];
        }

        // Attributs
        for(let i=0; i<attributsInputArray.length; i++){
            attributsInputArray[i] = [];
            attributSelected[i]    = [];
            optAttrSelected[i]     = [];
            optAttrArray[i]        = [];
        }

        this.setState({ 
            caracInputArray, caracSelected, optCaracSelected, optCaracArray,
            attributsInputArray, attributSelected, optAttrSelected, optAttrArray
        });
        if(!this.props.edit) setTimeout(function(){that._addAttrItem(); that._addCaracItem()}, 100);
    }

    /* Check if all values are completed */
    _getCompletedValue(typeProd){
        let {nom,reference,categorieSelected,descCourte,descLongue,prixHT,ean13,upc,poids,quantity,quantityMin,imagesInputArray,errorMessage} = this.state;
        let isCompleted = false;

        if(typeProd === 0){
            if(nom !== ''){
                if(reference !== ''){
                    if(categorieSelected[0] && categorieSelected[0].nom !== ""){
                        if(prixHT !== ''){
                            if(ean13 !== ''){
                                if(ean13.length === 13){
                                    if(upc !== ''){
                                        if(poids !== ''){
                                            if(quantity !== ''){
                                                if(quantityMin !== ''){
                                                    if(descCourte !== ''){
                                                        if(descCourte.length <= 800){
                                                            if(descLongue !== ''){
                                                                if(stringService.countWords(descLongue) >= 300){
                                                                    isCompleted = true;
                                                                    for(let i=0; i<this.state.imagesInputArray.length; i++){
                                                                        if(imagesInputArray[i].length === 0){
                                                                            isCompleted = false;
                                                                            errorMessage = "Un champ \"Image\" est vide."
                                                                        }
                                                                    }
                                                                }else errorMessage = "Le champ \"Description\" doit être supérieur à 300 mots."
                                                            }else errorMessage = "Le champ \"Description\" doit être complété."
                                                        }else errorMessage = "Le champ \"Résumé\" doit être inférieur à 800 caractères."
                                                    }else errorMessage = "Le champ \"Résumé\" doit être complété."
                                                }else errorMessage = "Le champ \"Quantité minimale\" doit être complété"
                                            }else errorMessage = "Le champ \"Quantité\" doit être complété"
                                        }else errorMessage = "Le champ \"Poids\" doit être complété"
                                    }else errorMessage = "Le champ \"UPC\" doit être complété"
                                }else errorMessage = "Le champ \"EAN 13\" doit contenir 13 chiffres."
                            }else errorMessage = "Le champ \"EAN 13\" doit être complété."
                        }else errorMessage = "Le champ \"Prix HT\" doit être complété."
                    }else errorMessage = "Veuillez entrer une catégorie. Si aucune n'existe, veuillez en créer une dans l'onglet : Paramètres/Catégories."
                }else errorMessage = "Le champ \"Référence\" doit être complété."
            }else errorMessage = "Le champ \"Nom\" doit être complété."
        }else{
            if(nom !== ''){
                if(reference !== ''){
                    if(prixHT !== ''){
                        if(ean13 !== ''){
                            if(ean13.length === 13){
                                if(upc !== ''){
                                    if(quantity !== ''){
                                        if(quantityMin !== ''){
                                            isCompleted = true;
                                            for(let i=0; i<this.state.imagesInputArray.length; i++){
                                                if(imagesInputArray[i].length === 0){
                                                    isCompleted = false;
                                                    errorMessage = "Un champ \"Image\" est vide."
                                                }
                                            }
                                        }else errorMessage = "Le champ \"Quantité minimale\" doit être complété"
                                    }else errorMessage = "Le champ \"Quantité\" doit être complété"
                                }else errorMessage = "Le champ \"UPC\" doit être complété"
                            }else errorMessage = "Le champ \"EAN 13\" doit contenir 13 chiffres."
                        }else errorMessage = "Le champ \"EAN 13\" doit être complété."
                    }else errorMessage = "Le champ \"Prix HT\" doit être complété."
                }else errorMessage = "Le champ \"Référence\" doit être complété."
            }
        }
 
        this.setState({errorMessage})
        return isCompleted;
    }

    /* Change the color of the fields uncomplete */
    _getCompletedColor(item, field){
        let unCompletedBorder = {"border": "5px solid "+colors.red, "borderRadius":"8px", "boxSizing": "border-box", "width":"100%" };
        if(field === "image") unCompletedBorder = {"border": "5px solid "+colors.red, "borderRadius":"8px", "boxSizing": "border-box", "flex":1 };
        if(this.props.edit === true){ 
            if(item.length === 0) return unCompletedBorder;
            if(field === "ean13" && item.length<13) return unCompletedBorder;
            if(field === "desc_courte" && item.length > 800) return unCompletedBorder;
            if(field === "desc_longue" && stringService.countWords(item) < 300) return unCompletedBorder;
            else return {};
        }
    }

    /* Close the slidable menu */
    _closePanel(){
        this.props.onClose();
        this.props.onReload();
    }

    /*-------------------------------------------------*/
	/*--------------------- Select --------------------*/
    /*-------------------------------------------------*/
    
    _selectNom       = (nom) => {this.setState({nom : nom.target.value});}
    _selectReference = (ref) => {this.setState({reference : ref.target.value});}
    _selectCategorie = (categorie,index) => {
        let {categorieSelected} = this.state;
        if(categorie) categorieSelected[index] = categorie;
        this.setState({ categorieSelected });
        dropdownService.closeDropDown("dropCat"+index);
    }
    _selectPrixHT = (prix) => { this.setState({prixHT : prix.target.value});}
    _selectTaxe   = (taxe) => {  
        this.setState({taxe : taxe}); 
        dropdownService.closeDropDown("dropTaxe");
    }
    
    _selectActif = (actif) => { 
        this.setState({actif : actif}); 
        dropdownService.closeDropDown("dropActif");
    }
    _selectEan13 = (ean13) => {this.setState({ean13 : ean13.target.value});}
    _selectUPC   = (upc)   => {this.setState({upc   : upc.target.value});  }
    _selectEco = (eco) => {  
        this.setState({eco : eco}); 
        dropdownService.closeDropDown("dropEco");
    }
    _selectPoids         = (poids)       => {this.setState({poids       : poids.target.value});}
    _selectQuantity      = (quantity)    => {this.setState({quantity    : quantity.target.value});}
    _selectQuantityMin   = (quantityMin) => {this.setState({quantityMin : quantityMin.target.value});}
    _selectVisibility = (visibility) => { 
        this.setState({visibility : visibility}); 
        dropdownService.closeDropDown("dropVisibility");
    }
    _selectDescCourte = (descCourte) => {this.setState({descCourte : descCourte.target.value});}
    _selectDescLongue = (descLongue) => {this.setState({descLongue : descLongue.target.value});}
    _selectDispoCommande = (dispoCommande) => { 
        this.setState({dispoCommande : dispoCommande}); 
        dropdownService.closeDropDown("dropCommande");
    }
    _selectImage(i, img) {
        let {imagesInputArray} = this.state;
        imagesInputArray[i]  = img.target.value;
        this.setState({imagesInputArray})
    }
    _removeImage(index){
        let {imagesInputArray} = this.state;
        if(imagesInputArray.length > 1 && index > -1) imagesInputArray.splice(index,1);
        this.setState({ imagesInputArray });
    }
    _addImageInputArray(){
        let {imagesInputArray} = this.state;
        imagesInputArray[imagesInputArray.length] = '';
        this.setState({imagesInputArray});
    }
    _selectSuppImages = (suppImages) => { 
        this.setState({suppImages : suppImages}); 
        dropdownService.closeDropDown("dropSuppImages");
    }
    _selectDispoLigne = (dispoLigne) => { 
        this.setState({dispoLigne : dispoLigne}); 
        dropdownService.closeDropDown("dropDispoLigne");
    }

    // Caractéristiques
    _selectCarac(index, carac){
        let {caracSelected, optCaracArray, optCaracSelected} = this.state;

        // When you select another attribute, you reset the options array
        if(caracSelected[index] !== carac){
            optCaracSelected[index] = '';
            optCaracArray[index]    = [];
        } 
        caracSelected[index] = carac;

        this.setState({caracSelected, optCaracArray, optCaracSelected});
        dropdownService.closeDropDown("dropCarac"+index);
    }
    _selectCaracOption(index, option){
        let {optCaracArray, optCaracSelected} = this.state;

        if(!optCaracArray[index]) optCaracArray[index] = [];
        if(!optCaracArray[index].includes(option)) optCaracArray[index].push(option);

        optCaracSelected[index] = option;
        
        this.setState({optCaracArray, optCaracSelected});
        dropdownService.closeDropDown("dropOptCarac"+index);
    }
    _addCaracItem(index){
        let {caracSelected, caracInputArray, optCaracSelected, dropDownList} = this.state;
        let caracteristiquesArray = this.props.caracteristiques;

        // Add a new line
        caracInputArray.push('');

        if(caracteristiquesArray.length !== 0){
            if(caracSelected.length === 0){
                caracSelected.push({nom:''});
                optCaracSelected.push('');
            } 
            else{
                caracSelected[index] = {nom:''};
                optCaracSelected[index] = '';
            }
            dropDownList.push("dropCarac"+(caracInputArray.length-1));
            dropDownList.push("dropOptCarac"+(caracInputArray.length-1));
        }
        this.setState({caracSelected, caracInputArray, optCaracSelected, dropDownList});
    }
    _removeCarac(index){
        let {caracInputArray, caracSelected, optCaracSelected, optCaracArray, dropDownList} = this.state;
        if(caracInputArray.length > 1 && index > -1){
            caracInputArray.splice(index,1);
            caracSelected.splice(index,1);
            optCaracSelected.splice(index,1);
            optCaracArray.splice(index,1);
            
            let indexDropDownCarac    = dropDownList.findIndex(drop => drop === "dropCarac"+index)
            dropDownList.splice(indexDropDownCarac,1);
            
            let indexDropDownCaracOpt = dropDownList.findIndex(drop => drop === "dropOptCarac"+index)
            dropDownList.splice(indexDropDownCaracOpt,1);

            this.setState({caracInputArray, caracSelected, optCaracSelected, optCaracArray,dropDownList});
        }
    }
    _removeCaracOptItem(indexAttr, item){
        let {optCaracArray} = this.state;

        let index = optCaracArray[indexAttr].indexOf(item);
        if (index > -1) optCaracArray[indexAttr].splice(index,1);

        this.setState({optCaracArray});
    }


    // Attributs
    _selectAttribut(index, attr){
        let {attributSelected, optAttrArray, optAttrSelected} = this.state;

        // When you select another attribute, you reset the options array
        if(attributSelected[index] !== attr){
            optAttrSelected[index] = '';
            optAttrArray[index]    = [];
        } 
        attributSelected[index] = attr;

        this.setState({attributSelected, optAttrArray, optAttrSelected});
        dropdownService.closeDropDown("dropAttr"+index);
    }
    _selectAttrOption(index, option){
        let {optAttrArray, optAttrSelected} = this.state;

        if(!optAttrArray[index]) optAttrArray[index] = [];
        if(!optAttrArray[index].includes(option)) optAttrArray[index].push(option);

        optAttrSelected[index] = option;
        
        this.setState({optAttrArray, optAttrSelected});
        dropdownService.closeDropDown("dropOptAttr"+index);
    }
    _addAttrItem(index){
        let {attributsInputArray, attributSelected, optAttrSelected, dropDownList} = this.state;
        let attributsArray = this.props.attributs;

        // Add a new line
        attributsInputArray[attributsInputArray.length] = '';
        if(attributsArray.length !== 0){
            if(attributSelected.length === 0){
                attributSelected.push({nom:''});
                optAttrSelected.push('');
            } 
            else{
                attributSelected[index] = {nom:''};
                optAttrSelected[index]  = '';
            }
            dropDownList.push("dropAttr"+(attributsInputArray.length-1));
            dropDownList.push("dropOptAttr"+(attributsInputArray.length-1));
        };
        this.setState({ attributsInputArray, attributSelected, optAttrSelected, dropDownList });
    }
    _removeAttr(index){
        let {attributsInputArray, attributSelected, optAttrSelected, optAttrArray, dropDownList} = this.state;
        if(attributsInputArray.length > 1 && index > -1){
            attributsInputArray.splice(index,1);
            attributSelected.splice(index,1);
            optAttrSelected.splice(index,1);
            optAttrArray.splice(index,1);

            let indexDropDownAttr = dropDownList.findIndex(drop => drop === "dropAttr"+index)
            dropDownList.splice(indexDropDownAttr,1);
            
            let indexDropDownAttrOpt = dropDownList.findIndex(drop => drop === "dropOptAttr"+index)
            dropDownList.splice(indexDropDownAttrOpt,1);
            
            this.setState({attributsInputArray, attributSelected, optAttrSelected, optAttrArray, dropDownList});
        } 
    }
    _removeAttrOptItem(indexAttr, item){
        let {optAttrArray} = this.state;

        let index = optAttrArray[indexAttr].indexOf(item);
        if (index > -1) optAttrArray[indexAttr].splice(index,1);

        this.setState({optAttrArray});
    }

    /* Press enter to add category */
	async _handleEnterKeyPressed(e,index){
        let {imagesInputArray} = this.state;
		if(e.keyCode === 13){
            if(index === imagesInputArray.length -1){
                await this._addImageInputArray();
                this[`textInput${this.state.imagesInputArray.length-1}`].focus();

            }
		}
	}
    
    /*-------------------------------------------------*/
	/*------------------- Displaying ------------------*/
    /*-------------------------------------------------*/

    /* Displaying the category input */
    _displayCategory(parentID){
        let here = this;
        let {dropDownList, categorieSelected} = this.state;
        if(!categorieSelected[parentID]){
            categorieSelected[parentID] = {};
            categorieSelected[parentID]['nom'] = "";
            categorieSelected[parentID]['parentID'] = undefined;
            dropDownList.push('dropCat'+parentID);
        } 
        let categoriesArray = this.props.categories;

        if(!this.props.edit){
            return(
                <div key={parentID}>
                    <div className="displayColumn">
                        {parentID === 0 && (<div className="slidableMenu-content-block-label">Catégorie*</div>)}
                        {parentID !== 0 && (<div className="slidableMenu-content-block-label">Sous-Catégorie</div>)}
                        <div className="dropdown">
                            <button className="dropbtn" onClick={() => dropdownService.openDropDown("dropCat"+parentID,JSON.parse(JSON.stringify(dropDownList)))}><div className="dropdown-name">{stringService.uppercaseFirstLetter(categorieSelected[parentID].nom)}</div><i className="icons-font arrow icon-chevron-down"></i></button>
                            <div className="dropdown-content" id={"dropCat"+parentID}>
                                {categoriesArray.map((cat, index) => {
                                    if(cat.parentID === parentID){
                                        return <div key={index} onClick={() => here._selectCategorie(cat,parentID)}>{stringService.uppercaseFirstLetter(cat.nom)}</div>
                                    }else return null;
                                })}
                            </div>
                        </div>
                    </div>
                {/* Sous-Categories */}
                    {here._loopSubCategories(parentID)}
                </div>
            )
        }else{
            return this.props.data.categories.map((catSelected, indexCat) => {
                categorieSelected[indexCat] = catSelected;
                return(
                    <div key={indexCat}>
                        <div className="displayColumn">
                            <div className="slidableMenu-content-block-label">Catégorie*</div>
                            <div className="dropdown">
                                <button className="dropbtn" disabled><div className="dropdown-name">{stringService.uppercaseFirstLetter(catSelected.nom)}</div></button>
                            </div>
                        </div>
                    </div>
                )
            })
        }
    }

    /* Loop through subCategories */
    _loopSubCategories(parentID){
        let {categorieSelected} = this.state;
        let categoriesArray = this.props.categories;
        let here = this;

        for(let i=0; i<categoriesArray.length; i++){
            if(categoriesArray[i].parentID === categorieSelected[parentID]['id']){
                return here._displayCategory(categoriesArray[i].parentID);
            }
        }
    }

  /*-------------------------------------------------*/
  /*-------------------- Firebase -------------------*/
  /*-------------------------------------------------*/

    /* Send the proper categories we need to send to Firebase */
    _findChildCategories(parentID){
        let {categorieSelected,categoriesToSend} = this.state;
         categorieSelected.forEach((catSelect) =>{
            if(catSelect.parentID === parentID){
                let index = categoriesToSend.findIndex(catToSend => catToSend.id === catSelect.id)
                if(index === -1) categoriesToSend.push(catSelect);
                this._findChildCategories(catSelect.id);
            }
        })
        this.setState({categoriesToSend});
    }

    /* Create declinaisons with differents attributes */
    async _associateNextAttributes(index, attributs, idProd, newProdOpt){
        let that = this;

        for(let attrOpt of attributs[index].options){
            newProdOpt.attributs[index]['options'] = attrOpt;
            newProdOpt['idProd'] = idProd;
    
            if(attributs[index+1]){
                that._associateNextAttributes(index+1, attributs, idProd, newProdOpt);
            } 
            else{
                delete newProdOpt.nom;
                delete newProdOpt.actif;
                delete newProdOpt.poids;
                delete newProdOpt.taxe;
                delete newProdOpt.categories;
                delete newProdOpt.desc_courte;
                delete newProdOpt.desc_longue;
                delete newProdOpt.dispoCommande;
                delete newProdOpt.suppImages;
                delete newProdOpt.visibility;
                delete newProdOpt.caracteristiques;
                delete newProdOpt.dispoLigne;

                let completed = that._getCompletedValue(0);

                newProdOpt['complete']  = completed;

                that.props.declinRef.collection(that.state.langueSelected).add(newProdOpt); 
            }
        }
    }

    /* Send data to Firebase */
    async _sendToFirebase(){
        let that = this;
        let {produitAdded, categoriesToSend, caracInputArray, caracSelected, optCaracArray, attributsInputArray, attributSelected, optAttrArray} = this.state;

        let errorMessage = '';

        this.setState({isDisabled : true});

        let todayDate = stringService.getActualDate();
        let caracteristiques = [];
        let attributs        = [];
        let missCaracOptions = false;
        let missAttrOptions  = false;

        if(this.state.nom !== ''){
            if(this.state.reference !== ''){
                // Categories
                this._findChildCategories(0);

                    // Caracteristiques
                    for(let i=0; i<caracInputArray.length; i++){
                        if(!optCaracArray[i]) optCaracArray[i] = [];

                        if(caracSelected[i] && caracSelected[i] !== {}){
                            if(caracSelected[i].nom){
                                caracteristiques.push({nom: caracSelected[i].nom, options: optCaracArray[i]})
                                if(optCaracArray[i].length === 0) missCaracOptions = true;
                            } 
                        } 
                    }

                    // Attributs
                    for(let i=0; i<attributsInputArray.length; i++){
                        if(!optAttrArray[i]) optAttrArray[i] = [];

                        if(attributSelected[i] && attributSelected[i] !== {}){
                            if(attributSelected[i].nom){
                                attributs.push({nom: attributSelected[i].nom, type:attributSelected[i].type, options:optAttrArray[i]})
                                if(optAttrArray[i].length === 0) missAttrOptions = true;
                            } 
                        } 
                    }
                    let productToSend = {
                        reference     : that.state.reference,
                        prix_ht       : that.state.prixHT,
                        ean13         : that.state.ean13,
                        upc           : that.state.upc,
                        eco           : that.state.eco,
                        quantity      : that.state.quantity,
                        quantityMin   : that.state.quantityMin,
                        images        : that.state.imagesInputArray,
                        maj_date      : todayDate,
                        creation_date : that.props.data.creation_date
                    };

                    // EDIT
                    if(this.props.edit){
                        let completed = that._getCompletedValue(0);
                        
                        // Principal
                        if(this.props.typeProd === 0){
                            productToSend['id']               = that.props.data.id;
                            productToSend['nom']              = that.state.nom;
                            productToSend['taxe']             = that.state.taxe;
                            productToSend['actif']            = that.state.actif;
                            productToSend['poids']            = that.state.poids;
                            productToSend['visibility']       = that.state.visibility;
                            productToSend['desc_courte']      = that.state.descCourte;
                            productToSend['desc_longue']      = that.state.descLongue;
                            productToSend['dispoCommande']    = that.state.dispoCommande;
                            productToSend['suppImages']       = that.state.suppImages;
                            productToSend['dispoLigne']       = that.state.dispoLigne;
                            productToSend['complete']         = completed;
                            productToSend['categories']       = that.props.data.categories;
                            productToSend['caracteristiques'] = that.props.data.caracteristiques;

                            this.props.produitsRef.collection(that.state.langueSelected).where("id", "==", this.props.data.id).get().then(function(querySnapshot){
                                querySnapshot.forEach(function(doc) {
                                    if(doc){
                                        that.props.produitsRef.collection(that.state.langueSelected).doc(doc.id).set(productToSend).then((_) => { 
                                            that.setState({ produitAdded : true }); 
                                            that.props.onReload();
                                        }).catch(function(error) {console.error("Erreur d'édit du produit ", error)});
                                    }
                                });
                            });
                        
                        // Declinaison
                        }else{
                            let completed = that._getCompletedValue(1);
                        
                            productToSend['idProd']    = that.props.data.idProd;
                            productToSend['attributs'] = that.props.data.attributs;
                            productToSend['complete']  = completed;
                            that.props.declinRef.collection(that.state.langueSelected).doc(that.props.data.idFirebase).set(productToSend).then((_) => { 
                                that.setState({ produitAdded : true }); 
                                that.props.onReload();
                            }).catch(function(error) {console.error("Erreur d'édit du produit ", error)});
                        }
                        
                    // ADD
                    }else{
                        if(categoriesToSend[0] && categoriesToSend[0].nom !== ''){
                            if(caracteristiques.length !== 0){
                                if(!missCaracOptions){
                                    if(attributs.length !== 0){
                                        if(!missAttrOptions){
                                            let highiestIdProdFound = -1;
                                            await that.props.produitsRef.collection(that.state.langueSelected).get().then(function(snap) {
                                                snap.forEach(function(doc) {
                                                    if(doc.data().id > highiestIdProdFound) highiestIdProdFound = doc.data().id;
                                                });
                                            });
                                            let completed = that._getCompletedValue(0);

                                            productToSend['nom']              = that.state.nom;
                                            productToSend['taxe']             = that.state.taxe;
                                            productToSend['actif']            = that.state.actif;
                                            productToSend['poids']            = that.state.poids;
                                            productToSend['visibility']       = that.state.visibility;
                                            productToSend['desc_courte']      = that.state.descCourte;
                                            productToSend['desc_longue']      = that.state.descLongue;
                                            productToSend['dispoCommande']    = that.state.dispoCommande;
                                            productToSend['suppImages']       = that.state.suppImages;
                                            productToSend['dispoLigne']       = that.state.dispoLigne;
                                            productToSend['caracteristiques'] = caracteristiques;
                                            productToSend['creation_date']    = todayDate;
                                            productToSend['complete']         = completed;
                                            productToSend['categories']       = categoriesToSend;
                                            productToSend['attributs']        = attributs;

                                            let idProd = highiestIdProdFound+1;
                                            
                                            // Creating declinaison
                                            if(attributs.length > 0){
                                                let i=0;
                                                let newProdOpt = JSON.parse(JSON.stringify(productToSend)) // copy prod object
                                                
                                                // let highiestIdDecliFound = -1;
                                                // await that.props.declinRef.collection(that.state.langueSelected).get().then(function(snap) {
                                                //     snap.forEach(function(doc) {
                                                //         if(doc.data().id > highiestIdDecliFound) highiestIdDecliFound = doc.data().id;
                                                //     });
                                                // });
                                            
                                                that._associateNextAttributes(i, attributs, idProd, newProdOpt)
                                            }
                                            
                                            productToSend['id'] = idProd;
                                            
                                            delete productToSend.attributs;
                                            that.props.produitsRef.collection(that.state.langueSelected).add(productToSend).then((_) => { 
                                                that.props.onReload();
                                                that.setState({produitAdded : true}); 
                                            });

                                        }else errorMessage = "Il manque une ou plusieurs options à vos attributs.";
                                    }else errorMessage = "Veuillez ajouter un attribut. Si aucun n'existe, veuillez en créer un dans l'onglet : Paramètres/Attributs";
                                }else errorMessage = "Il manque une ou plusieurs options à vos caractéristiques.";
                            }else errorMessage = "Veuillez ajouter une caractéristique. Si aucune n'existe, veuillez en créer une dans l'onglet : Paramètres/Caractéristiques"; 
                        }else errorMessage = "Veuillez entrer une catégorie. Si aucune n'existe, veuillez en créer une dans l'onglet : Paramètres/Catégories.";
                    }

                    // Reset categories
                    this.setState({categoriesToSend: []});
                       
            }else errorMessage = "Veuillez entrer une référence.";
        }else errorMessage = "Veuillez entrer un nom.";
        this.setState({produitAdded:produitAdded, errorMessage:errorMessage, isDisabled:false});
    }

    render() {
        let here = this;
        let caracteristiquesArray = this.props.caracteristiques;
        let attributsArray        = this.props.attributs;
        let edit                  = this.props.edit;
        let typeProd              = this.props.typeProd

        let {
            isDisabled,
            imagesInputArray,
            caracInputArray, caracSelected, optCaracSelected, optCaracArray,
            attributsInputArray, attributSelected, optAttrSelected, optAttrArray,
            produitAdded, dropDownList
        } = this.state;

        let optionsListCarac = [];
        let optionsListAttr  = [];

        return (
            <div>
                {!produitAdded &&(
                    <div className="slidableMenu-content-block">
                    {/* Nom du produit */}
                        {typeProd === 0 && (
                            <div>
                                <div className="slidableMenu-content-block-label">Nom du produit*</div>
                                {!edit &&(<input className="slidableMenu-content-block-input-short" type="text" value={this.state.nom} onChange={this._selectNom} style={this._getCompletedColor(this.state.nom)}></input>)}
                                {edit  &&(<input className="slidableMenu-content-block-input-short" type="text" value={this.state.nom} onChange={this._selectNom} style={this._getCompletedColor(this.state.nom)} disabled></input>)}
                            </div>
                        )}
                    {/* Référence */}
                        <div className="slidableMenu-content-block-label">Référence*</div>
                        {!edit &&( <input className="slidableMenu-content-block-input-short" type="text" value={this.state.reference} onChange={this._selectReference} style={this._getCompletedColor(this.state.reference)}></input>)}
                        {edit  &&( <input className="slidableMenu-content-block-input-short" type="text" value={this.state.reference} onChange={this._selectReference} style={this._getCompletedColor(this.state.reference)} disabled></input>)}
                        
                    {/* Categories */}
                        {typeProd === 0 && (
                            here._displayCategory(0)
                        )}
                    {/* Prix HT */}
                        <div className="slidableMenu-content-block-label">Prix HT</div>
                        <input className="slidableMenu-content-block-input-short" type="number" value={this.state.prixHT} onChange={this._selectPrixHT} style={this._getCompletedColor(this.state.prixHT)}></input>
                    {/* ID règles de taxes */}
                        {typeProd === 0 && (
                            <div>
                                <div className="slidableMenu-content-block-label">ID règles de taxes</div>
                                <div className="dropdown">
                                    <button className="dropbtn" onClick={() => dropdownService.openDropDown('dropTaxe',JSON.parse(JSON.stringify(dropDownList)))}><div className="dropdown-name">{stringService.convertTaxeToString(this.state.langueSelected,this.state.taxe)}</div><i className="icons-font arrow icon-chevron-down"></i></button>
                                    <div className="dropdown-content" id="dropTaxe">
                                        <div onClick={() => this._selectTaxe(0)}>Aucune taxe</div>
                                        <div onClick={() => this._selectTaxe(1)}>2,1%</div>
                                        <div onClick={() => this._selectTaxe(2)}>5,5%</div>
                                        <div onClick={() => this._selectTaxe(3)}>10%</div>
                                        <div onClick={() => this._selectTaxe(4)}>20%</div>
                                    </div>
                                </div>
                            </div>
                        )}

                    {/*  Actif */}
                        {typeProd === 0 && (
                            <div>
                                <div className="slidableMenu-content-block-label">Actif</div>
                                <div className="dropdown">
                                    <button className="dropbtn" onClick={() => dropdownService.openDropDown('dropActif',JSON.parse(JSON.stringify(dropDownList)))}><div className="dropdown-name">{stringService.convertBoolToString(this.state.actif)}</div><i className="icons-font arrow icon-chevron-down"></i></button>
                                    <div className="dropdown-content" id="dropActif">
                                        <div onClick={() => this._selectActif(0)}>Non</div>
                                        <div onClick={() => this._selectActif(1)}>Oui</div>
                                    </div>
                                </div>
                            </div>
                        )}

                    {/* EAN-13 */}
                        <div className="slidableMenu-content-block-label">EAN-13 <i className="icons-font help icon-question-circle" title="Le champs doit comporter 13 chiffres pour être valide."></i></div>
                        <input className="slidableMenu-content-block-input-short" type="number" value={this.state.ean13} onChange={this._selectEan13} style={this._getCompletedColor(this.state.ean13,"ean13")}></input>
                    {/* UPC */}
                        <div className="slidableMenu-content-block-label">UPC</div>
                        <input className="slidableMenu-content-block-input-short" type="number" value={this.state.upc} onChange={this._selectUPC} style={this._getCompletedColor(this.state.upc)}></input>
                    {/* Eco-participation */}
                        <div className="slidableMenu-content-block-label">Eco-participation</div>
                        <div className="dropdown">
                            <button className="dropbtn" onClick={() => dropdownService.openDropDown('dropEco',JSON.parse(JSON.stringify(dropDownList)))}><div className="dropdown-name">{stringService.convertTaxeToString(this.state.langueSelected,this.state.eco)}</div><i className="icons-font arrow icon-chevron-down"></i></button>
                            <div className="dropdown-content" id="dropEco">
                                <div onClick={() => this._selectEco(0)}>Aucune taxe</div>
                                <div onClick={() => this._selectEco(1)}>2,1%</div>
                                <div onClick={() => this._selectEco(2)}>5,5%</div>
                                <div onClick={() => this._selectEco(3)}>10%</div>
                                <div onClick={() => this._selectEco(4)}>20%</div>
                            </div>
                        </div>
                    {/* Poids */}
                        {typeProd === 0 && (
                            <div>
                                <div className="slidableMenu-content-block-label">Poids (kg)</div>
                                <input className="slidableMenu-content-block-input-short" type="number"   value={this.state.poids} onChange={this._selectPoids} style={this._getCompletedColor(this.state.poids)}></input>
                            </div>
                        )}
                    {/* Quantité */}
                        <div className="slidableMenu-content-block-label">Quantité</div>
                        <input className="slidableMenu-content-block-input-short" type="number" value={this.state.quantity} onChange={this._selectQuantity} style={this._getCompletedColor(this.state.quantity)}></input>
                    {/* Quantité minimale */}
                        <div className="slidableMenu-content-block-label">Quantité minimale</div>
                        <input className="slidableMenu-content-block-input-short" type="number" value={this.state.quantityMin} onChange={this._selectQuantityMin} style={this._getCompletedColor(this.state.quantityMin)}></input>
                    {/* Visibilité */}
                        {typeProd === 0 && (
                            <div>
                                <div className="slidableMenu-content-block-label">Visibilité</div>
                                <div className="dropdown">
                                    <button className="dropbtn" onClick={() => dropdownService.openDropDown('dropVisibility',JSON.parse(JSON.stringify(dropDownList)))}><div className="dropdown-name">{stringService.convertVisibility(this.state.visibility)}</div><i className="icons-font arrow icon-chevron-down"></i></button>
                                    <div className="dropdown-content" id="dropVisibility">
                                        <div onClick={() => this._selectVisibility('both')}>Partout</div>
                                        <div onClick={() => this._selectVisibility('catalog')}>Catalogue uniquement</div>
                                        <div onClick={() => this._selectVisibility('search')}>Recherche uniquement</div>
                                        <div onClick={() => this._selectVisibility('none')}>Nulle part</div>
                                    </div>
                                </div>
                            </div>
                        )}
                    {/* Description courte */}
                        {typeProd === 0 && (
                            <div>
                                <div className="slidableMenu-content-block-label">Résumé <i className="icons-font help icon-question-circle" title="Il est important de dissocier la description courte de la description longue afin d’optimiser le référencement naturel."></i></div>
                                <textarea className="slidableMenu-content-block-input-long" type="text" placeholder="Maximum 150 mots" maxLength="800" value={this.state.descCourte} onChange={this._selectDescCourte} style={this._getCompletedColor(this.state.descCourte,'desc_courte')}></textarea>
                                <div className="slidableMenu-content-block-label-counter">{this.state.descCourte.length}/800 caractères maximum</div>                        
                            </div>
                        )}
                    {/* Description longue */}
                        {typeProd === 0 && (
                            <div>
                                <div className="slidableMenu-content-block-label">Description <i className="icons-font help icon-question-circle" title="Il est important de dissocier la description courte de la description longue afin d’optimiser le référencement naturel."></i></div>
                                <textarea className="slidableMenu-content-block-input-long" type="text" placeholder="Minimum 300 mots" value={this.state.descLongue} onChange={this._selectDescLongue} style={this._getCompletedColor(this.state.descLongue,'desc_longue')}></textarea>
                                <div className="slidableMenu-content-block-label-counter">{stringService.countWords(this.state.descLongue)}/300 mots minimum</div>
                            </div>
                        )}
                    {/* Disponibilité Commande */}
                        {typeProd === 0 && (
                            <div>
                                <div className="slidableMenu-content-block-label">Disponibilité à la commande</div>
                                <div className="dropdown">
                                    <button className="dropbtn" onClick={() => dropdownService.openDropDown('dropCommande',JSON.parse(JSON.stringify(dropDownList)))}><div className="dropdown-name">{stringService.convertBoolToString(this.state.dispoCommande)}</div><i className="icons-font arrow icon-chevron-down"></i></button>
                                    <div className="dropdown-content" id="dropCommande">
                                        <div onClick={() => this._selectDispoCommande(0)}>Non</div>
                                        <div onClick={() => this._selectDispoCommande(1)}>Oui</div>
                                    </div>
                                </div>
                            </div>
                        )}
                    {/* Image */}
                        <div className="slidableMenu-content-block-label">Image(s)</div>
                        <div className="slidableMenu-content-block-flex">
                            <div className="dropdown-dynamic">
                                {imagesInputArray.map((img, indexImg) => {
                                    return(
                                        <div key={indexImg} className="slidableMenu-content-block-flex-block">
                                            <div><input className="slidableMenu-content-block-flex-block-input" type="text" ref={input => {this[`textInput${indexImg}`] = input}} value={img} onChange={e => this._selectImage(indexImg, e)} style={this._getCompletedColor(imagesInputArray[indexImg],"image")}  onKeyDown={(e) => this._handleEnterKeyPressed(e, indexImg)}></input></div>
                                            <i className="icons-font option icon-trash" onClick={() => this._removeImage(indexImg)}></i>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <i className="icons-font add icon-plus-circle" onClick={() => this._addImageInputArray()}></i>
                    {/* Supprimer les images existantes */}
                        {typeProd === 0 && (
                            <div>
                                {this.props.role.nom === "admin" && (
                                    <div className="displayColumn">
                                        <div className="slidableMenu-content-block-label">Supprimer les images existantes</div>
                                        <div className="dropdown">
                                            <button className="dropbtn" onClick={() => dropdownService.openDropDown('dropSuppImages',JSON.parse(JSON.stringify(dropDownList)))}><div className="dropdown-name">{stringService.convertBoolToString(this.state.suppImages)}</div><i className="icons-font arrow icon-chevron-down"></i></button>
                                            <div className="dropdown-content" id="dropSuppImages">
                                                <div onClick={() => this._selectSuppImages(0)}>Non</div>
                                                <div onClick={() => this._selectSuppImages(1)}>Oui</div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    {/* IF NOT EDIT : Select caracteristics */}
                        {typeProd === 0 && (
                            <div>
                                {!edit &&(
                                    <div>
                                        {caracInputArray.map((caracInput, indexCaracInput) => {
                                            return(
                                                <div key={indexCaracInput}>
                                                    {caracSelected[indexCaracInput] && (
                                                    <div>
                                                        <div className="displayRow">
                                                            <div className="displayColumn">
                                                                <div className="slidableMenu-content-block-label">Caractéristique*</div>
                                                                <div className="dropdown">
                                                                    <button className="dropbtn produitAttr" onClick={() => dropdownService.openDropDown("dropCarac"+indexCaracInput,JSON.parse(JSON.stringify(dropDownList)))}><div className="dropdown-name">{stringService.uppercaseFirstLetter(caracSelected[indexCaracInput].nom)}</div><i className="icons-font arrow icon-chevron-down"></i></button>
                                                                    <div className="dropdown-content longAttr" id={"dropCarac"+indexCaracInput}>
                                                                        {caracteristiquesArray.map((data, index) => {
                                                                            if(!optionsListCarac[indexCaracInput]) optionsListCarac[indexCaracInput] = [];
                                                                            if(caracSelected[indexCaracInput].nom === data.nom){
                                                                                data.options.forEach((opt) => {
                                                                                    optionsListCarac[indexCaracInput].push(opt);
                                                                                });
                                                                            }
                                                                            if(!caracSelected.find(carac => carac === data)){
                                                                                return <div key={index} onClick={() => this._selectCarac(indexCaracInput, data)}>{stringService.uppercaseFirstLetter(data.nom)}</div>
                                                                            }else return null;
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="slidableMenu-content-block right">
                                                                <div className="slidableMenu-content-block-label short">Options</div>
                                                                <div className="displayRow">
                                                                    <div className="dropdown">
                                                                        <button className="dropbtn short" onClick={() => dropdownService.openDropDown("dropOptCarac"+indexCaracInput,JSON.parse(JSON.stringify(dropDownList)))}><div className="dropdown-name">{optCaracSelected[indexCaracInput]}</div><i className="icons-font arrow icon-chevron-down"></i></button>
                                                                        <div className="dropdown-content short" id={"dropOptCarac"+indexCaracInput}>
                                                                            {optionsListCarac[indexCaracInput].map((data, index) => {
                                                                                return <div key={index} onClick={() => this._selectCaracOption(indexCaracInput, data)}>{data}</div>
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                    <i className="icons-font pointer icon-times-circle" onClick={() => this._removeCarac(indexCaracInput)}></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="displayRow">
                                                            <Row className="selected-items-block">
                                                                {optCaracArray[indexCaracInput] &&(
                                                                    optCaracArray[indexCaracInput].map((data, index) => {
                                                                        return(
                                                                            <div className="selected-items-item" key={index}>
                                                                                <div>{data}</div>
                                                                                <i className="icons-font selectable icon-close" onClick={() => this._removeCaracOptItem(indexCaracInput, data)}></i>
                                                                            </div>
                                                                        );
                                                                    })
                                                                )}
                                                            </Row>
                                                        </div>
                                                    </div>)}
                                                </div>
                                            );
                                        })}
                                        {this.state.langueSelected !== '' && caracSelected.length !== 0 && caracteristiquesArray.length !== caracSelected.length &&(
                                            <i className="icons-font add icon-plus-circle" onClick={() => here._addCaracItem(caracInputArray.length)}></i>
                                        )}
                                    </div>
                                )}
                            {/* IF EDIT : just read caracteristics */}
                                {edit &&(
                                    <div>
                                        {caracInputArray.map((caracInput, indexCaracInput) => {
                                            return(
                                                <div key={indexCaracInput}>
                                                    {caracSelected[indexCaracInput] && (
                                                    <div>
                                                        <div className="displayRow">
                                                            <div className="displayColumn">
                                                                <div className="slidableMenu-content-block-label">Caractéristique*</div>
                                                                <div className="dropdown">
                                                                    <button className="dropbtn produitAttr" disabled><div className="dropdown-name">{stringService.uppercaseFirstLetter(caracSelected[indexCaracInput].nom)}</div></button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="displayRow">
                                                            <Row className="selected-items-block">
                                                                {optCaracArray[indexCaracInput] &&(
                                                                    optCaracArray[indexCaracInput].map((data, index) => {
                                                                        return(
                                                                            <div className="selected-items-item" key={index}>
                                                                                <div>{data}</div>
                                                                            </div>
                                                                        );
                                                                    })
                                                                )}
                                                            </Row>
                                                        </div>
                                                    </div>)}
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        )}

                    {/* Disponibilité en ligne */}
                        {typeProd === 0 && (
                            <div>
                                <div className="slidableMenu-content-block-label">Disponible en ligne uniquement</div>
                                <div className="dropdown">
                                    <button className="dropbtn" onClick={() => dropdownService.openDropDown('dropDispoLigne',JSON.parse(JSON.stringify(dropDownList)))}><div className="dropdown-name">{stringService.convertBoolToString(this.state.dispoLigne)}</div><i className="icons-font arrow icon-chevron-down"></i></button>
                                    <div className="dropdown-content" id="dropDispoLigne">
                                        <div onClick={() => this._selectDispoLigne(0)}>Non</div>
                                        <div onClick={() => this._selectDispoLigne(1)}>Oui</div>
                                    </div>
                                </div>
                            </div>
                        )}

                    {/* IF NOT EDIT : Select attributes */}
                        {!edit &&(
                            <div>
                                {attributsInputArray.map((attrInput, indexAttrInput) => {
                                    return(
                                        <div key={indexAttrInput}>
                                            {attributSelected[indexAttrInput] && (
                                            <div>
                                                <div className="displayRow">
                                                    <div className="displayColumn">
                                                        <div className="slidableMenu-content-block-label">Attribut*</div>
                                                        <div className="dropdown">
                                                            <button className="dropbtn produitAttr" onClick={() => dropdownService.openDropDown("dropAttr"+indexAttrInput,JSON.parse(JSON.stringify(dropDownList)))}><div className="dropdown-name">{stringService.uppercaseFirstLetter(attributSelected[indexAttrInput].nom)}</div><i className="icons-font arrow icon-chevron-down"></i></button>
                                                            <div className="dropdown-content longAttr" id={"dropAttr"+indexAttrInput}>
                                                                {attributsArray.map((data, index) => {
                                                                    if(!optionsListAttr[indexAttrInput]) optionsListAttr[indexAttrInput] = [];
                                                                    if(attributSelected[indexAttrInput].nom === data.nom){
                                                                        data.options.forEach((opt) => {
                                                                            optionsListAttr[indexAttrInput].push(opt);
                                                                        });
                                                                    }
                                                                    if(!attributSelected.find(attr => attr === data)){
                                                                        return <div key={index} onClick={() => this._selectAttribut(indexAttrInput, data)}>{stringService.uppercaseFirstLetter(data.nom)}</div>
                                                                    }else return null;
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="slidableMenu-content-block right">
                                                        <div className="slidableMenu-content-block-label short">Options</div>
                                                        <div className="displayRow">
                                                            <div className="dropdown">
                                                                <button className="dropbtn short" onClick={() => dropdownService.openDropDown("dropOptAttr"+indexAttrInput,JSON.parse(JSON.stringify(dropDownList)))}><div className="dropdown-name">{optAttrSelected[indexAttrInput]}</div><i className="icons-font arrow icon-chevron-down"></i></button>
                                                                <div className="dropdown-content short" id={"dropOptAttr"+indexAttrInput}>
                                                                    {optionsListAttr[indexAttrInput].map((data, index) => {
                                                                        return <div key={index} onClick={() => this._selectAttrOption(indexAttrInput, data)}>{data}</div>
                                                                    })}
                                                                </div>
                                                            </div>
                                                            <i className="icons-font pointer icon-times-circle" onClick={() => this._removeAttr(indexAttrInput)}></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="displayRow">
                                                    <Row className="selected-items-block">
                                                        {optAttrArray[indexAttrInput] &&(
                                                            optAttrArray[indexAttrInput].map((data, index) => {
                                                                return (
                                                                    <div className="selected-items-item" key={index}>
                                                                        <div>{data}</div>
                                                                        <i className="icons-font selectable icon-close" onClick={() => this._removeAttrOptItem(indexAttrInput, data)}></i>
                                                                    </div>
                                                                );
                                                            })
                                                        )}
                                                    </Row>
                                                </div>
                                            </div>)}
                                        </div>
                                    );
                                })}
                                {this.state.langueSelected !== '' && attributSelected.length !== 0 && attributsArray.length !== attributSelected.length &&(
                                    <i className="icons-font add icon-plus-circle" onClick={() => here._addAttrItem(attributsInputArray.length)}></i>
                                )}
                            </div>
                        )}
                    {/* IF EDIT : just read attributes */}
                        {edit && typeProd===1 && (
                            <div>
                                {attributsInputArray.map((attrInput, indexAttrInput) => {
                                    return(
                                        <div key={indexAttrInput}>
                                            {attributSelected[indexAttrInput] && (
                                            <div>
                                                <div className="displayRow">
                                                    <div className="displayColumn">
                                                        <div className="slidableMenu-content-block-label">Attribut*</div>
                                                        <div className="dropdown">
                                                            <button className="dropbtn produitAttr" disabled ><div className="dropdown-name">{stringService.uppercaseFirstLetter(attributSelected[indexAttrInput].nom)}</div></button>
                                                        </div>
                                                    </div>
                                                    <div className="slidableMenu-content-block right">
                                                        <div className="slidableMenu-content-block-label shortEdit">Option</div>
                                                        <div className="displayRow">
                                                            <div className="dropdown">
                                                                <button className="dropbtn short" disabled><div className="dropdown-name">{optAttrSelected[indexAttrInput]}</div></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>)}
                                        </div>
                                    );
                                })}
                            </div>
                        )}

                    {/* Error message */}
                        <div className="slidableMenu-content-block-label center">{this.state.errorMessage}</div>
                    {/* Buttons */}
                        <div className="slidableMenu-content-block-buttons">
                            {this.props.edit &&(<input className="slidableMenu-content-block-buttons-button white" type="button" value="Editer et continuer" onClick={() => this._sendToFirebase()} disabled={isDisabled}></input>)}
                            {!this.props.edit &&(<input className="slidableMenu-content-block-buttons-button white" type="button" value="Ajouter et continuer" onClick={() => this._sendToFirebase()} disabled={isDisabled}></input>)}
                            <input className="slidableMenu-content-block-buttons-button dark"  type="button" value="Fermer" onClick={() => this.props.onClose()}></input>
                        </div>
                    </div>
                )}
            
                {produitAdded &&(
                    <div className="slidableMenu-content-block">
                        <div className="slidableMenu-content-block-label center">Le produit a été ajouté avec succès !</div>
                        <div className="slidableMenu-content-block-buttons">
                            <input className="slidableMenu-content-block-buttons-button white" type="button" value="Fermer" onClick={() => this._closePanel()}></input>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default SlidableMenu_Produits;

