/** Natives **/
import React, { Component } from "react";
/** Style **/
import "./Drawer.scss";
import colors from '../../assets/Colors.scss';
/* Images */
import logoTizBlanc from '../../assets/logo-tiz-blanc.png'; 
/* Plugins */
import { browserHistory } from 'react-router'; 
/* Services */
import StringService   from '../../services/StringService';
import DropdownService from '../../services/DropdownService';
//Class
const stringService   = new StringService();
const dropdownService = new DropdownService();

class DrawerComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            style_selected   : {"background": colors.darkblue, "color": colors.white, "boxShadow": "0 5px 7px rgba(0, 0, 0, 0.16)" },
            style_unselected : {"background": colors.white,    "color": colors.darkblue },

            languageSelected : '',

            projetsSelected       : false,
            progressionSelected   : true,
            produitsSelected      : false,
            parametresSelected    : false,
            configurationSelected : false,
            exportsSelected       : false,

            user : {},
            isProjectDisabled     : false,
            isProgressionDisabled : false,
            isProductsDisabled    : false,
            isParametersDisabled  : false,
            isConfigDisabled      : false,
            isExportDisabled      : false,
        }
    }

    // Change draw if its change from parent component
    componentDidUpdate(prevProps, prevState) {
        let draw = this.props.draw;
        let {projetsSelected, progressionSelected, produitsSelected, parametresSelected, configurationSelected, exportsSelected} = this.state;

        if(this.props.langues !== prevProps.langues){
            this.setState({languageSelected : this.props.langues[0].nom});
            this.props.onSelectLang(this.state.languageSelected);
        } 
        if(this.props.draw !== prevProps.draw) this._selectDraw(this.props.draw);

        if(this.props.isDisabled !== prevProps.isDisabled){
            if(this.props.selectedProject === null){
                this.setState({
                    isProjectDisabled     : false,
                    isProgressionDisabled : true,
                    isProductsDisabled    : true,
                    isParametersDisabled  : false,
                    isConfigDisabled      : false,
                    isExportDisabled      : true,
                })
            }else{
                this.setState({
                    isProjectDisabled     : this.props.isDisabled,
                    isProgressionDisabled : this.props.isDisabled,
                    isProductsDisabled    : this.props.isDisabled,
                    isParametersDisabled  : this.props.isDisabled,
                    isConfigDisabled      : this.props.isDisabled,
                    isExportDisabled      : this.props.isDisabled,
                })
            }
            if     (draw === "projets")      projetsSelected = true;
            else if(draw === "progression")  progressionSelected = true;
            else if(draw === "produits")     produitsSelected = true;
            else if(draw === "param")        parametresSelected = true;
            else if(draw === "config")       configurationSelected = true;
            else if(draw === "exports")      exportsSelected = true;

            this.setState({projetsSelected, progressionSelected, produitsSelected, parametresSelected, configurationSelected, exportsSelected});
        }
    }

    /* Select the language */
    _selectLanguage = (language) => {
        this.setState({languageSelected : language});
        this.props.onSelectLang(language);
		dropdownService.closeDropDown("dropLang");
	}

    /* Select the state of the draw */
    _selectDraw = (title) => {
        if     (title === "projets")     this.setState({ progressionSelected: false, produitsSelected: false, parametresSelected: false, configurationSelected: false, exportsSelected: false, projetsSelected: true  }) 
        else if(title === "progression") this.setState({ progressionSelected: true,  produitsSelected: false, parametresSelected: false, configurationSelected: false, exportsSelected: false, projetsSelected: false })
        else if(title === "produits")    this.setState({ progressionSelected: false, produitsSelected: true,  parametresSelected: false, configurationSelected: false, exportsSelected: false, projetsSelected: false })
        else if(title === "param")       this.setState({ progressionSelected: false, produitsSelected: false, parametresSelected: true,  configurationSelected: false, exportsSelected: false, projetsSelected: false }) 
        else if(title === "config")      this.setState({ progressionSelected: false, produitsSelected: false, parametresSelected: false, configurationSelected: true,  exportsSelected: false, projetsSelected: false }) 
        else if(title === "exports")     this.setState({ progressionSelected: false, produitsSelected: false, parametresSelected: false, configurationSelected: false, exportsSelected: true,  projetsSelected: false })

        this.props.onSelectDraw(title);
    }

    /* Select the color of the draw */
    _getColors(category){
        if(category === true) return this.state.style_selected;
        else return this.state.style_unselected;
    }

    /* Click on 'Deconnexion' */
    _onLogout(){
        browserHistory.push("/");
    }

    _displayContent(){
        let {isProjectDisabled, isProgressionDisabled, isProductsDisabled, isParametersDisabled, isConfigDisabled, isExportDisabled} = this.state;

        let projets         = this.props.role.onglets && this.props.role.onglets.projets       === true;
        let progression     = this.props.role.onglets && this.props.role.onglets.progression   === true;
        let produits        = this.props.role.onglets && this.props.role.onglets.produits      === true;
        let configuration   = this.props.role.onglets && this.props.role.onglets.configuration === true;
        let parametres      = this.props.role.onglets && this.props.role.onglets.parametres    === true;
        let exp             = this.props.role.onglets && this.props.role.onglets.export        === true;
        let langues         = this.props.langues;
        let selectedProject = this.props.selectedProject;

        return(
            <div>
            {/* Logo */}
                <div>
                    <img src={logoTizBlanc} className="drawer-logo" alt="Agence Tiz" />
                </div>

            {/* Language */}
                {selectedProject !== null && (
                    <div className="drawer-language">
                        <div className="dropdown">
                            <button className="dropbtn" onClick={() => dropdownService.openDropDown('dropLang')}><div className="dropdown-name">{stringService.uppercaseFirstLetter(this.state.languageSelected)}</div><i className="icons-font arrow icon-chevron-down"></i></button>
                            <div className="dropdown-content" id="dropLang">
                                {langues.map((data, index) => {
                                    return (
                                        <div key={index} onClick={() => this._selectLanguage(data.nom)}>{stringService.uppercaseFirstLetter(data.nom)}</div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                )}

            {/* Input button Projets */}
                {projets && (
                <div className="drawer-button">
                    <input className="drawer-button-button" type="button" value="Projets"       onClick={() => this._selectDraw('projets')}    style={this._getColors(this.state.projetsSelected)} disabled={isProjectDisabled}></input>
                </div>)}
            {/* Input button Progression */}
                {progression && (
                <div className="drawer-button">
                    <input className="drawer-button-button" type="button" value="Progression"   onClick={() => this._selectDraw('progression')} style={this._getColors(this.state.progressionSelected)} disabled={isProgressionDisabled}></input>
                </div>)}
            {/* Input button Produits */}
                {produits &&(
                <div className="drawer-button">
                    <input className="drawer-button-button" type="button" value="Produits"      onClick={() => this._selectDraw('produits')} style={this._getColors(this.state.produitsSelected)} disabled={isProductsDisabled}></input>
                </div>)}
            {/* Input button Paramètres */}
                {parametres && (
                <div className="drawer-button">
                    <input className="drawer-button-button" type="button" value="Paramètres"    onClick={() => this._selectDraw('param')}  style={this._getColors(this.state.parametresSelected)} disabled={isParametersDisabled}></input>
                </div>)}
            {/* Input button Configuration */}
                {configuration && (
                <div className="drawer-button">
                    <input className="drawer-button-button" type="button" value="Configuration" onClick={() => this._selectDraw('config')} style={this._getColors(this.state.configurationSelected)} disabled={isConfigDisabled}></input>
                </div>
                )}
            {/* Input button Exports */}
                {exp && (
                <div className="drawer-button">
                    <input className="drawer-button-button" type="button" value="Exports / Imports" onClick={() => this._selectDraw('exports')} style={this._getColors(this.state.exportsSelected)} disabled={isExportDisabled}></input>
                </div>)}

            {/* Input button Deconnexion */}
                <div className="deconnexion-button" onClick={() => this._onLogout()}>
                    <div className="deconnexion-button-icon">
                        <i className="icons-font logout icon-power-off"></i>
                    </div>
                    <div className="deconnexion-button-text">Déconnexion</div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className="drawer-container">
                {this._displayContent()}
            </div>
        );
    }
}

export default DrawerComponent;

