/** Natives **/
import React, { Component } from "react";
/** Style **/
import "./MainPage.scss";
import "../../assets/fontIcoMoon/style.css";

/* Components */
import Drawer        from '../../components/Drawer/Drawer';
import Progression   from '../../components/Progression/Progression';
import Produits      from '../../components/Produits/Produits';
import Configuration from '../../components/Configuration/Configuration';
import Projets       from '../../components/Projets/Projets';
import Parametres    from '../../components/Parametres/Parametres';
import Exports       from '../../components/Exports/Exports';

/* Services */
import { auth } from '../../services/FirebaseCloudService';
import { db   } from '../../services/FirebaseCloudService';

class MainPage extends Component {
_isMounted = false;

constructor(props) {
	super(props);
	this.state = {
			draw             : 'progression',
			languageSelected : '',
			user             : {},
			role             : {},
			projet           : '',
			produits         : {}, // Need to be an object, mystery...
			declinaisons     : {},
			attributs        : [],
			caracteristiques : [],
			categories       : [],
			langues          : [],
			openAlert        : false,
			titleAlert       : '',
			selectedProject  : null,
			isDisabled       : false,

			attributsRef  : null,
			caracRef		  : null,
			categoriesRef : null,
			produitsRef	  : null,
			declinRef	  : null
		}
		this._getDatas = this._getDatas.bind(this)
		this._changeProject = this._changeProject.bind(this);

		// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
		let vh = window.innerHeight * 0.01;
		// Then we set the value in the --vh custom property to the root of the document
		document.documentElement.style.setProperty('--vh', `${vh}px`);
	}
	componentDidMount(){
		this._isMounted = true;
		this._getDatas()

		// We listen to the resize event
		window.addEventListener('resize', () => {
			// We execute the same script as before
			let vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);
		});
	}
	componentDidUpdate(prevProps, prevState){
		if(this.state.langues !== prevState.langues){
			this.setState({languageSelected : this.state.langues[0].nom});
		}
	}
	componentWillUnmount(){
		this._isMounted = false;
		// localStorage.setItem('appState', JSON.stringify(this.state));
	}
	
	/* Get the data from Firebase */
	_getDatas(){
		let that = this;
		let user = {};
		this.setState({isDisabled:true});

		auth.onAuthStateChanged(firebaseUser => {
			if(firebaseUser){
			//Get datas from firebase

				db.collection('utilisateurs').doc(firebaseUser.email).get().then((doc) => {
					if(this._isMounted) {
						let userFirebase = doc.data();
						user.email    = userFirebase.email;
						user.nom      = userFirebase.nom;
						user.prenom   = userFirebase.prenom;
						user.username = userFirebase.username;
						this.setState({user : user});
						// console.log('user',this.state.user);

						//Get role
						if(userFirebase.roleRef){
							userFirebase.roleRef.get().then(res =>{
								this.setState({ role : res.data() });
								// console.log('role', this.state.role)
							}).catch(err => console.error(err));
						}

						// Get projects list
						if(userFirebase.projetsUserRef){
							userFirebase.projetsUserRef.get().then(prjUser =>{
								
								let arrayProjects = prjUser.data().projets;
								arrayProjects.sort((a, b) => (a.nom.toLowerCase() > b.nom.toLowerCase()) ? 1 : -1)

								// get the selected project in local storage
								let selectProject = localStorage.getItem('selectedProject');
								if(selectProject !== null || selectProject !== undefined){
									if(selectProject > arrayProjects.length-1) selectProject = this.state.selectedProject;
									this.setState({selectedProject : selectProject});
								}
								if(selectProject === null) this.setState({draw: 'projets'});

								if(selectProject !== null && arrayProjects[selectProject].hasOwnProperty('projetRef')){
									arrayProjects[selectProject].projetRef.get().then(prj => {
										let prjWithRef = prj.data();
										let prjData = {};
										prjData = prjWithRef.nom;

										// Get langues
										if(prjWithRef.languesRef){
											prjWithRef.languesRef.get().then(res => {
												let lang = Object.values(res.data());
												this.setState({langues:lang, languageSelected:lang[0].nom})
											});
										}

										// Get attributs
										if(prjWithRef.attributsRef){
											let attrRef = prjWithRef.attributsRef;
											this.setState({ attributsRef : attrRef });
										}

										// Get caracteristiques
										if(prjWithRef.caracRef){
											let caracRef = prjWithRef.caracRef;
											this.setState({ caracRef : caracRef });
										}
						
										// Get categories
										if(prjWithRef.categoriesRef){
											let categoriesRef = prjWithRef.categoriesRef;
											this.setState({ categoriesRef : categoriesRef });
										}
						
										// Get produits
										if(prjWithRef.produitsRef){
											let produitsRef = prjWithRef.produitsRef;
											this.setState({ produitsRef : produitsRef });
										}

										// Get declinaisons
										if(prjWithRef.declinRef){
											let declinRef = prjWithRef.declinRef;
											this.setState({ declinRef : declinRef });
										}
						
										// Projet
										this.setState({ projet : prjData});
										// console.log('projets :',prjData);
									}).catch(err => console.error(err));
								}
								setTimeout(function(){ that.setState({isDisabled : false}); }, 1000);
							});
						}
					}
				}).catch(err => { console.error(err) });
			}else{
				console.log('Non loggé');
			}
		});
	}

	/* Select the page to display */
	_selectDraw = (title) => {
		this.setState({draw: title});
	}

	/* Changing project */
	_changeProject(id){
		localStorage.setItem('selectedProject', id);
		// this.setState({selectedProject : id, draw: 'progression'});
		this.setState({selectedProject : id});
		this._getDatas();
	}

	_selectLang = (language) => {
		this.setState({languageSelected : language})
	}

	render(){
		let {languageSelected, user, draw , role ,projet ,langues, selectedProject,attributsRef, caracRef, categoriesRef, produitsRef, declinRef} = this.state;

		return(
			<div className="main-container">
				<Drawer onSelectDraw={this._selectDraw} role={role} langues={langues} draw={draw} isDisabled={this.state.isDisabled} selectedProject={selectedProject} onSelectLang={this._selectLang} />
				{draw === "projets"     && (<Projets       role={role} projet={projet} user={user} onChangeProject={this._changeProject} />)}
				{draw === "progression" && (<Progression   role={role} projet={projet} langues={langues} produitsRef={produitsRef}  declinRef={declinRef} attributsRef={attributsRef} categoriesRef={categoriesRef} caracRef={caracRef} />)}
				{draw === "produits"    && (<Produits      role={role} projet={projet} langues={langues} languageSelected={languageSelected} produitsRef={produitsRef} declinRef={declinRef} attributsRef={attributsRef} caracRef={caracRef} categoriesRef={categoriesRef} />)}
				{draw === "param"       && (<Parametres    role={role} langues={langues} languageSelected={languageSelected} attributsRef={attributsRef} caracRef={caracRef} categoriesRef={categoriesRef} />)}
				{draw === "config"      && (<Configuration role={role} />)}
				{draw === "exports"     && (<Exports       langues={langues} languageSelected={languageSelected}  produitsRef={produitsRef} declinRef={declinRef} attributsRef={attributsRef} caracRef={caracRef} categoriesRef={categoriesRef} />)}
			</div>
		);
	}
}

export default MainPage;

