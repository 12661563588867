	/** Natives **/
	import React, { Component } from "react";
	/** Style **/
	import "./Produits.scss";
	import colors from '../../assets/Colors.scss';
	/* Plugins */
	import { Row, Col } from 'react-flexbox-grid';
	/* Components */
	import LineItem     from '../LineItem/LineItem';
	import SlidableMenu from '../SlidableMenu/SlidableMenu';
	import AlertMessage from '../../components/AlertMessage/AlertMessage';
	import Pagination   from '../Pagination/Pagination';
	/** Services **/
	import StringService   from '../../services/StringService';
	import DropdownService from '../../services/DropdownService';
	import DataService 	   from '../../services/DataService';
	//Class
	const stringService   = new StringService();
	const dropdownService = new DropdownService();
	const dataService 	  = new DataService();

	class Produits extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			showFiltres       : false,
			showMenu          : false,
			categorieSelected : {},
			catToRemoveArray  : [],
			attributSelected  : 'tous',
			complete          : 'tous',
			produits          : {},
			creationDate      : '',
			majDate           : '',
			data              : {},
			title             : '',

			// Displaying products
			filteredPrincipProduits : [],
			filteredDeclinProduits  : [],
			query : '',

			// Filters
			showDropdownLangue   : false,
			showDropdownCat      : false,
			showDropdownAttr     : false,
			showDropdownComplete : false,

			// Draw
			principauxSelected   : true,
			declinaisonsSelected : false,
			typeProd             : 0,
			style_selected       : {"background": colors.blue,     "color": colors.white },
			style_unselected     : {"background": colors.darkgrey, "color": colors.white },

			// Alert message
			openAlert    : false,
			isChoice     : false,
			titleAlert   : '',
			dataToDelete : '',
			typeToDelete : '',

			// Dropdowns
			dropDownList : ["dropLang","dropCat","dropAttr","dropComplete"],

			// Pagination
			pageOfItems : [],
			pageProd  : 1,
			pageDecli : 1,

			attributsArray      : [],
			caracteristicsArray : [],
			categoriesArray	  : [],
			produitsArray		  : [],
			declinArray			  : [],

			categoriesArraySelected   : [],

			isLoading : true
		}
	}
	componentDidMount(){
		let {categorieSelected} = this.state;
		this._loadData(this.props.languageSelected);
		categorieSelected = {nom:'tous'};

		this.setState({ categorieSelected, });
	}

	componentDidUpdate(prevProps, prevState){
		let {query} = this.state;
		if (prevProps.languageSelected !== this.props.languageSelected || prevState.query !== query) {
			this._loadData(this.props.languageSelected)
			this.setState({query});
		}
	}
	
	/* Load all data from Firestore */
	_loadData(language){
		let that = this;
		let {query} = this.state;

		dataService.loadSubCollectionFromFirebase(language, that.props.categoriesRef).then((cat) => {
			dataService.loadSubCollectionFromFirebase(language, that.props.caracRef).then((caracs) => {
				dataService.loadSubCollectionFromFirebase(language, that.props.attributsRef).then((attr) =>{
					dataService.loadSubCollectionFromFirebase(language, that.props.produitsRef).then((prod) => {
						dataService.loadSubCollectionFromFirebase(language, that.props.declinRef).then((declin) => {
							let filteredPrincipProduits = [];
							let filteredDeclinProduits  = [];
							if(prod.length   > 0) filteredPrincipProduits = prod.filter(p => p.reference.toLowerCase().includes(query.toLowerCase()) || p.nom.toLowerCase().includes(query.toLowerCase()));
							if(declin.length > 0) filteredDeclinProduits  = declin.filter(p => p.reference.toLowerCase().includes(query.toLowerCase()));

							filteredPrincipProduits.sort((a, b) => (a.nom > b.nom) ? 1 : -1);
							filteredDeclinProduits.sort((a, b) => (a.reference > b.reference) ? 1 : -1);

							that.setState({attributsArray:attr, caracteristicsArray:caracs, categoriesArray:cat, produitsArray:prod, declinArray:declin, filteredPrincipProduits, filteredDeclinProduits, isLoading:false});
						});
					});
				});
			});
		});
	}

	/* Open the filtres panel */
	_openFiltres = () => {
		this.setState({
			showFiltres : !this.state.showFiltres
		});
	}

	/* Search functionality */
	_handleInputChange = event => {
		const query = event.target.value;
		this.setState({query});
	};

	/* Open the menu in the right */
	_openMenu = () => {
		this.setState({
			showMenu : !this.state.showMenu,
			edit     : false,
			title    : 'Créer un produit'
		});
	}

	/* Edit an attribute */
	_editMenu = (data, prodType) => {
		this.setState({
			data     : data,
			edit     : true,
			showMenu : !this.state.showMenu,
			title    : 'Editer un produit',
			typeProd : prodType
		})
	}

	/* Select the category */
	_selectCategorie = (categorie) => { 
		let {categorieSelected, categoriesArraySelected} = this.state;

		if(!categoriesArraySelected) categoriesArraySelected = [];
		if(!categoriesArraySelected.includes(categorie) && categorie.nom !== 'tous') categoriesArraySelected.push(categorie);
		if(categorie.nom === 'tous') categoriesArraySelected = [];

		categorieSelected = categorie;
		
		this.setState({categoriesArraySelected, categorieSelected});

		dropdownService.closeDropDown("dropCat");
	}

	/* Remove subCategores of a selected category */
	_removeSubCategories(targetId){
		let {categoriesArray, catToRemoveArray} = this.state;
		let that = this;  

		for(let catItem of categoriesArray){
			if (catItem.parentID === targetId) {
				that._removeSubCategories(catItem.id)
			}
		}
		let catToRemove = categoriesArray.find(currentCat => currentCat.id === targetId)
		catToRemoveArray.push(catToRemove)
		this.setState({catToRemoveArray});
	} 

	/* Remove a language */
	_removeCategory(item){
		let {categoriesArraySelected, catToRemoveArray, categorieSelected} = this.state;
		let that = this;

		that._removeSubCategories(item.id);

		for(let i=0; i<catToRemoveArray.length; i++){
			let index = categoriesArraySelected.findIndex(currentCat => currentCat.id === catToRemoveArray[i].id)
			if(index!== -1) categoriesArraySelected.splice(index,1);
		}
		if(categoriesArraySelected.length !== 0) categorieSelected = {nom: categoriesArraySelected[categoriesArraySelected.length-1].nom};
		else categorieSelected = {nom:'tous'};
		this.setState({catToRemoveArray : [], categoriesArraySelected, categorieSelected});
	}

	_selectAttribut  = (attribut)  => { 
		this.setState({attributSelected : attribut});   
		dropdownService.closeDropDown("dropAttr");
	}
	_selectComplete  = (complete)  => { 
		this.setState({complete : complete}); 
		dropdownService.closeDropDown("dropComplete");
	}

	/* Display the label of the completed value */
	_getCompleteLabel(bool){
		if(typeof bool === "boolean"){
			if(bool === true) return 'Oui'
			else return 'Non'
		}else return stringService.uppercaseFirstLetter(bool);
	}
	
	/* Get the total number of products displayed */
	_getNbrProducts(){
		let filtered = [];

		if(this.state.principauxSelected) filtered = this.state.filteredPrincipProduits;
		else filtered = this.state.filteredDeclinProduits

		return filtered.length +' résultat(s)';
	}

	/*-----------------------------------------------------*/
	/*----------------------- Alert -----------------------*/
	/*-----------------------------------------------------*/

	/* Open the alert message */
	_openAlert = (isChoice, title, type, dataToDelete) => {
		this.setState({isChoice: isChoice, openAlert: !this.state.openAlert, titleAlert: title, typeToDelete: type, dataToDelete: dataToDelete});
	}

	/* Remove produit */ 
	_closeAlert = async (choice) => {
		let {dataToDelete, typeToDelete} = this.state;
		let languageSelected = this.props.languageSelected;

		let that = this;

		if(choice === true){
			if(typeToDelete === 0){
				// loop removing declinaison
				that.props.declinRef.collection(languageSelected).where("idProd", "==", dataToDelete.id).get().then(function(querySnapshot){
					querySnapshot.forEach(function(doc) {
						that.props.declinRef.collection(languageSelected).doc(doc.id).delete().catch(function(error) { console.error("Error removing product: ", error); });
					});
				})
				
				that.props.produitsRef.collection(languageSelected).doc(dataToDelete.idFirebase).delete().then(function(){    
					that._loadData(languageSelected) 
					that.setState({openAlert: !that.state.openAlert});
				}).catch(function(error) { console.error("Error removing product: ", error); });
		
			}else{
				that.props.declinRef.collection(languageSelected).doc(dataToDelete.idFirebase).delete().then(function(){   
					that._loadData(languageSelected) 
					that.setState({openAlert: !that.state.openAlert});
				}).catch(function(error) { console.error("Error removing product: ", error); });
			}
		}else that.setState({openAlert: !that.state.openAlert});
	}

	/*-----------------------------------------------------*/
	/*----------------------- Draw ------------------------*/
	/*-----------------------------------------------------*/

	/* Select the state of the draw */
	_selectProductDraw = (choice) => {
		if      (choice === 0) this.setState({ principauxSelected : true,  declinaisonsSelected : false, showMenu : false }) 
		else if (choice === 1) this.setState({ principauxSelected : false, declinaisonsSelected : true,  showMenu : false })
	}

	/* Select the color of the draw */
	_getColorsDraw(category){
		if(category === true) return this.state.style_selected;
		else return this.state.style_unselected;
	}

	/*-------------------------------------------------*/
	/*------------------ Pagination -------------------*/
	/*-------------------------------------------------*/

	// Update state with new page of items
	_onChangePageProd  = (pageOfItems,page) => this.setState({ pageOfItems:pageOfItems, pageProd:page }); 

	// Update state with new page of items
	_onChangePageDecli = (pageOfItems,page) => this.setState({ pageOfItems:pageOfItems, pageDecli:page }); 

	/*-----------------------------------------------------*/
	/*--------------------- Render ------------------------*/
	/*-----------------------------------------------------*/

	render(){
		let {filteredPrincipProduits, filteredDeclinProduits, categorieSelected, attributSelected, complete, creationDate, majDate, dropDownList, pageProd, pageDecli,
			attributsArray, caracteristicsArray, categoriesArray, categoriesArraySelected
		} = this.state;
		let here = this;
		let languageSelected = this.props.languageSelected;

		return(
			<div className="produits-content">
				{this.state.showFiltres &&(
					<div className="produits-panel">
						<div className="produits-panel-filtres" onClick={() => this._openFiltres()}>
							<i className="icons-font filtres icon-chevron-left"></i>
							<div className="produits-panel-filtres-text"> Filtres</div>
						</div>

						<div className="produits-panel-body">
						
						{/* Categories */}
						{this.state.principauxSelected && (
							<div>
								<div className="produits-panel-dropdown">
									<div className="produits-panel-dropdown-label">Catégories et sous-catégories</div>
									<div className="dropdown">
										<button className="dropbtn medium" onClick={() => dropdownService.openDropDown('dropCat',JSON.parse(JSON.stringify(dropDownList)))}><div className="dropdown-name">{stringService.uppercaseFirstLetter(categorieSelected.nom)}</div><i className="icons-font arrow icon-chevron-down"></i></button>
										<div className="dropdown-content medium" id="dropCat">
										{categoriesArray.map((data, index) => {
											if(categoriesArraySelected && categoriesArraySelected.length !== 0 && categoriesArraySelected[categoriesArraySelected.length-1].id === data.parentID){
												return <div key={index} onClick={() => here._selectCategorie(data)}>{stringService.uppercaseFirstLetter(data.nom)}</div>
											}else if(categoriesArraySelected && categoriesArraySelected.length === 0 && data.parentID === 0){
												return <div key={index} onClick={() => here._selectCategorie(data)}>{stringService.uppercaseFirstLetter(data.nom)}</div>
											}else return null;
										})}
										</div>
									</div>
								</div>

							{/* Categories selected */}
								<Row className="selected-items-block">
									{categoriesArraySelected && (
										categoriesArraySelected.map((data, index) => {
											return (
												<div className="selected-items-item" key={index}>
													<div>{stringService.uppercaseFirstLetter(data.nom)}</div>
													<i className="icons-font selectable icon-close" onClick={() => this._removeCategory(data)}></i>
												</div>
											);
										})
									)}
								</Row>
							</div>
						)}

						{/* Attributs */}
						{this.state.declinaisonsSelected && (
							<div className="produits-panel-dropdown">
								<div className="produits-panel-dropdown-label">Attribut</div>
								<div className="dropdown">
									<button className="dropbtn medium" onClick={() => dropdownService.openDropDown('dropAttr',JSON.parse(JSON.stringify(dropDownList)))}><div className="dropdown-name">{stringService.uppercaseFirstLetter(attributSelected)}</div><i className="icons-font arrow icon-chevron-down"></i></button>
									<div className="dropdown-content medium" id="dropAttr">
										<div onClick={() => here._selectAttribut('tous')}>{stringService.uppercaseFirstLetter('tous')}</div>
										{attributsArray.map((data, index) => {
											return (
												<div key={index} onClick={() => here._selectAttribut(data.nom)}>{stringService.uppercaseFirstLetter(data.nom)}</div>
											);
										})}
									</div>
								</div>
							</div>
						)}

						{/* Complété */}
						<div className="produits-panel-dropdown">
							<div className="produits-panel-dropdown-label">Complété</div>
							<div className="dropdown">
								<button className="dropbtn medium" onClick={() => dropdownService.openDropDown('dropComplete',JSON.parse(JSON.stringify(dropDownList)))}><div className="dropdown-name">{here._getCompleteLabel(this.state.complete)}</div><i className="icons-font arrow icon-chevron-down"></i></button>
								<div className="dropdown-content medium" id="dropComplete">
								<div onClick={() => this._selectComplete('tous')}>Tous</div>
								<div onClick={() => this._selectComplete(false)} >Non</div>
								<div onClick={() => this._selectComplete(true)}  >Oui</div>
								</div>
							</div>
						</div>

						{/* Crée le */}
						<div className="produits-panel-dropdown">
							<div className="produits-panel-dropdown-label">Crée le</div>
							<input type="date" name="creele" id="creele" onChange={(event) => this.setState({creationDate: stringService.convertDateENToFR(event.target.value)})}></input>
						</div>

						{/* Maj le */}
						<div className="produits-panel-dropdown">
							<div className="produits-panel-dropdown-label">Maj le</div>
							<input type="date" name="majle" id="majle" onChange={(event) => this.setState({majDate: stringService.convertDateENToFR(event.target.value)})}></input>
						</div>
					</div>
				</div>
			)}

			<div className="produits-container">
				<div className="produits-top-container">
				{/* Title of the page */}
					<div className="produits-top">
						<div className="produits-top-row">
							<div className="produits-top-row-title">Liste des produits</div>
							{filteredPrincipProduits &&(<div className="produits-top-row-results">{here._getNbrProducts()}</div>)}
						</div>
						{/* 'Filtres' button */}
						<div className="produits-top-filtres" onClick={() => this._openFiltres()}>
							<div className="produits-top-filtres-text"> Filtres </div>
							<i className="icons-font filtres icon-chevron-right"></i>
						</div>
					</div>

				{/* Input buttons */}
					<div className="produits-buttons">
						<div className="searchbar">
							<input className="searchbar-text" type="text" placeholder="Rechercher..." value={this.state.query} onChange={this._handleInputChange}></input>
							<div className="searchbar-icon"><i className="icons-font search icon-search"></i></div>
						</div>
						<button className="button-blue" onClick={() => this._openMenu()}><i className="icons-font icon-cube"></i> Créer un produit</button>
					</div>
				</div>

			{/* Switch buttons */}
				<div className="produits-switchButtons">
					<div className="produits-switchButtons-switch left"  onClick={() => this._selectProductDraw(0)} style={this._getColorsDraw(this.state.principauxSelected)}>Produits principaux</div>
					<div className="produits-switchButtons-switch right" onClick={() => this._selectProductDraw(1)} style={this._getColorsDraw(this.state.declinaisonsSelected)}>Déclinaisons</div>
				</div>

			{/* PROD. PRINCIPAUX */}
				{this.state.principauxSelected && (
					<div style={{height:'100%'}}>
						{/* Header table */}
						<Row className="table-header removable">
							<Col className="subtitle" xs={3}>Nom du produit</Col>
							<Col className="subtitle" xs={3}>Référence</Col>
							<Col className="subtitle" xs={2}>Complété</Col>
							<Col className="subtitle" xs={2}>Crée le</Col>
							<Col className="subtitle" xs={2}>Màj le</Col>
						</Row> 
						<div className="line"></div>
			
						<div className="table-items produits">
							{this.state.pageOfItems.map((prod, indexProd) => {
								if(complete === 'tous' || complete === prod.complete){
									if(creationDate === "" || creationDate === prod.creation_date){
										if(majDate === "" || majDate === prod.maj_date){
											if((prod.categories !== undefined) && (categorieSelected.nom === 'tous' || prod.categories.find(cat => cat.nom.toLowerCase() === categorieSelected.nom.toLowerCase()))){
												return(
													<div key={indexProd}>
														<LineItem key={indexProd} type="produits" prodType={0} data={prod} onEdit={here._editMenu} onOpenAlert={here._openAlert} /> 
													</div>
												);
											}else return null;
										}else return null;
									}else return null;
								}else return null;
							})}

							{/* Pagination */}
							<Pagination items={filteredPrincipProduits} initialPage={pageProd} onChangePage={this._onChangePageProd} />
						</div>
					</div>
				)}
			
			{/* DECLINAISONS */}
				{this.state.declinaisonsSelected && (
					<div style={{height:'100%'}}>
						{/* Header table */}
						<Row className="table-header removable">
							<Col className="subtitle" xs={4}>Référence</Col>
							<Col className="subtitle" xs={2}>Complété</Col>
							<Col className="subtitle" xs={3}>Crée le</Col>
							<Col className="subtitle" xs={3}>Màj le</Col>
						</Row> 
						<div className="line"></div>

						{/* Items list */}
						<div className="table-items produits">
							{this.state.pageOfItems.map((prod, indexProd) => {
								if((prod.complete !== undefined) && (complete === 'tous' || complete === prod.complete)){
									if((prod.creation_date !== undefined) && (creationDate === "" || creationDate === prod.creation_date)){
										if((prod.maj_date !== undefined) && (majDate === "" || majDate === prod.maj_date)){
											if((prod.attributs !== undefined) && (attributSelected === "tous" || prod.attributs.find(attr => attr.nom.toLowerCase() === attributSelected.toLowerCase()))){
												return(
													<div key={indexProd}>
														<LineItem key={indexProd} type="produits" prodType={1} data={prod} onEdit={here._editMenu} onOpenAlert={here._openAlert} /> 
													</div>
												);
											}else return null
										}else return null
									}else return null
								}else return null
							})}

							{/* Pagination */}
							<Pagination items={filteredDeclinProduits} initialPage={pageDecli} onChangePage={this._onChangePageDecli} />
						</div>
					</div>
				)}

			</div>
			{this.state.showMenu && (
				<SlidableMenu 
				   title				  = {this.state.title} 
					type				  = "produit" 
					edit				  = {here.state.edit} 
					data				  = {this.state.data} 
					typeProd			  = {this.state.typeProd} 
					role				  = {this.props.role} 
					onClose			  = {this._openMenu} 
					onReload			  = {() => this._loadData(languageSelected)} 
					languageSelected = {languageSelected} 
					attributs		  = {attributsArray} 
					caracteristiques = {caracteristicsArray} 
					categories		  = {categoriesArray} 
					produitsRef		  = {this.props.produitsRef} 
					declinRef 		  = {this.props.declinRef}
				/>)}
			{this.state.openAlert && (<AlertMessage title={this.state.titleAlert} isChoice={this.state.isChoice} onAlertChoice={this._closeAlert}></AlertMessage>)}
			</div>
		);
	}
}

export default Produits;

