/** Natives **/
import React, { Component } from "react";
/** Style **/
import "./SlidableMenu_Caracteristiques.scss";

class SlidableMenu_Caracteristiques extends Component {
    constructor(props) {
     super(props);
        this.state = {
            nom          : '',
            type         : '',
            optionsArray : [],
            errorMessage : '',
            alreadyExist : false
        }
    }
    componentDidMount(){ 
        this._addOptionsInputArray(); 
        
        if(this.props.edit === true){ 
            let optionsArray = [];

            for(let i=0; i<this.props.data.options.length; i++){
                optionsArray.push('');
                optionsArray[i] = this.props.data.options[i];
            }

            this.setState({ 
                nom : this.props.data.nom,
                type : this.props.data.type, 
                optionsArray : optionsArray
            })
        }
    }

    /* Nom */
    _selectNom  = (nom)  => this.setState({nom  : nom.target.value});
    _selectOption(i, option) {
        let optionsArray = this.state.optionsArray;
        optionsArray[i]  = option.target.value;
        this.setState({optionsArray : optionsArray})
    }

    /* Add option */
    async _addOptionsInputArray(){
        let optionsArrayTemp = this.state.optionsArray;
        optionsArrayTemp[optionsArrayTemp.length] = '';
        this.setState({optionsArray : optionsArrayTemp});
    }

    /* Remove an option input */
    _removeItem(index){
        let optionsArrayTemp = this.state.optionsArray;
        if (index > -1) optionsArrayTemp.splice(index,1);
        this.setState({ optionsArray : optionsArrayTemp });
    }

    /* Close the menu and call the callback from the parent */
    _closePanel(){
        this.props.onClose();
        this.props.onReload();
    }

    /* Press enter to add category */
	async _handleEnterKeyPressed(e,index){
        let {optionsArray} = this.state;
		if(e.keyCode === 13){
            if(index === optionsArray.length -1){
                await this._addOptionsInputArray();
                this[`textInput${this.state.optionsArray.length-1}`].focus();
            }
		}
	}

  /*-------------------------------------------------*/
  /*-------------------- Firebase -------------------*/
  /*-------------------------------------------------*/

    /* Save data to Firebase */
    async _sendToFirebase(){
        let {errorMessage} = this.state;
        let that = this;

        if(this.state.nom !== ''){
            if(this.state.optionsArray.length !== 0){
                let highiestIdFound = -1;
                await this.props.caracRef.collection(this.props.langueSelected).get().then(function(snap) {
                    snap.forEach(function(doc) {
                        if(doc.data().id > highiestIdFound) highiestIdFound = doc.data().id;
                    });
                });

                // If carac already exist
                if(this.props.edit === true){
                    this.props.caracRef.collection(this.props.langueSelected).where("id", "==", this.props.data.id).get().then(function(querySnapshot) {
                        querySnapshot.forEach(function(doc) {
                            if(doc){
                                // edit 
                                that.props.caracRef.collection(that.props.langueSelected).doc(doc.id).set({
                                    id      : doc.data().id,
                                    nom     : that.state.nom,
                                    options : that.state.optionsArray,
                                }).then((_) => { 
                                    that.setState({caracAdded : true}); 
                                    that.props.onReload();
                                });
                            }
                        });
                    });
                }else{
                    this.props.caracRef.collection(this.props.langueSelected).add({
                        id      : highiestIdFound+1,
                        nom     : this.state.nom,
                        options : this.state.optionsArray,
                    }).then((_) => { 
                        that.props.onReload();
                        that.setState({caracAdded : true}); 
                    });
                }

            }else errorMessage = "Veuillez entrer une ou plusieurs options.";
        }else errorMessage = "Veuillez entrer un nom.";
        this.setState({errorMessage});
    }

    render() {
        return (
            <div>
                {!this.state.caracAdded && (
                    <div className="slidableMenu-content-block">
                        {/* Nom */}
                        <div className="slidableMenu-content-block-label">Nom</div>
                        <input className="slidableMenu-content-block-input-short" type="text" value={this.state.nom}  onChange={this._selectNom}></input>
                        {/* Options */}
                        <div className="slidableMenu-content-block-label">Options</div>
                        <div className="slidableMenu-content-block-flex">
                            <div className="dropdown-dynamic">
                                {this.state.optionsArray.map((opt, indexOpt) => {
                                    return(
                                        <div key={indexOpt} className="slidableMenu-content-block-flex-block">
                                            <div><input className="slidableMenu-content-block-flex-block-input" type="text" value={opt} ref={input => { this[`textInput${indexOpt}`] = input }} onChange={e => this._selectOption(indexOpt, e)} onKeyDown={(e) => this._handleEnterKeyPressed(e, indexOpt)}></input></div>
                                            <i className="icons-font option icon-trash" onClick={() => this._removeItem(indexOpt)}></i>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <i className="icons-font add icon-plus-circle" onClick={() => this._addOptionsInputArray()}></i>
                        {this.state.errorMessage && (<div className="slidableMenu-content-block-label center">{this.state.errorMessage}</div>)}
                        {/* Buttons */}
                        <div className="slidableMenu-content-block-buttons">
                            <input className="slidableMenu-content-block-buttons-button white" type="button" value="Enregistrer" onClick={() => this._sendToFirebase()}></input>
                            <input className="slidableMenu-content-block-buttons-button dark"  type="button" value="Annuler"     onClick={this.props.onClose}></input>
                        </div>
                    </div>
                )}
                {this.state.caracAdded && (
                    <div className="slidableMenu-content-block">
                        <div className="slidableMenu-content-block-label center">La caractéristique a été ajoutée avec succès !</div>
                        <div className="slidableMenu-content-block-buttons">
                            <input className="slidableMenu-content-block-buttons-button white" type="button" value="Fermer" onClick={() => this._closePanel()}></input>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default SlidableMenu_Caracteristiques;

