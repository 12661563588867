/** Natives **/
import React, { Component } from "react";
/** Style **/
import "./LoginPage.scss";
/* Images */
import logoTiz  from '../../assets/logo-tiz.png';
/* Plugins */
import { browserHistory } from 'react-router';
import { auth } from '../../services/FirebaseCloudService';

class LoginPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			inputLogin    : '',
			inputPassword : '',
			errorMessage  : ''
		};
		this._handleChangeLogin    = this._handleChangeLogin.bind(this);
		this._handleChangePassword = this._handleChangePassword.bind(this);
		this._onConnect            = this._onConnect.bind(this);
	}
	
	/* Get value login */
	_handleChangeLogin(event) {
		this.setState({inputLogin: event.target.value});
	}

	/* Get value Password */
	_handleChangePassword(event) {
		this.setState({inputPassword: event.target.value});
	}

	/* Submit values */
	_onConnect(event) {
		if(this.state.inputLogin && this.state.inputPassword){
			auth.signInWithEmailAndPassword(this.state.inputLogin, this.state.inputPassword).then(()=>{
				browserHistory.push("/main");
			}).catch((e)=>{
				this.setState({errorMessage: "Identifiant ou mot de passe incorrect."})
			});
		}else this.setState({errorMessage: "Identifiant ou mot de passe incorrect."})
		event.preventDefault();
	}

	render(){
		return (
			<div className="login-container">
				<form className="panel-row" onSubmit={this._onConnect}>
				{/* Logo */}
					<div className="panel-logo">
						<img src={logoTiz} className="panel-logo" alt="Agence Tiz" />
					</div>
				{/* Title */}
					<div className="panel-title">
						<h4 className="panel-title-text">Veuillez entrer vos identifiants</h4>
					</div>
				{/* Inputs */}
					<div className="panel-inputs">
						<div className="panel-input">
							<h5 className="panel-input-text">Login :</h5>
							<input value={this.state.inputLogin} onChange={this._handleChangeLogin} id="txtLogin" type="text" className="panel-input-input"></input>
						</div>
						<div className="panel-input">
							<h5 className="panel-input-text">Mot de passe :</h5>
							<input value={this.state.inputPassword} onChange={this._handleChangePassword} id="txtPassword" type="password" className="panel-input-input" ></input>
						</div>
					</div>
				{/* Error message */}
					{this.state.errorMessage !== '' && (<div className="panel-errorMessage">{this.state.errorMessage}</div>)}
				{/* Input button connection */}
					<div className="panel-button">
						<input type="submit" value="Connexion" className="panel-button-button"></input>
					</div>
				</form>
			</div>
		);
	}
}

export default LoginPage;

