/** Natives **/
import React, { Component } from "react";
/** Style **/
import "./AlertMessage.scss";

class Parametres extends Component {

  /* When the user need to have a choice */
  _displayChoice(){
   return(
     <div>
        <div className="alertMessage-background"></div>
        <div className="alertMessage-content">
          <div className="alertMessage-title">{this.props.title}</div>
          <div className="alertMessage-block-buttons">
            <input className="alertMessage-block-buttons-button blue"  type="button" value="Oui" onClick={() => this.props.onAlertChoice(true)}></input>
            <input className="alertMessage-block-buttons-button white" type="button" value="Non" onClick={() => this.props.onAlertChoice(false)}></input>
          </div>
        </div>
     </div>
   )
  }

  /* Confirmation message */
  _displayConfirmation(){
    return (
      <div>
         <div className="alertMessage-background"></div>
        <div className="alertMessage-content">
          <div className="alertMessage-title">{this.props.title}</div>
          <div className="alertMessage-block-buttons">
            <input className="alertMessage-block-buttons-button blue" type="button" value="Fermer" onClick={() => this.props.onAlertChoice(false)}></input>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="alertMessage-body">
        {this.props.isChoice  &&(this._displayChoice())}
        {!this.props.isChoice &&(this._displayConfirmation())}
      </div>
    );
  }
}

export default Parametres;

