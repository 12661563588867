import firebase from 'firebase';

// Initialize Firebase
var config = {
    apiKey            : "AIzaSyDE_m9eJ-dlZN6NFWTErDmwEM95Q00nup4",
    authDomain        : "tiz199220-import-produits.firebaseapp.com",
    databaseURL       : "https://tiz199220-import-produits.firebaseio.com",
    projectId         : "tiz199220-import-produits",
    storageBucket     : "tiz199220-import-produits.appspot.com",
    messagingSenderId : "889641955297",
    appId             : "1:889641955297:web:cd68cc4793cde200"
}; 

const firebaseApp = firebase.initializeApp(config);
const auth = firebaseApp.auth();
const db = firebase.firestore();

export {db, auth}