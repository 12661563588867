/** Natives **/
import React, { Component } from "react";
/** Style **/
import "./Parametres-categories.scss";
/** Components **/
import AlertMessage from '../../components/AlertMessage/AlertMessage';

class ParametresCategories extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			newCat             : '',
			editCat            : '',
			displayNewCat      : false,
			parentName         : '',
			showNewSubCat      : -1,
			displayEditCat     : -1,
			categoriesToRemove : [],
		}
		this.newCatRef = React.createRef();
		this._handleEnterKeyPressed = this._handleEnterKeyPressed.bind(this);
	}
	
	/*-------------------------------------------------*/
	/*------------------ Displaying -------------------*/
	/*-------------------------------------------------*/

	/* Show the field to add a new category */
	_showInputCategory(){ 
		this.setState({displayNewCat : !this.state.displayNewCat});
		if(this.newCatRef.current){
			this.newCatRef.current.scrollIntoView({ 
				behavior : "smooth", 
				block		: "end",
				inline	: "end"
			})
		}
	}

	/* Show the field to add a new category */
	_showInputSubCategory(parentName){ 
		this.setState({displayNewCat : !this.state.displayNewCat, parentName:parentName});
	}

	/* Show the edit field categoru */
	_displayEditCategory(cat){
		this.setState({displayEditCat:cat.id, editCat:cat.nom})
	}

	/* Display the categories */
	_displaySubCategory(categoryList, cat, index){
		let that = this;
		return(
			<ul key={index} className="paramCat-categories"> 
				{this.state.displayEditCat !== cat.id &&(
					<li className="paramCat-category">
						<div className="paramCat-add-button">
							<i className="icons-font subCat icon-plus-circle" onClick={() => {this.setState({showNewSubCat : cat.id})}}></i>
							<div className="paramCat-category-title">
								<i className="icons-font icon-folder-open-o"></i>
								<div className="paramCat-category-title-text">{cat.nom}</div>
							</div>
						</div>
						<div className="paramCat-buttons">
							<i className="icons-font cat icon-pencil" onClick={() => that._displayEditCategory(cat)}></i> |
							<i className="icons-font cat icon-trash"  onClick={() => that._openAlert(true, "Êtes-vous sûr de vouloir supprimer l'élément et ses sous-éléments associés ?",cat)}></i>
						</div>
					</li>
				)}
				{this.state.displayEditCat === cat.id &&(
					<div className="paramCat-addItem">
						<div className="paramCat-addItem-head">
							<i className="icons-font icon-folder-open-o"></i>
							<input className="input-add subCategory" type="text" value={that.state.editCat} onChange={(e) => that._selectEditCategoryName(e)} onKeyDown={(e) => this._handleEnterKeyPressed(e, 'edit', cat.id)}></input>
						</div>
						<div className="paramCat-buttons">
							<i className="icons-font cat icon-plus-circle" onClick={() => that._editCategory(cat.id)}></i> |
							<i className="icons-font cat icon-close"       onClick={() => that.setState({displayEditCat:-1})}></i>
						</div>
					</div>
				)}

				{/* Subcategories */}
				{categoryList.map(function(sousCat, indexSousCat){
					if(sousCat.parentID === cat.id){
						return(
							that._displaySubCategory(categoryList, sousCat, indexSousCat)
						)
					}else return null
				})}

				{/* Field to add a subCategory */}
				{that._displayNewSubCategoryField(cat)}
			</ul>
		)
	}

	/* Display the field to add a new subCategory */
	_displayNewSubCategoryField(cat){
		let that = this;
		let catId = 0;
		if(cat !== undefined) catId = cat.id;
		if(that.state.showNewSubCat === catId){
			return(
				<li className="paramCat-addItem">
					<div className="paramCat-addItem-head subCategory">
						<i className="icons-font icon-folder-open-o"></i>
						<input className="input-add subCategory" type="text" value={that.state.newCat} onChange={that._selectNewCategory} onKeyDown={(e) => this._handleEnterKeyPressed(e, 'add', cat.id)}></input>
					</div>
					<div className="paramCat-buttons">
						<i className="icons-font cat icon-plus-circle" onClick={() => that._addCategory(cat.id)}></i>
					</div>
				</li>
			)
		}else return null
	}

	/*-------------------------------------------------*/
	/*--------------------- Select --------------------*/
	/*-------------------------------------------------*/

	/* Select a category */
	_selectNewCategory = (newCat) => this.setState({newCat : newCat.target.value});

	_selectEditCategoryName = (e) => {
		this.setState({editCat : e.target.value});
	}

	/* Press enter to add category */
	_handleEnterKeyPressed(e, type, index){
		if(e.keyCode === 13){
			if(type==='add'){
				this._addCategory(index)
			}else{
				this._editCategory(index)
			}
		}
	}

	/*-------------------------------------------------*/
	/*--------------------- Edit ----------------------*/
	/*-------------------------------------------------*/

	/* Edit a category name */
	_editCategory(id){
		let {editCat} = this.state;
		let that = this;

		that.props.categoriesRef.collection(this.props.langueSelected).where("id", "==", id).get().then(function(querySnapshot) {
			querySnapshot.forEach(function(doc) {
				if(doc){
					// edit 
					that.props.categoriesRef.collection(that.props.langueSelected).doc(doc.id).set({
						id       : doc.data().id,
						nom      : editCat,
						parentID : doc.data().parentID,
					}).then((_) => { 
						that.setState({displayEditCat : -1});
						that.props.onReload();
					});
				}
			});
		});
	}

	/*-------------------------------------------------*/
	/*--------------------- Adding --------------------*/
	/*-------------------------------------------------*/
	
	/* Add a category */
	_addCategory(parentID){
		let categories = this.props.categoriesArray;
		let that = this;

		if(this.state.newCat !== ""){
			let highiestIdCatFound = 0;
			for(var i = 0; i<categories.length; i++){
				if(categories[i].id > highiestIdCatFound) highiestIdCatFound = categories[i].id;
			}

			this.props.categoriesRef.collection(this.props.langueSelected).add({
				id       : highiestIdCatFound+1,
				nom      : this.state.newCat,
				parentID : parentID,
			}).then((_) => { 
				that.props.onReload();
				this.setState({ newCat: '', displayNewCat: false, showNewSubCat:-1 })
			});
		}
	}

	/*-------------------------------------------------*/
	/*--------------------- Remove --------------------*/
	/*-------------------------------------------------*/

	/* Push in array the category selected and its child to be deleted */
	_removeCategories(targetId) {
		let {categoriesToRemove} = this.state;
		let categories = this.props.categoriesArray;
		let that = this;  

		for(let catItem of categories){
			if(catItem.parentID === targetId) {
				that._removeCategories(catItem.id)
			}
		}
		let catToRemove = categories.find(currentCat => currentCat.id === targetId)
		categoriesToRemove.push(catToRemove)
		this.setState({categoriesToRemove});
	}

	/*-------------------------------------------------*/
	/*--------------------- Alert ---------------------*/
	/*-------------------------------------------------*/

	/* Open the alert message */
	_openAlert = (isChoice, title, catToDelete) => {
		this.setState({isChoice: isChoice, openAlert: !this.state.openAlert, titleAlert: title, catToDelete: catToDelete});
	}

	/* When closing the alert component */
	_closeAlert = async (choice) => {
		let {catToDelete, categoriesToRemove} = this.state;
		let that = this;

		if(choice === true){
			that._removeCategories(catToDelete.id); 

			for(let i=0; i<categoriesToRemove.length; i++){
				that.props.categoriesRef.collection(that.props.langueSelected).doc(categoriesToRemove[i].idFirebase).delete().then(function(){    
					that.props.onReload();
				}).catch(function(error) {
					console.error("Error removing document: ", error);
				});
			}
		}
		that.setState({categoriesToRemove : [], openAlert: !this.state.openAlert});
	}

	/*-------------------------------------------------*/
	/*--------------------- Render --------------------*/
	/*-------------------------------------------------*/

	render() {
		let that = this;
		let categories = this.props.categoriesArray;

		return(
			<div className="paramCat-container">
			<div className="paramCat-title">
				<div className="paramCat-title-text">Catégories</div>
			</div>

			{/* First category (Catalogue) */}
			<div className="paramCat-firstCat">
				<div className="paramCat-firstCat-title">Catalogue {this.props.langueSelected}</div>
				<button className="button-blue" onClick={() => this._showInputCategory()}><i className="icons-font icon-clipboard"></i> Ajouter une catégorie</button>
			</div>

			<div className="paramCat-content">
				{categories.map(function(catLang, indexFirst){
					return(
						<div key={indexFirst}>
							{/* Second category (Category) */}
							{catLang.parentID === 0 && (that._displaySubCategory(categories,catLang,indexFirst))}
						</div>
					);
				})}                                

				{/* Add a new category */}
				<div>
					{that.state.displayNewCat &&(
						<ul className="paramCat-categories" >
							<li className="paramCat-addItem" >
							<div className="paramCat-addItem-head category">
								<i className="icons-font icon-folder-open-o"></i>
								<input className="input-add subCategory" type="text" value={that.state.newCat} onChange={that._selectNewCategory} onKeyDown={(e) => this._handleEnterKeyPressed(e, 'add', 0)}></input>
							</div>
							<div className="paramCat-buttons" tabIndex="0">
								<i className="icons-font cat icon-plus-circle"  onClick={() => that._addCategory(0)}></i>
							</div>
							</li>
						</ul>
					)}
				</div>
			</div>
			<div ref={this.newCatRef} style={{'marginTop':'20px'}}></div>

			{this.state.openAlert && (<AlertMessage title={this.state.titleAlert} isChoice={this.state.isChoice} onAlertChoice={this._closeAlert}></AlertMessage>)}
			</div>
		);
	}
}

export default ParametresCategories;

