/** Natives **/
import React, { Component } from "react";
/** Style **/
import "./SlidableMenu_ConfigUser.scss";
/* Plugins */
import { Row } from 'react-flexbox-grid';
/* Services */
import { db, auth }    from '../../services/FirebaseCloudService';
import StringService   from '../../services/StringService';
import DropdownService from '../../services/DropdownService';
//Class
const stringService   = new StringService();
const dropdownService = new DropdownService();


class SlidableMenu_ConfigRole extends Component {
    constructor(props) {
     super(props);
        this.state = {
            nom         : '',
            prenom      : '',
            email       : '',
            password    : '',
            confirmedPassword : '',
            role        : '',
            rolesList   : [],
            codeProjet  : '',
            projetsList : [],

            projetArray : [],

            userAdded    : false,
            errorMessage : '',

            dropDownList : ["dropRole","dropPrj"],
        }
    }
    async componentDidMount() {
        this._getRoles();
        this._getProjets();
        
        if(this.props.edit === true){
            let projetArray = this.state.projetArray;

            await db.collection('projetsUsers').doc(this.props.data.email).get().then(async (snapshot) => {
                await snapshot.data().projets.forEach((prj) => { projetArray.push(prj.nom); });
                if(projetArray.length !== 0 ) this.setState({codeProjet : projetArray[projetArray.length-1]});
            });

            this.setState({
                nom    : this.props.data.nom,
                prenom : this.props.data.prenom,
                email  : this.props.data.email,
                role   : this.props.data.role,
                projetArray : projetArray
            });
        }
    }

    /* Get the list of roles */
    _getRoles(){
        let rolesList = [];
        db.collection('roles').get().then((snapshot) => {
            snapshot.docs.forEach((snap) => {
                rolesList.push({nom: snap.data().nom})
            });
            this.setState({rolesList : rolesList});
        });
    }

    /* Get the list of projects */
    _getProjets(){
        let projetsList = [];
        db.collection('projets').get().then((snapshot) => {
            snapshot.docs.forEach((snap) =>{
                projetsList.push({nom: snap.data().nom})
            })
            this.setState({projetsList : projetsList})
        })
    }
    
    _selectNom      = (nom)      => this.setState({nom        : nom.target.value});
    _selectPrenom   = (prenom)   => this.setState({prenom     : prenom.target.value});
    _selectEmail    = (email)    => this.setState({email      : email.target.value});
    _selectPassword = (password) => this.setState({password   : password.target.value});
    _selectConfirmedPassword = (confirmedPassword) => this.setState({confirmedPassword : confirmedPassword.target.value});
    _selectRole     = (role)     => {
        this.setState({role : role});
        dropdownService.closeDropDown("dropRole");
    } 
    _selectProjetArray(prj){
        let projetArrayTemp = this.state.projetArray;
        let codeProjetTemp  = this.state.codeProjet;

        if(!projetArrayTemp) projetArrayTemp = [];
        if(!projetArrayTemp.includes(prj)) projetArrayTemp.push(prj);

        codeProjetTemp = prj;
        
        this.setState({
            projetArray : projetArrayTemp,
            codeProjet  : codeProjetTemp
        });
        dropdownService.closeDropDown("dropPrj");
    }

    _closePanel(){
        this.props.onClose();
        this.props.onReload();
    }

    /* Remove an item from a multi-selection */
    _removeItem(item){
        let projetArrayTemp = this.state.projetArray;

        let index = projetArrayTemp.indexOf(item);
        if (index > -1) projetArrayTemp.splice(index,1);

        this.setState({projetArray : projetArrayTemp});
    }

  /*-------------------------------------------------*/
  /*-------------------- Firebase -------------------*/
  /*-------------------------------------------------*/

    /* Save user to Firebase */
    _checkFields(){
        let errorMessage = '';
        if(this.state.nom  !== ''){
            if(this.state.prenom !== ''){
                if(this.state.email !== ''){
                    if(this.state.role !== ''){
                        if(this.state.projetArray.length !== 0){
                            if(this.props.edit === true){
                                this._sendToFirebase();
                            }else{
                                if(this.state.password !== ''){
                                    if(this.state.confirmedPassword !== ''){
                                        if(this.state.confirmedPassword === this.state.password){
                                            this._sendToFirebase();
                                        }else errorMessage = "Les mots de passe ne sont pas identiques.";
                                    }else errorMessage = "Veuillez entrer le mot de passe.";
                                }else errorMessage = "Veuillez entrer un mot de passe.";
                            }
                        }else errorMessage = "Veuillez ajouter au moins un projet.";
                    }else errorMessage = "Veuillez sélectionner un rôle.";
                }else errorMessage = "Veuillez entrer un email.";
            }else errorMessage = "Veuillez entrer un prénom.";
        }else errorMessage = "Veuillez entrer un nom.";
        this.setState({errorMessage : errorMessage});
    }

    async _sendToFirebase(){
        let projets = [];
        this.state.projetArray.forEach(prj => {
            projets.push({nom: prj, projetRef: db.collection("projets").doc(prj)})
        });
        let projectsToSend = {projets: projets}

        if(!this.props.edit) await auth.createUserWithEmailAndPassword(this.state.email, this.state.password).catch((e)=>{ alert(e.message) });
        await db.collection("projetsUsers").doc(this.state.email).set(projectsToSend);
        await db.collection("utilisateurs").doc(this.state.email).set({
            prenom  : this.state.prenom,        
            nom     : this.state.nom,   
            email   : this.state.email,        
            roleRef : db.collection("roles").doc(this.state.role),
            projetsUserRef : db.collection("projetsUsers").doc(this.state.email)
        });
        this.setState({userAdded : true});
    }


    render() {
    let {dropDownList} = this.state;

        return (
            <div>
                {!this.state.userAdded && ( 
                    <div className="slidableMenu-content-block">
                        {/* Nom */}
                        <div className="slidableMenu-content-block-label">Nom</div>
                        <input className="slidableMenu-content-block-input-short" type="text"     value={this.state.nom}      onChange={this._selectNom}></input>
                        {/* Prénom */}
                        <div className="slidableMenu-content-block-label">Prénom</div>
                        <input className="slidableMenu-content-block-input-short" type="text"     value={this.state.prenom}   onChange={this._selectPrenom}></input>
                        {/* Email */}
                        <div className="slidableMenu-content-block-label">Email</div>
                        {this.props.edit  &&(<input className="slidableMenu-content-block-input-short" type="text"  value={this.state.email}  onChange={this._selectEmail}  autoComplete="false" disabled></input>)}
                        {!this.props.edit &&(<input className="slidableMenu-content-block-input-short" type="text"  value={this.state.email}  onChange={this._selectEmail}  autoComplete="false"></input>)}
                        {/* Mot de passe */}
                        <div className="slidableMenu-content-block-label">Mot de passe</div>
                        {this.props.edit  &&(<input className="slidableMenu-content-block-input-short" type="password" value={this.state.password} onChange={this._selectPassword} autoComplete="false" disabled></input>)}
                        {!this.props.edit &&(<input className="slidableMenu-content-block-input-short" type="password" value={this.state.password} onChange={this._selectPassword} autoComplete="false"></input>)}
                        {/* Confirm Mot de passe */}
                        <div className="slidableMenu-content-block-label">Confirmer le mot de passe</div>
                        {this.props.edit  &&(<input className="slidableMenu-content-block-input-short" type="password" value={this.state.confirmedPassword} onChange={this._selectConfirmedPassword} autoComplete="false" disabled></input>)}
                        {!this.props.edit &&(<input className="slidableMenu-content-block-input-short" type="password" value={this.state.confirmedPassword} onChange={this._selectConfirmedPassword} autoComplete="false"></input>)}
                        {/* Role */}
                        <div className="slidableMenu-content-block-label">Rôle</div>
                        <div className="dropdown">
                            <button className="dropbtn long" onClick={() => dropdownService.openDropDown('dropRole',JSON.parse(JSON.stringify(dropDownList)))}><div className="dropdown-name">{stringService.uppercaseFirstLetter(this.state.role)}</div><i className="icons-font arrow icon-chevron-down"></i></button>
                            <div className="dropdown-content long" id="dropRole">
                                {this.state.rolesList.map( (data, index) => {
                                    return <div key={index} onClick={() => this._selectRole(data.nom)}>{stringService.uppercaseFirstLetter(data.nom)}</div>
                                })}
                            </div>
                        </div>
                        {/* Projet */}
                        <div className="slidableMenu-content-block-label">Projets</div>
                        <div className="dropdown">
                            <button className="dropbtn long" onClick={() => dropdownService.openDropDown('dropPrj',JSON.parse(JSON.stringify(dropDownList)))}><div className="dropdown-name">{stringService.uppercaseFirstLetter(this.state.codeProjet)}</div><i className="icons-font arrow icon-chevron-down"></i></button>
                            <div className="dropdown-content long" id="dropPrj">
                                {this.state.projetsList.map((data, index) => {
                                    return <div key={index} onClick={() => this._selectProjetArray(data.nom)}>{stringService.uppercaseFirstLetter(data.nom)}</div>
                                })}
                            </div>
                        </div>
                        <Row className="selected-items-block">
                            {this.state.projetArray && (
                                this.state.projetArray.map((data, index) => {
                                    return (
                                        <div className="selected-items-item" key={index}>
                                            <div>{data}</div>
                                            <i className="icons-font selectable icon-close" onClick={() => this._removeItem(data)}></i>
                                        </div>
                                    );
                                })
                            )}
                        </Row>
                        {this.state.errorMessage !== '' && (<div className="slidableMenu-content-block-label center">{this.state.errorMessage}</div>)}
                        {/* Buttons */}
                        <div className="slidableMenu-content-block-buttons">
                            <input className="slidableMenu-content-block-buttons-button white" type="button" value="Ajouter" onClick={() => this._checkFields('user')}></input>
                            <input className="slidableMenu-content-block-buttons-button dark"  type="button" value="Annuler" onClick={this.props.onClose}></input>
                        </div>
                    </div>
                )}
                {this.state.userAdded && ( 
                    <div className="slidableMenu-content-block">
                        <div className="slidableMenu-content-block-label center">L'utilisateur a été ajouté avec succès !</div>
                        <div className="slidableMenu-content-block-buttons">
                            <input className="slidableMenu-content-block-buttons-button white" type="button" value="Fermer" onClick={() => this._closePanel()}></input>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default SlidableMenu_ConfigRole;

