import React from 'react';
import PropTypes from 'prop-types';
/* Style */
import "./Pagination.scss";

const propTypes = {
    items        : PropTypes.array.isRequired,
    onChangePage : PropTypes.func.isRequired,
    initialPage  : PropTypes.number,
    pageSize     : PropTypes.number
}

const defaultProps = {
    initialPage : 1,
    pageSize    : 20
}

class Pagination extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pager: {}
        };
    }

    // set page if items array isn't empty
    componentDidMount() {
        if (this.props.items && this.props.items.length) this._setPage(this.props.initialPage);
    }

    // reset page if items array has changed
    componentDidUpdate(prevProps, prevState){
        if (this.props.items !== prevProps.items) this._setPage(this.props.initialPage);
    }

    _setPage(page) {
        var { items, pageSize } = this.props;
        var {pager} = this.state;

        // if (page < 1 || page > pager.totalPages) return;
        
        pager = this._getPager(items.length, page, pageSize); // get new pager object for specified page

        var pageOfItems = items.slice(pager.startIndex, pager.endIndex + 1); // get new page of items from items array

        
        this.setState({ pager }); // update state
        this.props.onChangePage(pageOfItems,page); // call change page function in parent component
    }


    _getPager(totalItems, currentPage, pageSize) {
        currentPage = currentPage || 1;  // default to first page
        pageSize    = pageSize || 10; // default page size is 10

        var totalPages = Math.ceil(totalItems / pageSize); // calculate total pages
        var startPage, endPage;

        // less than 10 total pages so show all
        if(totalPages <= 10) {
            startPage = 1;
            endPage   = totalPages;

        // more than 10 total pages so calculate start and end pages
        }else {
            if (currentPage <= 6) {
                startPage = 1;
                endPage   = 10;
            } else if (currentPage + 4 >= totalPages) {
                startPage = totalPages - 9;
                endPage   = totalPages;
            } else {
                startPage = currentPage - 5;
                endPage   = currentPage + 4;
            }
        }

        // calculate start and end item indexes
        var startIndex = (currentPage - 1) * pageSize;
        var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

        // create an array of pages to ng-repeat in the pager control
        var pages = [...Array((endPage + 1) - startPage).keys()].map(i => startPage + i);

        // return object with all pager properties required by the view
        return {
            totalItems  : totalItems,
            currentPage : currentPage,
            pageSize    : pageSize,
            totalPages  : totalPages,
            startPage   : startPage,
            endPage     : endPage,
            startIndex  : startIndex,
            endIndex    : endIndex,
            pages       : pages
        };
    }


    render() {
        var pager = this.state.pager;

        // don't display pager if there is only 1 page
        if (!pager.pages || pager.pages.length <= 1) return null;

        const styleButtonBegin = `pagination-button ${pager.currentPage === 1 ? 'disabled' : ''}`;
        const styleButtonEnd   = `pagination-button ${pager.currentPage === pager.totalPages ? 'disabled' : ''}`;

        return(
            <div className="pagination">
                <div className="paginations-buttons-bloc">
                    <div className={styleButtonBegin} onClick={() => this._setPage(1)}>Début</div>
                    <div className={styleButtonBegin} onClick={() => this._setPage(pager.currentPage - 1)}><i className="icons-font paginate icon-chevron-left"></i></div>
                </div>
                <div className="paginations-buttons-numbers">
                    {pager.pages.map((page, index) => <div key={index} className={`pagination-button ${pager.currentPage === page ? 'active' : ''}`} onClick={() => this._setPage(page)}>{page}</div> )}
                </div>
                <div className="paginations-buttons-bloc">
                    <div className={styleButtonEnd} onClick={() => this._setPage(pager.currentPage + 1)}><i className="icons-font paginate icon-chevron-right"></i></div>
                    <div className={styleButtonEnd} onClick={() => this._setPage(pager.totalPages)}>Fin</div>
                </div>
            </div>
        );
    }
}

Pagination.propTypes = propTypes;
Pagination.defaultProps = defaultProps;
export default Pagination;