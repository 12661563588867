class StringService {

    /* Return the string with the first letter in uppercase */
    uppercaseFirstLetter(string){
        if(string) return string.charAt(0).toUpperCase() + string.slice(1);
    }

    /* Convert an english date to french */
    convertDateENToFR(dateEN){
        let dateSplitted = dateEN.split('-');
        let dateFr = dateSplitted[2]+'/'+dateSplitted[1]+'/'+dateSplitted[0];
        if(dateSplitted[0] === "") dateFr = "";
        return dateFr;
    }

    /* Convert a french date to english */
    convertDateFRToEN(dateFR){
        let dateSplitted = dateFR.split('/');
        let dateEN = dateSplitted[2]+'-'+dateSplitted[1]+'-'+dateSplitted[0];
        if(dateSplitted[0] === "") dateEN = "";
        return dateEN;
    }

    /* Count the number of words inside a string */
    countWords(string){
        string = string.replace(/(^\s*)|(\s*$)/gi,""); // exclude  start and end white-space
        string = string.replace(/\n /,"\n");           // exclude newline with a start spacing
        string = string.replace(/[^a-zA-Z ]/g, "");
        return string.split(' ').filter(function(str){return str!=="";}).length;
    }

    /* Convert number bool to string */
    convertBoolToString(bool){
        if(bool === 0) return 'Non';
        else return 'Oui';
    }

    /* Convert bool taxe to string */
    convertTaxeToString(lang,bool){
        if(lang === 'francais'){
            if(bool === 0)      return 'Aucune taxe';
            else if(bool === 1) return '2,1%';
            else if(bool === 2) return '5,5%';
            else if(bool === 3) return '10%';
            else if(bool === 4) return '20%';
        }else{
            if(bool === 0)      return 'Aucune taxe';
            else if(bool === 1) return '2,1%';
            else if(bool === 2) return '5,5%';
            else if(bool === 3) return '10%';
            else if(bool === 4) return '20%';
        }
    }

    /* Convert the string of the "visibility" field */
    convertVisibility(data){
        if(data === "both")         return "Partout"
        else if(data === "catalog") return "Catalogue uniquement"
        else if(data === "search")  return "Recherche uniquement"
        else if(data === "none")    return "Nulle part"
    }

    /* Get the date */
    getActualDate(){
        let day   = new Date().getDate();
        let month = new Date().getMonth()+1;
        let year  = new Date().getFullYear();

        if(day   < 10) day   = '0' + day;
        if(month < 10) month = '0' + month;

        let date = day+'/'+month+'/'+year;
        return date;
    }
}

export default StringService;