import StringService from './StringService';
const stringService = new StringService();

class JsonService {

  	/*-------------------------------------------------*/
	/*-------------------- Export ---------------------*/
   /*-------------------------------------------------*/
   
   /* Generate the principal products to export */
   generateJsonExportProdPrincipaux(jsonToExport, i){
      // Categories
      if(jsonToExport.hasOwnProperty('categories')){
         let formattedCat = '';
         for(let y=0; y<jsonToExport['categories'].length; y++){
            if(y === 0) formattedCat = stringService.uppercaseFirstLetter(jsonToExport['categories'][y].nom);
            else formattedCat = formattedCat.concat(',',stringService.uppercaseFirstLetter(jsonToExport['categories'][y].nom))
         }
         jsonToExport['categories'] = formattedCat;
      }

      // Caracteristiques
      let formattedCarac = '';
      if(jsonToExport.hasOwnProperty('caracteristiques')){
         if(jsonToExport['caracteristiques'].length !== 0){
            jsonToExport['caracteristiques'].forEach((carac) => {
               let options = '';
               for(let y=0;y<carac.options.length;y++){
                  if(y === 0) options = carac.options[y]+':'+i;
                  else options = options.concat('|',carac.options[y]+':'+i)
               }
               if(formattedCarac.length === 0) formattedCarac = formattedCarac.concat(carac.nom,':',options)
               else formattedCarac = formattedCarac.concat(',',carac.nom,':',options)
            })
            jsonToExport['caracteristiques'] = formattedCarac;
         }
      }
      
      // Images
      let formattedImg = '';
      if(jsonToExport.hasOwnProperty('images')){
         if(jsonToExport['images'].length !== 0){
            for(let x=0; x<jsonToExport['images'].length;x++){
               if(formattedImg.length === 0) formattedImg = formattedImg.concat(jsonToExport['images'][x])
               else formattedImg = formattedImg.concat(',',jsonToExport['images'][x])
            }
            jsonToExport['images'] = formattedImg;
         }
      }
      jsonToExport['Product ID']                               = jsonToExport['id']; 
      jsonToExport['Actif (0/1)']                              = jsonToExport['actif'];
      jsonToExport['Name *']                                   = jsonToExport['nom'];
      jsonToExport['Categories (x,y,z...)']                    = jsonToExport['categories'];   
      jsonToExport['Price tax excluded']                       = jsonToExport['prix_ht'];     
      jsonToExport['Tax rules ID']                             = jsonToExport['taxe'];          // return O/1, need to return an entire number ?
      jsonToExport['Wholesale price']                          = "";
      jsonToExport['On sale (0/1)']                            = "";
      jsonToExport['Discount amount']                          = "";
      jsonToExport['Discount percent']                         = "";
      jsonToExport['Discount from (yyyy-mm-dd)']               = "";
      jsonToExport['Discount to (yyyy-mm-dd)']                 = "";
      jsonToExport['Reference #']                              = jsonToExport['reference'];
      jsonToExport['Supplier reference #']                     = "";
      jsonToExport['Supplier']                                 = "";
      jsonToExport['Manufacturer']                             = "";
      jsonToExport['creation_date']                            = stringService.convertDateFRToEN(jsonToExport['creation_date'])
      jsonToExport['EAN13']                                    = jsonToExport['ean13'];     
      jsonToExport['UPC']                                      = jsonToExport['upc'];     
      jsonToExport['Ecotax']                                   = jsonToExport['eco'];
      jsonToExport['Width']                                    = "";
      jsonToExport['Height']                                   = "";
      jsonToExport['Depth']                                    = "";
      jsonToExport['Weight']                                   = jsonToExport['poids'];     
      jsonToExport['Delivery time of in-stock products']       = "";
      jsonToExport['Delivery time of out-of-stock products with allowed orders'] = "";
      jsonToExport['Quantity']                                 = jsonToExport['quantity'];     
      jsonToExport['Minimal quantity']                         = jsonToExport['quantityMin'];
      jsonToExport['Low stock level']                          = "";
      jsonToExport['Send me an email when the quantity is under this level'] = "";
      jsonToExport['Visibility']                               = jsonToExport['visibility'];    // Need to be text ? 
      jsonToExport['Additional shipping cost']                 = "";
      jsonToExport['Unity']                                    = "";
      jsonToExport['Unit price']                               = "";
      jsonToExport['Summary']                                  = jsonToExport['desc_courte'];     
      jsonToExport['Description']                              = jsonToExport['desc_longue'];  
      jsonToExport['Tags (x,y,z...)']                          = "";
      jsonToExport['Meta title']                               = "";
      jsonToExport['Meta keywords']                            = "";
      jsonToExport['Meta description']                         = "";
      jsonToExport['URL rewritten']                            = "";
      jsonToExport['Text when in stock']                       = "";
      jsonToExport['Text when backorder allowed']              = "";
      jsonToExport['Available for order (0 = No, 1 = Yes)']    = jsonToExport['dispoCommande'];    
      jsonToExport['Product available date']                   = "";
      jsonToExport['Product creation date']                    = jsonToExport['creation_date']; // need to be converted in 00-00-0000
      jsonToExport['Show price (0 = No, 1 = Yes)']             = "";
      jsonToExport['Image URLs (x,y,z,...)']                   = jsonToExport['images'];   
      jsonToExport['Image alt texts (x,y,z...)']               = "";
      jsonToExport['Delete existing images (0 = No, 1 = Yes)'] = jsonToExport['suppImages'];     
      jsonToExport['Feature(Name:Value:Position)']             = jsonToExport['caracteristiques'];     
      jsonToExport['Available online only (0= No, 1 = Yes)']   = jsonToExport['dispoLigne'];    
      jsonToExport['Condition']                                = "";
      jsonToExport['Customizable (0 = No, 1 = Yes)']           = "";
      jsonToExport['Uploadable files (0 = No, 1 = Yes)']       = "";
      jsonToExport['Text fields (0 = No, 1 = Yes)']            = "";
      jsonToExport['Out of stock action']                      = "";
      jsonToExport['Virtual product']                          = "";
      jsonToExport['File URL']                                 = "";
      jsonToExport['Number of allowed downloads']              = "";
      jsonToExport['Expiration date']                          = "";
      jsonToExport['Number of days']                           = "";
      jsonToExport['ID / Name of shop']                        = "";
      jsonToExport['Advanced stock management']                = "";
      jsonToExport['Depends On Stock']                         = "";
      jsonToExport['Warehouse']                                = "";
      jsonToExport['Accessories  (x,y,z...)']                  = "";

      this._deleteFieldsJsonExport(jsonToExport);
      return jsonToExport;
   }

   /* Generate the declinaisons to export */
   generateJsonExportDeclinaisons(jsonToExport){
      let formattedAttr = '';
      let formattedOpt  = '';

      // Attributes
      if(jsonToExport.hasOwnProperty('attributs')){
         if(jsonToExport['attributs'].length !== 0){
            for(let x=0; x<jsonToExport['attributs'].length;x++){
               if(formattedAttr.length === 0) formattedAttr = formattedAttr.concat(jsonToExport['attributs'][x].nom,':',jsonToExport['attributs'][x].type,':'+x)
               else formattedAttr = formattedAttr.concat(',',jsonToExport['attributs'][x].nom,':',jsonToExport['attributs'][x].type,':'+x)
               if(formattedOpt.length === 0) formattedOpt = formattedOpt.concat(jsonToExport['attributs'][x]['options'],':'+x)
               else formattedOpt = formattedOpt.concat(',',jsonToExport['attributs'][x]['options'],':'+x)
            } 
            jsonToExport['attributs'] = formattedAttr;
         }
      }
      jsonToExport['Product ID']                      = jsonToExport['idProd']; 
      jsonToExport['Product reference']               = jsonToExport['reference'];
      jsonToExport['Attribute (Name:Type:Position)*'] = jsonToExport['attributs'];
      jsonToExport['Value (Value:Position)*']         = formattedOpt;
      jsonToExport['Supplier Reference']              = '';
      jsonToExport['Reference']                       = '';
      jsonToExport['EAN13']                           = jsonToExport['ean13'];     
      jsonToExport['UPC']                             = jsonToExport['upc'];     
      jsonToExport['Wholesale Price']                 = jsonToExport['prix_ht'];     
      jsonToExport['Impact on price']                 = '';    
      jsonToExport['Ecotax']                          = jsonToExport['eco'];     
      jsonToExport['Quantity']                        = jsonToExport['quantity'];     
      jsonToExport['Minimal quantity']                = jsonToExport['quantityMin'];
      jsonToExport['Low stock level']                 = "";
      jsonToExport['Send me an email when the quantity is under this level'] = "";
      jsonToExport['Impact on weight']                = '';     
      jsonToExport['Default (0 = No, 1 = Yes)']       = '';     
      jsonToExport['Combination available date']      = '';     
      jsonToExport['Image Position']                  = '';     
      jsonToExport['Image URLs (x,y,z...)']           = jsonToExport['images'];     
      jsonToExport['Image alt texts (x,y,z...)']      = '';     
      jsonToExport['ID / Name of shop']               = '';    
      jsonToExport['Advanced Stock Managment']        = '';     
      jsonToExport['Depends on stock']                = '';    
      jsonToExport['Warehouse']                       = '';

      this._deleteFieldsJsonExport(jsonToExport);
      return jsonToExport;
   }
   
   /* Generate the categories to export */
   generateJsonExportCategories(jsonToExport){
      jsonToExport['Category ID']         = jsonToExport['id'];
      jsonToExport['Active (0/1)']        = "1";
      jsonToExport['Name *']              = jsonToExport['nom'];
      jsonToExport['Parent Category']     = jsonToExport['parent'];
      jsonToExport['Root category (0/1)'] = "0";

      this._deleteFieldsJsonExport(jsonToExport);
      return jsonToExport;
   }

   /* Generate the attributes to export */
   generateJsonExportAttributes(jsonToExport){
      let formattedOptions = '';
      if(jsonToExport.hasOwnProperty('options')){
         if(jsonToExport['options'].length !== 0){
            for(let x=0; x<jsonToExport['options'].length; x++){
               if(formattedOptions.length === 0) formattedOptions = formattedOptions.concat(jsonToExport['options'][x])
               else formattedOptions = formattedOptions.concat(',',jsonToExport['options'][x])
            }
            jsonToExport['options'] = formattedOptions;
         }
      }
      delete jsonToExport['idFirebase'];
      return jsonToExport;
   }

   /* Generate the attributes to export */
   generateJsonExportCaracteristiques(jsonToExport){
      let formattedOptions = '';
      if(jsonToExport.hasOwnProperty('options')){
         if(jsonToExport['options'].length !== 0){
            for(let x=0; x<jsonToExport['options'].length; x++){
               if(formattedOptions.length === 0) formattedOptions = formattedOptions.concat(jsonToExport['options'][x])
               else formattedOptions = formattedOptions.concat(',',jsonToExport['options'][x])
            }
            jsonToExport['options'] = formattedOptions;
         }
      }
      delete jsonToExport['idFirebase'];
      return jsonToExport;
   }

   /* Delete the fields unused to exports */
   _deleteFieldsJsonExport(jsonToExport){
      for(var propertyName in jsonToExport) {
         if(propertyName[0] === propertyName[0].toLowerCase()){
            delete jsonToExport[propertyName];
         }
      }
      return jsonToExport;
   }

   /*-------------------------------------------------*/
	/*-------------------- Import ---------------------*/
   /*-------------------------------------------------*/


   /* Generate the main products to import */
   generateJsonImportProdPrincipaux(jsonToImport){
      for(var propertyName in jsonToImport){
         if(propertyName.toLowerCase().includes('categor')){
            let catSplitted = jsonToImport[propertyName].split(',');
            let catArray = [];
   
            for(let i=0; i<catSplitted.length; i++){
               catArray[i] = {};
               catArray[i]['nom'] = catSplitted[i] ;
            }
            jsonToImport['categories'] = catArray;
         }
         if(propertyName.toLowerCase().includes('feature')){
            let caracSplitted = jsonToImport[propertyName].split(',');
            let caracArray = [];
   
            for(let i=0; i<caracSplitted.length; i++){
               let caracSplit = caracSplitted[i].split(':');

               caracArray[i] = {};
               caracArray[i]['nom']     = caracSplit[0];
               caracArray[i]['options'] = [];
               caracArray[i].options.push(caracSplit[1]);
            }
            jsonToImport['caracteristiques'] = caracArray;
         }
         if(propertyName.toLowerCase().includes('image') && propertyName.toLowerCase().includes('url')){
            let imgSplitted = jsonToImport[propertyName].split(',');
            let imgArray = [];

            for(let i=0; i<imgSplitted.length; i++){
               imgArray.push(imgSplitted[i])
            }
            jsonToImport['images'] = imgArray;
         } 
         if(propertyName.toLowerCase().includes('prod') && propertyName.toLowerCase().includes('id')){
            jsonToImport['id'] = parseInt(jsonToImport[propertyName]); 
         }
         if(propertyName.toLowerCase().includes('actif')){
            jsonToImport['actif'] = jsonToImport[propertyName]; 
         }
         if(propertyName.toLowerCase().includes('price') && propertyName.toLowerCase().includes('tax')){
            jsonToImport['prix_ht'] = jsonToImport[propertyName];
         }
         if(propertyName.toLowerCase().includes('tax') && !propertyName.toLowerCase().includes('price')){
            jsonToImport['taxe'] = parseInt(jsonToImport[propertyName]); 
         }
         if(propertyName.toLowerCase().includes('ref') && !propertyName.toLowerCase().includes('supp')){
            jsonToImport['reference'] = jsonToImport[propertyName]; 
         }
         if(propertyName.toLowerCase().includes('ean') && propertyName.toLowerCase().includes('13')){
            jsonToImport['ean13'] = jsonToImport[propertyName]; 
         }
         if(propertyName.toLowerCase().includes('eco')){
            jsonToImport['eco'] = parseInt(jsonToImport[propertyName]);
         }   
         if(propertyName.toLowerCase().includes('upc')){
            jsonToImport['upc'] = jsonToImport[propertyName]; 
         }
         if(propertyName.toLowerCase().includes('weight') || (propertyName.toLowerCase().includes('poids'))){
            jsonToImport['poids'] = jsonToImport[propertyName]; 
         }
         if(propertyName.toLowerCase().includes('quantity') && !propertyName.toLowerCase().includes('min') && !propertyName.toLowerCase().includes('email')){
            jsonToImport['quantity'] = jsonToImport[propertyName]; 
         }
         if(propertyName.toLowerCase().includes('quantity') && propertyName.toLowerCase().includes('min')){
            jsonToImport['quantityMin'] = jsonToImport[propertyName]; 
         } 
         if(propertyName.toLowerCase().includes('visibil')){
            jsonToImport['visibility'] = jsonToImport[propertyName]; 
         } 
         if(propertyName.toLowerCase().includes('summary')){
            jsonToImport['desc_courte'] = jsonToImport[propertyName]; 
         }
         if(propertyName.toLowerCase().includes('description') && !propertyName.toLowerCase().includes('meta')){
            jsonToImport['desc_longue'] = jsonToImport[propertyName]; 
         }
         if(propertyName.toLowerCase().includes('available') && propertyName.toLowerCase().includes('order')){
            jsonToImport['dispoCommande'] = jsonToImport[propertyName]; 
         }
         if(propertyName.toLowerCase().includes('creation') && propertyName.toLowerCase().includes('date')){
            jsonToImport['creation_date'] = stringService.convertDateENToFR(jsonToImport[propertyName]); 
         }
         if(propertyName.toLowerCase().includes('del') && propertyName.toLowerCase().includes('image')){
            jsonToImport['suppImages'] = jsonToImport[propertyName]; 
         }
         if(propertyName.toLowerCase().includes('available') && propertyName.toLowerCase().includes('online')){
            jsonToImport['dispoLigne'] = jsonToImport[propertyName]; 
         }
      }

      jsonToImport['maj_date'] = stringService.getActualDate();
      jsonToImport['nom']      = jsonToImport['Name *']; 

      this._getCompletedImport(jsonToImport, 0);
      this._deleteFieldsJsonImport(jsonToImport);

      console.log('jsonToImport',jsonToImport)

      return jsonToImport;
   }

   
   /* Generate the declinaisons to import */
   generateJsonImportDeclinaisons(jsonToImport){
      let that = this;

      for(var propertyName in jsonToImport){
         // attributes
         if(propertyName.toLowerCase().includes('attribut')){
            let attrSplitted = jsonToImport[propertyName].split(',');
            for(var secondPropertyName in jsonToImport){
               if(secondPropertyName.toLowerCase().includes('value')){
                  let optSplitted = jsonToImport[secondPropertyName].split(',');
                  let attArray = [];
         
                  for(let i=0; i<attrSplitted.length; i++){
                     let attrSplit = attrSplitted[i].split(':');
                     attArray[i] = {};
                     attArray[i]['nom']  = attrSplit[0];
                     attArray[i]['type'] = attrSplit[1];
                  }
                  for(let y=0; y<attrSplitted.length; y++){
                     let optSplit = optSplitted[y].split(':');
                     attArray[y]['options'] = optSplit[0];
                  }
                  jsonToImport['attributs'] = attArray;
               }
            }
         }

         if(propertyName.toLowerCase().includes('image') && propertyName.toLowerCase().includes('url')){
            let imgSplitted = jsonToImport[propertyName].split(',');
            let imgArray = [];

            for(let i=0; i<imgSplitted.length; i++){
               imgArray.push(imgSplitted[i])
            }
            jsonToImport['images'] = imgArray;
         } 
         if(propertyName.toLowerCase().includes('ref') && propertyName.toLowerCase().includes('prod')){
            jsonToImport['reference'] = jsonToImport[propertyName];
         }
         // if(!(propertyName.toLowerCase().includes('ref') && propertyName.toLowerCase().includes('prod'))){
         //    if(propertyName.toLowerCase().includes('supp') && propertyName.toLowerCase().includes('ref')){
         //       console.log('propertyName',propertyName);
         //       jsonToImport['reference'] = jsonToImport[propertyName];
         //    }
         // }
         else if(propertyName.toLowerCase().includes('quantity') && propertyName.toLowerCase().includes('min')){
            jsonToImport['quantityMin'] = jsonToImport[propertyName]; 
         }  
         else if(propertyName.toLowerCase().includes('quantity') && !propertyName.toLowerCase().includes('min') && !propertyName.toLowerCase().includes('email')){
            jsonToImport['quantity'] = jsonToImport[propertyName]; 
         } 
         else if(propertyName.toLowerCase().includes('price') && !propertyName.toLowerCase().includes('impact')){
            jsonToImport['prix_ht'] = jsonToImport[propertyName];
         } 
         else if(propertyName.toLowerCase().includes('id'))    jsonToImport['idProd'] = parseInt(jsonToImport[propertyName]);
         else if(propertyName.toLowerCase().includes('ean13')) jsonToImport['ean13']  = jsonToImport[propertyName];
         else if(propertyName.toLowerCase().includes('upc'))   jsonToImport['upc']    = jsonToImport[propertyName];
         else if(propertyName.toLowerCase().includes('eco'))   jsonToImport['eco']    = parseInt(jsonToImport[propertyName]);
      }
      jsonToImport['maj_date']      = stringService.getActualDate();
      jsonToImport['creation_date'] = stringService.getActualDate();

      that._getCompletedImport(jsonToImport, 1); 

      this._deleteFieldsJsonImport(jsonToImport);
      return jsonToImport;
   }

   /* Generate the categories to import */
   generateJsonImportCategories(jsonToImport){
      for(var propertyName in jsonToImport){
         if(propertyName.toLowerCase().includes('id')){
            jsonToImport['id'] = jsonToImport[propertyName];
         }
         if((propertyName.toLowerCase().includes('name')) || (propertyName.toLowerCase().includes('nom'))){
            jsonToImport['nom'] = jsonToImport[propertyName];
         }
         if(propertyName.toLowerCase().includes('parent')){
            jsonToImport['parentID'] = jsonToImport[propertyName];
         }
      }

      this._deleteFieldsJsonImport(jsonToImport);
      return jsonToImport;
   }

   /* Generate the categories to import */
   generateJsonImportAttributes(jsonToImport){
      if(jsonToImport.hasOwnProperty('options')){ 
         let optionsSplitted = jsonToImport['options'].split(',');
         let optArray = [];

         for(let i=0; i<optionsSplitted.length; i++){
            optArray.push(optionsSplitted[i]);
         }
         jsonToImport['options'] = optArray;
      }
      return jsonToImport;
   }

   /* Generate the categories to import */
   generateJsonImportCaracteristiques(jsonToImport){
      if(jsonToImport.hasOwnProperty('options')){ 
         let optionsSplitted = jsonToImport['options'].split(',');
         let optArray = [];

         for(let i=0; i<optionsSplitted.length; i++){
            optArray.push(optionsSplitted[i]);
         }
         jsonToImport['options'] = optArray;
      }
      return jsonToImport;
   }

   /* Get the completed value */
   _getCompletedImport(jsonToImport, type){
      jsonToImport['complete'] = false;

      if(type === 0){
         if(jsonToImport['nom'] !== ''){
            if(jsonToImport['reference'] !== ''){
               if(jsonToImport['categories'][0] && jsonToImport['categories'][0].nom !== ""){
                  if(jsonToImport['prix_ht'] !== ''){
                     if(jsonToImport['taxe'] !== ''){
                        if(jsonToImport['ean13'] !== ''){
                           if(jsonToImport['ean13'].length === 13){
                              if(jsonToImport['upc'] !== ''){
                                 if(jsonToImport['poids'] !== ''){
                                    if(jsonToImport['quantity'] !== ''){
                                       if(jsonToImport['quantityMin'] !== ''){
                                          if(jsonToImport['visibility'] !== ''){
                                             if(jsonToImport['desc_courte'] !== ''){
                                                if(jsonToImport['desc_courte'].length <= 800){
                                                   if(jsonToImport['dispoLigne'] !== ''){
                                                      if(jsonToImport['desc_longue'] !== ''){
                                                         if(stringService.countWords(jsonToImport['desc_longue']) >= 300){
                                                            jsonToImport['complete'] = true;
                                                            for(let i=0; i<jsonToImport['images'].length; i++){
                                                               if(jsonToImport['images'][i].length === 0){
                                                                  jsonToImport['complete'] = false;
                                                               }
                                                            }
                                                         }
                                                      }
                                                   }
                                                }
                                             }
                                          }
                                       }
                                    }
                                 }
                              }
                           }
                        }
                     }
                  }
               }
            }
         }

      }else{
         if(jsonToImport['nom'] !== ''){
            if(jsonToImport['reference'] !== ''){
               if(jsonToImport['attributs'] && jsonToImport['attributs'].length){
                  if(jsonToImport['prix_ht'] !== ''){
                     if(jsonToImport['ean13'] !== ''){
                        if(jsonToImport['ean13'].length === 13){
                           if(jsonToImport['upc'] !== ''){
                              if(jsonToImport['eco'] !== ''){
                                 if(jsonToImport['quantity'] !== ''){
                                    if(jsonToImport['quantityMin'] !== ''){
                                       jsonToImport['complete'] = true;
                                       for(let i=0; i<jsonToImport['images'].length; i++){
                                          if(jsonToImport['images'][i].length === 0){
                                             jsonToImport['complete'] = false;
                                          }
                                       }
                                    }
                                 }
                              }
                           }
                        }
                     }
                  }
               }
            }
         }
      }
   }

   /* Delete the fields unused to import */
   _deleteFieldsJsonImport(jsonToImport){
      for(var propertyName in jsonToImport){
         if(propertyName[0] === propertyName[0].toUpperCase()){
            delete jsonToImport[propertyName];
         }
      }
      return jsonToImport;
   }
}

export default JsonService;